import { useQuery } from '@tanstack/react-query';
import { getCurrentUser } from 'models/services/users.endpoint';
import User from 'models/User/User.model';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import useSafeContext from 'utils/useSafeContext';

import authenticationServiceInstance from './authenticationService';

interface AuthContextData {
  userToken: string | null;
  isAuthenticated: boolean;
  connectUser: (token: string) => void;
  disconnectUser: () => void;
  expireUserToken: () => void;
  validateToken: () => boolean;
  hasValidToken: boolean;
  userData: User | null;
  error: unknown | null;
  isLoading: boolean;
}

const AuthContext: React.Context<AuthContextData> =
  createContext<AuthContextData>({
    connectUser: () => {},
    disconnectUser: () => {},
    error: null,
    expireUserToken: () => {},
    hasValidToken: false,
    isAuthenticated: false,
    isLoading: false,
    userData: null,
    userToken: null,
    validateToken: () => false,
  });

const useUser = () => useSafeContext(AuthContext, 'User');

interface AuthProviderProps {
  children: React.ReactNode; // Prop pour les enfants du AuthProvider
}

function AuthProvider({ children }: AuthProviderProps) {
  const [userToken, setUserToken] = useState<string | null>(null);
  const [{ hasValidToken, isAuthenticated }, setAuthenticationInfo] = useState({
    hasValidToken: !!authenticationServiceInstance.hasValidToken(),
    isAuthenticated: authenticationServiceInstance.getIsAuthenticated(),
  });

  useEffect(() => {
    // Appeler la fonction init ici pour initialiser le contexte
    authenticationServiceInstance.init();
  }, []);

  // Function to set the user token and store in localStorage
  const connectUser = useCallback(async (token: string) => {
    await authenticationServiceInstance.connectUser(token);
    setAuthenticationInfo({
      hasValidToken: true,
      isAuthenticated: true,
    });
    setUserToken(token);
  }, []);

  // Function to clear user token and localStorage
  const disconnectUser = useCallback(async () => {
    await authenticationServiceInstance.disconnectUser();
    setAuthenticationInfo({
      hasValidToken: false,
      isAuthenticated: false,
    });
  }, []);

  const expireUserToken = useCallback(async () => {
    await authenticationServiceInstance.expireUserToken();
    setAuthenticationInfo((previousState) => ({
      ...previousState,
      hasValidToken: false,
      isAuthenticated: false,
    }));
  }, []);

  const validateToken = () => {
    const isValid = authenticationServiceInstance.hasValidToken();
    if (!isValid) {
      authenticationServiceInstance.expireUserToken();
    }
    return isValid;
  };
  // Fetch userData using useQuery
  const {
    data,
    error,
    isLoading,
    refetch: refetchUserQuery,
  } = useQuery({
    cacheTime: 100000,
    enabled: false,
    onSuccess: (response) => {
      if (!response?.data) expireUserToken();
    },
    queryFn: () => getCurrentUser(),
    queryKey: ['authenticate-me'],
    retry: 2,
    staleTime: 100000,
  });

  useEffect(() => {
    if (isAuthenticated && hasValidToken) {
      refetchUserQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, hasValidToken]);

  // Effect to check localStorage on mount
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setUserToken(storedToken);
      connectUser(storedToken);
      refetchUserQuery();
    }
  }, [connectUser, refetchUserQuery]);

  if (error) return expireUserToken();

  const authContextValue: AuthContextData = {
    connectUser,
    disconnectUser,
    error,
    expireUserToken,
    hasValidToken,
    isAuthenticated,
    isLoading,
    userData: data?.data?.user || null,
    userToken,
    validateToken,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, useUser };

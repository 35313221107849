/* eslint-disable sort-keys-fix/sort-keys-fix */
import RouteConfig from 'interfaces/RouteConfig.interface';
import React, { Suspense } from 'react';

import Loader from '../../components/Loader';
import routesPaths from './routesPaths';

const PrescribersPageRoute = React.lazy(
  () => import('routes/Prescribers/routes/Prescribers')
);

const PrescriberEditPageRoute = React.lazy(
  () => import('routes/Prescribers/routes/PrescriberEdit')
);

const routes: Record<string, RouteConfig> = {
  prescriberCreate: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <PrescriberEditPageRoute />
      </Suspense>
    ),
    path: routesPaths.prescriberNew,
    roles: 'ADMIN',
  },
  prescriberEdit: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <PrescriberEditPageRoute />
      </Suspense>
    ),
    path: routesPaths.prescriberEdit,
    roles: 'ADMIN',
  },
  prescribers: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <PrescribersPageRoute />
      </Suspense>
    ),
    exact: true,
    path: routesPaths.prescribers,
    roles: 'ADMIN',
  },
};

export default routes;

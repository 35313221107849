import { MapLayerProps } from 'business/map/layer/MapLayer.interface';
import { MarkerIconEnum } from 'business/map/utils/marker.constant';

/**
 * Enumeration for different map marker styles
 */
enum MapSiteMarkerStyleEnum {
  ECOLOGICAL_SENSITIVITY = 'ECOLOGICAL_SENSITIVITY',
  PROGRESS_STATUS = 'PROGRESS_STATUS',
  PERMEABILITY = 'PERMEABILITY',
}

interface MarkerStyleRulesProps {
  styleDefinition:
    | string
    | mapboxgl.StyleFunction
    | mapboxgl.Expression
    | undefined;
  linkedMarkers?: Array<MarkerIconEnum>;
}

interface MapSiteMarkerLayerProps extends Omit<MapLayerProps, 'group'> {
  group: MapSiteMarkerStyleEnum;
  filterField: string;
  filterValue: string | number;
  style: MarkerStyleRulesProps;
}

interface SiteMarkerLayerStyleProps {
  key: string;
  layers: Array<MapSiteMarkerLayerProps>;
}

export {
  SiteMarkerLayerStyleProps,
  MarkerStyleRulesProps,
  MapSiteMarkerLayerProps,
  MapSiteMarkerStyleEnum,
};

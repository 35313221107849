import {
  Color,
  CommonColors,
  SimplePaletteColorOptions,
  TypeBackground,
  TypeText,
  alpha,
} from '@mui/material';
/* eslint-disable sort-keys-fix/sort-keys-fix */

interface PartialColor
  extends Pick<
    Color,
    50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  > {
  1000?: string;
}

const common: CommonColors = {
  black: '#000000',
  white: '#FFFFFF',
};

const darkBlues: PartialColor = {
  50: '#F7F6F9',
  100: '#EEECF3',
  200: '#E6E3ED',
  300: '#C0BAD2',
  400: '#978BB5',
  500: '#6145A5',
  600: '#2E186A',
  700: '#190C4C',
  800: '#11073D',
  900: '#0B0432',
};

const greens: PartialColor = {
  50: '#CCEBD5',
  100: '#93FEB5',
  200: '#9EFFBD',
  300: '#96F2B3',
  400: '#8EE6AA',
  500: '#86D9A0',
  600: '#7fcc97',
  700: '#71bc8d',
  800: '#63ac83',
  900: '#559c7a',
};

const greys: PartialColor = {
  50: '#FFFFFF',
  100: '#FEFEFE',
  200: '#FFFCFC',
  300: '#FDFDFD',
  400: '#FCFCFC',
  500: '#FAFAFA',
  600: '#F6F6F6',
  700: '#F0F0F0',
  800: '#E3E3E3',
  900: '#C0C0C0',
  1000: '#A1A1A1',
};

const darkGreys: PartialColor = {
  50: '#A1A1A1',
  100: '#989898',
  200: '#8F8F8F',
  300: '#868686',
  400: '#7D7D7D',
  500: '#737373',
  600: '#6A6A6A',
  700: '#616161',
  800: '#585858',
  900: '#4F4F4F',
  1000: '#000000',
};

const reds: PartialColor = {
  50: '#fdadad',
  100: '#fc9999',
  200: '#fc8585',
  300: '#fb7070',
  400: '#fb5c5c',
  500: '#fa4848',
  600: '#fa3434',
  700: '#f91b1b',
  800: '#f50606',
  900: '#dc0505',
};

const yellows: PartialColor = {
  50: '#fce9b7',
  100: '#fce3a5',
  200: '#fbde93',
  300: '#fad881',
  400: '#fad36e',
  500: '#f9cd5d',
  600: '#f9c84b',
  700: '#f8c032',
  800: '#f7b91a',
  900: '#f0ae08',
};

const darkGreens: PartialColor = {
  50: '#467D6F',
  100: '#387b66',
  200: '#2a6b5c',
  300: '#1c5b53',
  400: '#0e4b49',
  500: '#084341',
  600: '#003b3f',
  700: '#00353B',
  800: '#002F37',
  900: '#00252D',
};

const blues: PartialColor = {
  50: '#9be5ea',
  100: '#82dfe5',
  200: '#69d9e0',
  300: '#50d2db',
  400: '#36ccd6',
  500: '#1dc6d1',
  600: '#05c0cc',
  700: '#04a9b3',
  800: '#04919a',
  900: '#037a81',
};

const purple = '#A5B4E8';

/* eslint-enable sort-keys-fix/sort-keys-fix */
function getGradient(color: string, opacity: number) {
  return `conic-gradient(from -71.18deg at 48.59% 126.26%, ${color} 0deg, ${alpha(
    color,
    opacity
  )} 360deg), ${common.white}`;
}

interface GradientColors {
  [key: string]: string;
}

const gradientColors: GradientColors = {
  blue: getGradient(blues[600], 0.71),
  darkBlue: getGradient(darkBlues[600], 0.8),
  darkGreen: getGradient(darkGreens[600], 0.6),
  green: getGradient(greens[600], 0.2),
  red: getGradient(reds[600], 0.3),
  yellow: getGradient(yellows[600], 0.18),
};

type ColorsType = {
  [key: string]: PartialColor;
};

const colors: ColorsType = {
  blues,
  darkBlues,
  darkGreens,
  darkGreys,
  greens,
  greys,
  reds,
  yellows,
};

/* eslint-disable sort-keys-fix/sort-keys-fix */
const createPaletteColors = (colorKey: string): SimplePaletteColorOptions => {
  const color = colors[colorKey];

  return {
    main: color[600],
    dark: color[700],
    light: color[500],
    shade50: color[50],
    shade100: color[100],
    shade200: color[200],
    shade300: color[300],
    shade400: color[400],
    hover: alpha(color[100], 0.9),
    darker: color[900],
    contrastText: common.white, // mui expects this prop
  };
};

const background: TypeBackground = {
  default: '',
  paper: '',
  background1: colors.greys[100],
  background2: colors.greys[700],
  background3: colors.greys[50],
};

interface OtherPaletteColor {
  lightGreenIllustrations: string;
  disabled: string;
  main: string;
}

const other: OtherPaletteColor = {
  lightGreenIllustrations: colors.greens[50],
  main: purple,
  disabled: colors.greys[900],
};

const tertiary: SimplePaletteColorOptions = {
  main: colors.greys[600],
  dark: colors.greys[800],
  light: colors.greys[500],
  shade200: colors.greys[200],
  shade300: colors.greys[300],
  shade400: colors.greys[400],
  hover: alpha(colors.greys[100], 0.12),
  contrastText: common.white, // mui expects this prop
};

const text: TypeText = {
  primary: colors.greens[600],
  secondary: colors.darkGreens[50],
  tertiary: colors.greys[50],
  light: colors.greys[200],
  dark: colors.darkGreys[900],
  disabled: colors.greys[900],
};

interface OverlayPaletteColor {
  overlay1: string;
  overlay2: string;
  overlay3: string;
  overlay4: string;
}

const overlay: OverlayPaletteColor = {
  overlay1: alpha(colors.darkBlues[600], 0.1),
  overlay2: alpha(colors.darkBlues[900], 0.15),
  overlay3: `linear-gradient(0deg, ${alpha(
    colors.darkBlues[900],
    0.6
  )} -0.29%, rgba(0, 0, 0, 0) 118.4%)`,
  overlay4: alpha(colors.darkBlues[600], 0.8),
};

const gradients: GradientColors = {
  green: gradientColors.green,
  darkBlue: gradientColors.darkBlue,
  red: gradientColors.red,
  yellow: gradientColors.yellow,
  darkGreen: gradientColors.darkGreen,
  blue: gradientColors.blue,
};

interface PaletteShadows {
  shadow1: string;
}

const shadow: PaletteShadows = {
  shadow1: `0px 4px 20px ${alpha(common.black, 0.1)}`,
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

const palette = {
  alert: createPaletteColors('reds'),
  background,
  gradients,
  info: createPaletteColors('blues'),
  other,
  overlay,
  primary: createPaletteColors('greens'),
  secondary: createPaletteColors('darkGreens'),
  shadow,
  success: createPaletteColors('darkGreens'),
  tertiary,
  text,
  warning: createPaletteColors('yellows'),
};

export {
  palette,
  GradientColors,
  PaletteShadows,
  OverlayPaletteColor,
  OtherPaletteColor,
};

import { Box as MuiBox } from '@mui/material';
import { useWebApp } from 'business/WebAppContext';
import { Menu } from 'design-system/menu/Menu';
import { MenuItemProps } from 'design-system/menu/menu-item/MenuItem';
import { muiTheme } from 'mui-theme/theme';
import React from 'react';
import companyRoutePaths from 'routes/Companies/routesPaths';
import prescriberRoutePaths from 'routes/Prescribers/routesPaths';
import siteRoutesPaths from 'routes/Sites/routesPaths';
import routesPaths from 'routesPaths';

import wording from './wording';

/**
 * Navigation menu component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.gridAreaName - The grid area name for layout positioning.
 * @returns {React.ReactElement} The rendered NavMenu component.
 */
function NavMenu({ gridAreaName }: { gridAreaName: string }) {
  const { webAppState } = useWebApp();

  const CartographyMenuItems: MenuItemProps[] = [
    {
      icon: 'building',
      id: 'prescribers',
      label: wording.prescriber.MenuLabel,
      menuItems: [
        {
          id: 'list-prescriber',
          label: wording.prescriber.ListLabel,
          path: `/${routesPaths.prescribers}`,
        },
        {
          id: 'add-prescriber',
          label: wording.prescriber.AddLabel,
          path: `/${routesPaths.prescribers}${prescriberRoutePaths.prescriberNew}`,
        },
      ],
    },
    {
      disabled: (() => !webAppState.selectedPrescriberId || false)(),
      icon: 'building',
      id: 'companies',
      label: wording.company.MenuLabel,
      menuItems: [
        {
          disabled: (() => !webAppState.selectedPrescriberId || false)(),
          id: 'list-company',
          label: wording.company.ListLabel,
          path: `/${routesPaths.companies}`,
        },
        {
          disabled: (() => !webAppState.selectedPrescriberId || false)(),
          id: 'add-company',
          label: wording.company.AddLabel,
          path: `/${routesPaths.companies}${companyRoutePaths.companyNew}`,
        },
      ],
    },
    {
      disabled: (() => !webAppState.selectedCompanyId || false)(),
      icon: 'markerCheck',
      id: 'site',
      label: wording.site.MenuLabel,
      menuItems: [
        {
          disabled: (() => !webAppState.selectedCompanyId || false)(),
          id: 'list-site',
          label: wording.site.ListLabel,
          path: `/${routesPaths.sites}`,
        },
        {
          disabled: (() => !webAppState.selectedCompanyId || false)(),
          id: 'add-site',
          label: wording.site.AddLabel,
          path: `/${routesPaths.sites}${siteRoutesPaths.siteNew}`,
        },
      ],
    },
  ];

  return (
    <MuiBox
      sx={{
        flexGrow: 1,
        gap: muiTheme.spacing(),
        gridArea: gridAreaName,
        justifySelf: 'flex-start',
      }}
    >
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        color='secondary'
        id='cartography-menu'
        label={wording.menuLabel}
        menuItems={CartographyMenuItems}
      />
    </MuiBox>
  );
}

export default NavMenu;

import { Components, Theme } from '@mui/material';

import { palette } from '../palette';

export default {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    // contained
    contained: {
      '&:disabled': {
        backgroundColor: palette.background.background2,
        color: palette.other.disabled,
      },
      color: palette.text.tertiary,
    },
    containedSecondary: {
      '&:hover': {
        color: palette.primary.main,
      },
      color: palette.text.tertiary,
    },
    // outlined
    outlined: {
      '&.Mui-focusVisible': {
        color: palette.text.tertiary,
      },
      '&:disabled': {
        borderColor: palette.other.disabled,
        color: palette.other.disabled,
      },
    },
    outlinedPrimary: {
      '&.Mui-focusVisible': {
        backgroundColor: palette.primary.main,
      },
    },
    outlinedSecondary: {
      '&.Mui-focusVisible': {
        backgroundColor: palette.secondary.main,
      },
    },
    root: {
      borderRadius: '10px',
      textTransform: 'none',
    },
    // sizes
    sizeLg: {
      fontSize: '16px',
      height: '50px',
      padding: '0 22px',
    },
    sizeMd: {
      fontSize: '14px',
      height: '36px',
      padding: '0 16px',
    },
    sizeSm: {
      fontSize: '12px',
      height: '32px',
      padding: '0 12px',
    },
    sizeXs: {
      fontSize: '12px',
      height: '12px',
      padding: '0 12px',
    },
  },
  variants: [
    {
      props: { color: 'alert' },
      style: {
        '&.Mui-focusVisible': {
          backgroundColor: palette.alert.main,
        },
      },
    },
    {
      props: { color: 'tertiary' },
      style: {
        '&.Mui-focusVisible': {
          backgroundColor: palette.background.background2,
        },
        '&:hover': {
          backgroundColor: palette.tertiary.dark,
        },
        color: palette.primary.main,
      },
    },
    {
      props: { color: 'tertiary', variant: 'outlined' },
      style: {
        '&.Mui-focusVisible': {
          color: palette.primary.main,
        },
        '&:hover': {
          backgroundColor: palette.tertiary.shade300,
          borderColor: palette.tertiary.dark,
        },
      },
    },
  ],
} as Components<Theme>['MuiButton'];

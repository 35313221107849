import { Button, styled } from '@mui/material';
import { muiTheme } from 'mui-theme/theme';
import React, { Component } from 'react';

import wording from './wording';

type SizeType = 's' | 'm' | 'l';

const Container = styled('div')<{ isCard?: boolean }>`
  height: calc(100% - (16px * 2));
  width: calc(100% - (16px * 2));
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  background-color: ${muiTheme.palette.background.paper};
  border: 1px solid ${muiTheme.palette.alert.main};
  margin: 16px;
`;

const TextWrapper = styled('div')<{
  paddingHorizontal: string;
  paddingVertical: string;
}>`
  text-align: center;
  padding: ${({ paddingHorizontal, paddingVertical }) =>
    `${paddingVertical} ${paddingHorizontal}`};
`;

const ErrorMessage = styled('h1')<{
  fontSize: string;
  isCard?: boolean;
  paddingTop?: string;
}>`
  font-weight: 500;
  font-family: Lato;
  font-size: ${({ fontSize }) => fontSize};
  padding-top: ${({ paddingTop }) => paddingTop};
  color: ${muiTheme.palette.secondary.main};
`;

const RefreshButton = styled(Button)<{ marginTop: string }>`
  margin-top: ${({ marginTop }) => marginTop};
`;

const RefreshMessage = styled('p')<{ fontSize: string; paddingTop: string }>`
  font-size: ${({ fontSize }) => fontSize};
  padding-top: ${({ paddingTop }) => paddingTop};
  color: ${muiTheme.palette.alert.main};
`;

const TakenCareOfMessage = styled('p')<{
  fontSize: string;
  paddingTop: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  padding-top: ${({ paddingTop }) => paddingTop};
  color: ${muiTheme.palette.secondary.main};
`;

const computeStyle = () => ({
  errorMessageFontSize: '16px',
  refreshButtonMarginTop: '12px',
  refreshMessageFontSize: '14px',
  refreshMessagePaddingTop: '12px',
  takenCareOfMessageFontSize: '10px',
  takenCareOfMessagePaddingTop: '8px',
  textHorizontalPadding: '32px',
  textVerticalPadding: '32px',
});

const isChunkLoadError = (error: Error) => error.name === 'ChunkLoadError';

/** We exceptionally use a class here since functional components cannot catch errors */
class ErrorBoundary extends Component<
  {
    isEmpty?: boolean;
    name: string;
    size?: SizeType;
    children?: React.ReactNode;
  },
  { hasChunkLoadError: boolean; hasError: boolean }
> {
  constructor(props: {
    name: string;
    isEmpty?: boolean;
    size?: 's' | 'm' | 'l';
    children?: React.ReactNode;
  }) {
    super(props);
    this.state = { hasChunkLoadError: false, hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasChunkLoadError: isChunkLoadError(error), hasError: true };
  }

  render() {
    const { hasChunkLoadError, hasError } = this.state;
    const { children = null, isEmpty = false } = this.props;

    if (hasError) {
      const {
        errorMessageFontSize,
        refreshButtonMarginTop,
        refreshMessageFontSize,
        refreshMessagePaddingTop,
        takenCareOfMessageFontSize,
        takenCareOfMessagePaddingTop,
        textHorizontalPadding,
        textVerticalPadding,
      } = computeStyle();
      return isEmpty ? null : (
        <Container>
          <TextWrapper
            paddingHorizontal={textHorizontalPadding}
            paddingVertical={textVerticalPadding}
          >
            <ErrorMessage fontSize={errorMessageFontSize} paddingTop='0'>
              {hasChunkLoadError
                ? wording.chunkLoadErrorMessage
                : wording.errorMessage}
            </ErrorMessage>
            {hasChunkLoadError ? (
              <RefreshButton
                marginTop={refreshButtonMarginTop}
                onClick={() => window.location.reload()}
                size='small'
                variant='outlined'
              >
                {wording.refreshButton}
              </RefreshButton>
            ) : (
              <>
                <RefreshMessage
                  fontSize={refreshMessageFontSize}
                  paddingTop={refreshMessagePaddingTop}
                >
                  {wording.refreshMessage}
                </RefreshMessage>
                <TakenCareOfMessage
                  fontSize={takenCareOfMessageFontSize}
                  paddingTop={takenCareOfMessagePaddingTop}
                >
                  {wording.takenCareOfMessage}
                </TakenCareOfMessage>
              </>
            )}
          </TextWrapper>
        </Container>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

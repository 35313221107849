import React from 'react';

function RectangleFilled() {
  return (
    <path
      d='M 1.4628843,7.8043675 H 40.598397 V 34.195633 H 1.4025223 Z'
      fill='currentColor'
    />
  );
}

export default RectangleFilled;

import { plainToInstance } from 'class-transformer';
import User from 'models/User/User.model';
import ApiCaller from 'utils/api/api-http';
import { ApiEndPointNameEnum, ApiSourceEnum } from 'utils/api/config';

import { PaginatedData } from './interfaces/paginated.interface';

// Function to fetch all users
async function findUsers(
  token: string | null,
  filters?: Record<string, unknown>
) {
  // Call the API using the ApiCaller function
  const response = await ApiCaller<PaginatedData>({
    body: { filters },
    endpoint: ApiEndPointNameEnum.users,
    method: 'post',
    source: ApiSourceEnum.cbsApi,
    token,
  });

  if (response.data && response.data.data) {
    const { data, ...rest } = response.data.data;
    const userInstances = plainToInstance(User, data, {
      excludeExtraneousValues: true,
    });

    return { data: userInstances, ...rest };
  }

  return null;
}

// Function to fetch information of the currently logged-in user
async function getCurrentUser() {
  // Define the data type returned by the API
  type Data = { data: { user: User } };

  if (window.localStorage.getItem('token')) {
    // Call the API using the ApiCaller function
    const response = await ApiCaller<Data>({
      endpoint: ApiEndPointNameEnum.me, // Endpoint to fetch information of the currently logged-in user
      method: 'get',
      // Authentication token to identify with the API
      source: ApiSourceEnum.cbsApi,
      // HTTP GET method to fetch the data
      token: window.localStorage.getItem('token') || undefined,
    });
    return response.data || null;
  }
  return null;
}

export { findUsers, getCurrentUser };

import { ButtonProps } from '@mui/material';
import { IconSize } from 'design-system/icon/Icon.types';

export const BUTTON_SIZES = ['lg', 'md', 'sm', 'xs'] as const;
export type ButtonSize = Extract<
  ButtonProps['size'],
  typeof BUTTON_SIZES[number]
>;

export const ICON_SIZE_MAP: {
  [key in ButtonSize]: IconSize;
} = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xs: 'xs',
};

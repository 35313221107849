import { useTheme } from '@mui/material';
import React from 'react';

function InformationFilled() {
  const {
    palette: { tertiary },
  } = useTheme();

  return (
    <>
      <path
        d='M 41,21 A 20,20 0 0 1 21,41 20,20 0 0 1 1,21 20,20 0 0 1 21,1 20,20 0 0 1 41,21 Z'
        fill='currentColor'
      />
      <path
        d='M 21.748133,4.2421867 H 28.0268 L 26.845422,9.9941778 H 20.5668 Z m -1.837644,8.9377243 h 6.278667 l -4.856667,23.494711 h -6.278667 z'
        fill={tertiary.light}
      />
    </>
  );
}

export default InformationFilled;

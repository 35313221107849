import React from 'react';

function EyeOpenOutlined() {
  return (
    <path
      d='m 20.999911,11.151726 c 6.890958,0 13.036344,3.813973 16.036339,9.8483 -2.999995,6.034329 -9.12714,9.848212 -16.036339,9.848212 -6.909021,0 -13.0362731,-3.813883 -16.0362686,-9.848212 2.9999955,-6.034327 9.1454346,-9.8483 16.0362686,-9.8483 z m 0,-3.5811815 C 11.909075,7.5705445 4.1454618,13.139288 1,21.000026 4.1454618,28.86064 11.909075,34.429456 20.999911,34.429456 30.090925,34.429456 37.854431,28.86064 41,21.000026 37.854431,13.139288 30.090925,7.5705445 20.999911,7.5705445 Z m 0,8.9529695 c 2.509087,0 4.545507,2.005518 4.545507,4.476512 0,2.470995 -2.03642,4.476476 -4.545507,4.476476 -2.509088,0 -4.545383,-2.005481 -4.545383,-4.476476 0,-2.470994 2.036295,-4.476512 4.545383,-4.476512 z m 0,-3.58118 c -4.509025,0 -8.181735,3.617001 -8.181735,8.057692 0,4.440709 3.67271,8.057693 8.181735,8.057693 4.509203,0 8.181806,-3.616983 8.181806,-8.057693 0,-4.440691 -3.672603,-8.057692 -8.181806,-8.057692 z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default EyeOpenOutlined;

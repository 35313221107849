import {
  Box as MuiBox,
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  Tooltip as MuiToolTip,
  css,
  styled,
} from '@mui/material';
import { Button } from 'design-system/button/action-button/Button';
import { IconButton } from 'design-system/button/icon-button/IconButton';
import { IconName } from 'design-system/icon/svg/IconMapping';
import { TextBody2 } from 'design-system/typography/Typography';
import { muiTheme } from 'mui-theme/theme';
import React from 'react';

import { MenuItem, MenuItemProps } from './menu-item/MenuItem';
import { MenuButtonColor } from './Menu.types';

/**
 * Props for the Menu component.
 */
export interface MenuProps extends Omit<MuiMenuProps, 'open'> {
  id: string;
  color?: MenuButtonColor;
  disabled?: boolean;
  icon?: IconName;
  label: string;
  menuItems?: Array<MenuItemProps>;
  isIconMenu?: boolean;
}

/**
 * Container styled component for the Menu.
 */
const Container = styled(MuiBox)`
  flex-grow: 1;
`;

/**
 * Styled TextBody component for the Menu label.
 */
const StyledTextBody = styled(TextBody2)(css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
`);

/**
 * Menu component representing a dropdown menu.
 * @param {MenuProps} props - The props for the Menu component.
 * @returns {JSX.Element} - The rendered Menu component.
 */
export function Menu({
  color,
  disabled = false,
  icon,
  id = 'basic',
  label = 'Button',
  menuItems = [],
  isIconMenu = false,
}: MenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  /**
   * Opens or closes the menu when the button is clicked.
   * @param {React.MouseEvent<HTMLElement>} event - The click event.
   */
  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  /**
   * Closes the menu.
   */
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  /**
   * Component IDs for accessibility.
   */
  const componentIds = {
    button: `menu-button-${id}`,
    menu: `menu-${id}`,
  };

  return (
    <Container>
      {isIconMenu ? (
        <MuiToolTip title={label}>
          <div>
            <IconButton
              aria-haspopup='false'
              aria-label={label}
              color='inherit'
              icon={icon || 'user'}
              onClick={handleToggleMenu}
              size='lg'
              sx={{ backgroundColor: 'transparent' }}
              variant='contained'
            />
          </div>
        </MuiToolTip>
      ) : (
        <Button
          aria-controls={componentIds.menu}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='false'
          aria-label={label}
          color={color}
          disabled={disabled}
          id={componentIds.button}
          onClick={handleToggleMenu}
          startIcon={icon}
        >
          <StyledTextBody
            color={
              color !== 'tertiary' ? muiTheme.palette.text.tertiary : undefined
            }
            title={label}
          >
            {label}
          </StyledTextBody>
        </Button>
      )}

      <MuiMenu
        anchorEl={anchorEl}
        id={componentIds.menu}
        onClose={handleToggleMenu}
        open={open}
      >
        {menuItems.map((menuItem, index) => (
          <MenuItem
            handleCloseMenu={handleCloseMenu}
            // eslint-disable-next-line react/no-array-index-key
            key={`${menuItem.id}-${index}`}
            {...menuItem}
          />
        ))}
      </MuiMenu>
    </Container>
  );
}

import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      '&': {
        '.MuiFormHelperText-root': {
          color: theme.palette.secondary.main,
        },
        '.MuiInputLabel-root, fieldset': {
          color: theme.palette.secondary.main,
        },

        '.MuiInputLabel-root.MuiFormLabel-root, fieldset': {
          color: theme.palette.text.primary,
        },
        borderRadius: 10,
      },
      '.MuiInputBase-input, .MuiInputBase-root': {
        '&::after, &::before': {
          border: 'none !important',
        },
        '&::placeholder': {
          color: theme.palette.primary.main,
          fontSize: 14,
          opacity: 1,
        },
        '&[readonly]': {
          background: theme.palette.grey[200],
          fontStyle: 'italic',
        },
        '.MuiInputBase-multiline': {
          padding: 5,
        },
        background: theme.palette.primary.contrastText,
        border: 'none',
        borderRadius: 10,
        color: theme.palette.secondary.main,
      },

      '.MuiTextField-root': {
        background: theme.palette.primary.contrastText,
        borderRadius: 10,
      },
      marginBottom: theme.spacing(4),
    }),
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        '.MuiInputBase-input, .MuiInputBase-root': {
          fontSize: 14,
        },
      },
    },
  ],
} as Components<Theme>['MuiTextField'];

import { USER_ROLES } from 'business/loginAndRights/userRoles';
import { Expose, Type } from 'class-transformer';

import UserActor from './UserActor.model';
import UserSite from './UserSite.model';

class User {
  @Expose()
  public id: string;

  @Expose()
  firstname: string;

  @Expose()
  lastname: string;

  @Expose()
  email: string;

  @Expose()
  role: string = USER_ROLES.USER;

  @Expose()
  @Type(() => UserActor)
  public userActors?: Array<UserActor>;

  @Expose()
  @Type(() => UserSite)
  public userSites?: Array<UserSite>;

  constructor(model: Partial<User> = {}) {
    if (model.userActors && typeof model.userActors === 'string') {
      this.userActors = JSON.parse(model.userActors);
    }

    if (model.userSites && typeof model.userSites === 'string') {
      this.userSites = JSON.parse(model.userSites);
    }

    Object.assign(this, model);
  }
}

export default User;

import { Expose, Type } from 'class-transformer';
import Site from 'models/Site/Site.model';
import UserActorRoleEnum from 'shared-api-front/enums/user-actor-role.enum';

import User from './User.model';

class UserSite {
  @Expose()
  userId: string;

  @Expose()
  actorId: number;

  @Expose()
  public role: UserActorRoleEnum;

  @Expose()
  @Type(() => User)
  public user: User;

  @Expose()
  @Type(() => Site)
  public site: Site;

  constructor(model: Partial<UserSite> = {}) {
    if (model.user && typeof model.user === 'string') {
      this.user = JSON.parse(model.user);
    }

    if (model.site && typeof model.site === 'string') {
      this.site = JSON.parse(model.site);
    }

    Object.assign(this, model);
  }
}

export default UserSite;

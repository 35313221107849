import { getConfig } from './external-services/config/getConfig';

let redirectInProgress = false;

function windowRedirectTo(href: string | null, relativeHref?: string) {
  const config = getConfig();

  try {
    if (!redirectInProgress) {
      let modifiedHref = href;

      if (href === null || href === '') {
        // If href is null or empty, redirect to the default URL
        modifiedHref = config.defaultBaseUrl;
      } else {
        // Parse the URL to check for hostname and protocol
        const parsedUrl = new URL(href);

        // Check if hostname is empty or protocol is missing
        if (!parsedUrl.hostname || !parsedUrl.protocol) {
          // Get the hostname and protocol from the default URL
          const defaultParsedUrl = new URL(config.defaultBaseUrl);

          // Replace the missing parts in href with default values
          parsedUrl.hostname = parsedUrl.hostname || defaultParsedUrl.hostname;
          parsedUrl.protocol = parsedUrl.protocol || defaultParsedUrl.protocol;

          // Use the modified href
          modifiedHref = parsedUrl.href;
        }
      }
      if (relativeHref)
        modifiedHref = `${config.defaultBaseUrl}${relativeHref}`;
      // Perform the redirection with the modified href
      window.location.href = modifiedHref || config.defaultBaseUrl;
      redirectInProgress = true;
    }
  } catch (error) {
    redirectInProgress = false;
  }
}

export default windowRedirectTo;

import { useMediaQuery, useTheme } from '@mui/material';

interface Devices {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

function useDeviceType(): Devices {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) || false;
  const isTablet =
    useMediaQuery(theme.breakpoints.between('sm', 'md')) || false;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')) || false;

  return {
    isDesktop,
    isMobile,
    isTablet,
  };
}

export { Devices };
export default useDeviceType;

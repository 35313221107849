import { Box, CircularProgress } from '@mui/material';
import React from 'react';

function Loader() {
  return (
    <Box
      alignContent='center'
      alignSelf='center'
      display='grid'
      height='100%'
      justifyContent='center'
    >
      <CircularProgress color='primary' />
    </Box>
  );
}

export default Loader;

import React from 'react';
import { Redirect } from 'react-router-dom';

interface InternalRedirectProps {
  to: string | { pathname: string };
}

function InternalRedirect({ to }: InternalRedirectProps) {
  const toObject = typeof to === 'string' ? { pathname: to } : to;

  return (
    <Redirect
      to={{
        ...toObject,
        pathname: toObject.pathname,
      }}
    />
  );
}

export default InternalRedirect;

import RouteConfig from 'interfaces/RouteConfig.interface';
import React, { Suspense } from 'react';
import MapRoutes from 'routes/Map';
import PrescribersRoutes from 'routes/Prescribers';
import SitesRoutes from 'routes/Sites';
import routesPaths from 'routesPaths';

import Loader from './components/Loader';

const UsersRoute = React.lazy(() => import('routes/Users'));
const HomeRoute = React.lazy(() => import('routes/homePages/Home'));
const AuthRoute = React.lazy(() => import('routes/Auth'));
const CompaniesRoute = React.lazy(() => import('routes/Companies'));
const NotFoundRoute = React.lazy(() => import('routes/NotFound'));

const routes: Record<string, RouteConfig> = {
  auth: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <AuthRoute />
      </Suspense>
    ),
    path: routesPaths.auth,
  },
  companies: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <CompaniesRoute />
      </Suspense>
    ),
    path: routesPaths.companies,
    roles: 'CONNECTED',
  },
  home: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <HomeRoute />
      </Suspense>
    ),
    exact: true,
    path: routesPaths.home,
  },
  maps: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <MapRoutes />
      </Suspense>
    ),
    path: routesPaths.maps,
  },
  prescribers: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <PrescribersRoutes />
      </Suspense>
    ),
    path: routesPaths.prescribers,
    roles: 'CONNECTED',
  },
  sites: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <SitesRoutes />
      </Suspense>
    ),
    path: routesPaths.sites,
    roles: 'CONNECTED',
  },
  users: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <UsersRoute />
      </Suspense>
    ),
    path: routesPaths.users,
    roles: 'CONNECTED',
  },
};

routes.notFound = {
  Component: () => (
    <Suspense fallback={<Loader />}>
      <NotFoundRoute />
    </Suspense>
  ),
  exact: true,
  path: '*',
};

export default routes;

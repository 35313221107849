import { useTheme } from '@mui/material';
import React from 'react';

function Perimeter1Filled() {
  const {
    palette: { secondary, tertiary },
  } = useTheme();
  return (
    <>
      <path
        d='M 23.512418,40.236234 A 17.487581,17.487581 0 0 1 6.0248375,22.748653 17.487581,17.487581 0 0 1 23.512418,5.2610721 17.487581,17.487581 0 0 1 41,22.748653 17.487581,17.487581 0 0 1 23.512418,40.236234 Z'
        fill='currentColor'
      />
      <path
        d='m 24.188286,38.237409 c -0.499594,0 -0.936832,-0.187366 -1.311565,-0.562099 -0.374732,-0.374733 -0.562099,-0.824412 -0.562099,-1.349038 V 13.467586 l -2.735549,1.161657 c -0.424648,0.17488 -0.824412,0.262328 -1.199145,0.262328 -0.549508,0 -1.011779,-0.174881 -1.386512,-0.524626 -0.3497,-0.349761 -0.524626,-0.79944 -0.524626,-1.349038 0,-0.39972 0.09998,-0.761967 0.299787,-1.086726 0.199957,-0.324773 0.512184,-0.562099 0.936832,-0.711992 L 23.55124,9.1206856 c 0.125011,-0.049974 0.249872,-0.087448 0.374733,-0.1124198 0.125011,-0.024987 0.237431,-0.037473 0.33726,-0.037473 0.57469,0 1.024369,0.1873664 1.349038,0.5620992 0.34985,0.3497456 0.524626,0.811911 0.524626,1.386511 v 25.406869 c 0,0.524626 -0.187367,0.974305 -0.562099,1.349038 -0.374733,0.374733 -0.836854,0.562099 -1.386512,0.562099 z'
        fill={tertiary.light}
      />
      <path
        d='m 11.412581,1.7637663 -9.3033715,-5e-7 c -0.6095659,0 -1.1092093,0.4996422 -1.1092093,1.1092081 l -4.1e-7,9.3033731 c -4e-8,0.989294 1.19914411,1.478935 1.88865391,0.779444 L 12.19201,3.6524197 C 12.891516,2.9529179 12.391877,1.7637663 11.412581,1.7637663 Z'
        fill={secondary.main}
      />
    </>
  );
}

export default Perimeter1Filled;

import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {
    clickable: false,
    color: 'primary',
    variant: 'filled',
  },
  styleOverrides: {
    icon: {
      height: '16px',
      marginLeft: 'unset',
      width: '16px',
    },
    root: {
      alignItems: 'center',
      borderRadius: '16px',
      padding: '3px 4px',
    },
  },
} as Components<Theme>['MuiChip'];

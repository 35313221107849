import RouteConfig from 'interfaces/RouteConfig.interface';
import React, { Suspense } from 'react';

import Loader from '../../components/Loader';
import routesPaths from './routesPaths';

const MapsPageRoute = React.lazy(() => import('routes/Map/routes/Map'));

const routes: Record<string, RouteConfig> = {
  maps: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <MapsPageRoute />
      </Suspense>
    ),
    exact: true,
    path: routesPaths.map,
    roles: 'ADMIN',
  },
};

export default routes;

import { styled } from '@mui/material';
import { Button } from 'design-system/button/action-button/Button';
import ModalDialog from 'design-system/modal/ModalDialog';
import {
  Heading1,
  TextBody1,
  TextBody2,
} from 'design-system/typography/Typography';
import { muiTheme } from 'mui-theme/theme';
import React, { useState } from 'react';

import DownloadNavigator from './DownloadNavigator';
import wording from './wording';

// define Object.assign for internet explorer
if (typeof Object.assign !== 'function') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.assign = function (target: any) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    // eslint-disable-next-line no-param-reassign
    target = Object(target);
    for (let index = 1; index < arguments.length; index += 1) {
      // eslint-disable-next-line prefer-rest-params
      const source = arguments[index];
      if (source != null) {
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            // eslint-disable-next-line no-param-reassign
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

const Container = styled('div')`
  text-align: center;
  padding-bottom: 32px;
  padding-top: 100px;
`;

const UnsupportedMessage = styled(Heading1)`
  margin-bottom: 40px;
  text-align: center;
`;

const TryWithMSE = styled(TextBody2)`
  margin-bottom: 12px;
`;

const OpenInMSEButton = styled(Button)`
  margin-bottom: 40px;
`;

const OthersSolutionsMessage = styled(TextBody1)`
  margin-bottom: 16px;
  text-align: center;
`;
const Paragraph1 = styled(TextBody1)`
  margin-bottom: 12px;
`;

const StyledModal = styled(ModalDialog)`
  padding-bottom: ${muiTheme.spacing(5)};
  padding-top: ${muiTheme.spacing(5)};
  text-align: justify;
`;

const StepsList = styled('ul')`
  list-style: initial;
  padding-left: 30px;

  & > li:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

function UnsupportedNavigatorGuide() {
  const [autoOpenMSEFailed, setAutoOpenMSEFailed] = useState(false);

  const openInMSE = () => {
    // This will only work if Edge is installed
    try {
      const location = new Location();
      location.assign(`microsoft-edge:${window.location.href}`);
      window.location = location;
    } catch (err) {
      setAutoOpenMSEFailed(true);
    }
  };

  return (
    <Container>
      <UnsupportedMessage>{wording.unsupportedNavigator}</UnsupportedMessage>
      <TryWithMSE>{wording.tryWithMSE}</TryWithMSE>
      <OpenInMSEButton onClick={openInMSE}>{wording.openInMSE}</OpenInMSEButton>
      <OthersSolutionsMessage>{wording.othersSolutions}</OthersSolutionsMessage>
      <DownloadNavigator
        browserLogo='browsers.googleChrome'
        browserName={wording.chrome}
        downloadURL='https://www.google.com/chrome/'
      />
      <DownloadNavigator
        browserLogo='browsers.firefox'
        browserName={wording.firefox}
        downloadURL='https://www.mozilla.org/fr/firefox/new/'
      />
      <DownloadNavigator
        browserLogo='browsers.opera'
        browserName={wording.opera}
        downloadURL='https://www.opera.com/fr/download'
      />

      <StyledModal
        handleClose={() => setAutoOpenMSEFailed(false)}
        isOpen={autoOpenMSEFailed}
        withCloseButton={false}
      >
        <Paragraph1>{wording.autoOpenMSEFailed}</Paragraph1>
        <br />
        <Paragraph1>{wording.stepsToFindMSE}</Paragraph1>
        <br />
        <StepsList>
          <li>
            <Paragraph1>{wording.stepOne}</Paragraph1>
          </li>
          <li>
            <Paragraph1>{wording.stepTwo}</Paragraph1>
          </li>
        </StepsList>
      </StyledModal>
    </Container>
  );
}

export default UnsupportedNavigatorGuide;

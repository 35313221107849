import { useUser } from 'business/loginAndRights/AuthContext';
import PrivateRoute from 'components/PrivateRoute';
import RouteConfig from 'interfaces/RouteConfig.interface';
import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

interface CustomSwitchProps {
  routes: {
    [key: string]: RouteConfig;
  };
  children?: React.ReactNode;
}

// eslint-disable-next-line react/function-component-definition
const CustomSwitch: React.FC<CustomSwitchProps> = ({
  children,
  routes,
  ...props
}) => {
  const { userData } = useUser();
  const { path } = useRouteMatch();

  return (
    <Switch {...props}>
      {Object.keys(routes).map((routeKey) => {
        const route = routes[routeKey];
        if (
          route.testExistence &&
          !route.testExistence({ currentUser: userData, ...props })
        ) {
          return null;
        }
        return (
          <PrivateRoute
            exact={route.exact || false}
            key={routeKey}
            path={`${path}${route.path}`}
            roles={route.roles}
            rolesDifferentFrom={route.rolesDifferentFrom}
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <route.Component {...props} />
          </PrivateRoute>
        );
      })}
      {children}
    </Switch>
  );
};

export default CustomSwitch;

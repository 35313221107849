import CustomSwitch from 'components/CustomSwitch';
import React from 'react';

import routes from './routes';

function MapRoutes() {
  return <CustomSwitch routes={routes} />;
}

export default MapRoutes;

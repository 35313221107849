import React from 'react';

function BulletOutlined() {
  return (
    <path
      d='m 15.036125,15.036375 c -2.125499,2.125473 -2.968363,5.240409 -2.180473,8.135473 0.78789,2.913338 3.059987,5.18556 5.95505,5.95505 2.913364,0.78799 6.01,-0.055 8.135474,-2.180472 2.125473,-2.125474 2.968462,-5.24041 2.180472,-8.135474 -0.78799,-2.913388 -3.059961,-5.18546 -5.95505,-5.95505 -2.913389,-0.78789 -6.01,0.05497 -8.135473,2.180473 z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default BulletOutlined;

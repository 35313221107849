import React from 'react';

function MarkerCircleOutlined() {
  return (
    <>
      <path
        d='M 32.816307,5.4506923 C 29.414154,2.4835646 25.472923,1 20.996307,1 16.519662,1 12.578446,2.4835646 9.176354,5.4506923 5.7742616,8.4178308 4.075077,12.400954 4.075077,17.4 c 0,3.33277 1.4017353,6.957385 4.2052,10.873692 C 11.083754,32.19 15.32243,36.43323 21,41 26.677539,36.43323 30.916307,32.19 33.719692,28.273692 36.523231,24.357385 37.924923,20.73277 37.924923,17.4 37.924923,12.400954 36.225692,8.4178308 32.82,5.4506923 Z m -11.82,23.4029997 c -6.859968,0 -12.4222915,-5.25923 -12.4222915,-11.745384 0,-6.486231 5.5623235,-11.741985 12.4222915,-11.741985 6.86,0 12.422308,5.259277 12.422308,11.745523 0,6.486154 -5.562308,11.745384 -12.422308,11.745384 z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 20.999992,27.096154 c 5.833846,0 10.563231,-4.471539 10.563231,-9.987692 0,-5.516032 -4.729385,-9.987662 -10.563231,-9.987662 -5.833907,0 -10.563215,4.47163 -10.563215,9.987662 0,5.516153 4.729308,9.987692 10.563215,9.987692 z'
        fill='transparent'
      />
    </>
  );
}

export default MarkerCircleOutlined;

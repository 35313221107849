import React from 'react';

function LayerOutlined() {
  return (
    <path
      d='M 14.309276,17.253741 21,22.457664 27.690724,17.253741 31.499135,14.291703 42,6.1243334 38.149959,3.207671 27.683806,11.324279 23.860447,14.289418 21,16.507623 18.139553,14.289418 14.316194,11.324279 3.8500041,3.207671 0,6.1243334 10.500889,14.291703 Z M 34.416653,22.459023 29.029412,18.289782 32.909965,15.38904 42,22.459023 21,38.792329 0,22.459023 9.0900106,15.38904 12.970588,18.289782 7.5833347,22.459023 21,32.842411 Z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default LayerOutlined;

const wording = {
  autoOpenMSEFailed:
    "Nous n'avons pas trouvé Microsoft Edge sur votre ordinateur.",
  chrome: 'Google Chrome',
  download: 'Télécharger',
  firefox: 'Mozilla Firefox',
  openInMSE: 'Ouvrir dans Microsoft Edge',
  opera: 'Opera',
  othersSolutions: "Ou téléchargez l'un des navigateurs populaires suivants :",
  stepOne:
    "Cliquez sur l'icône dans la barre des tâches ou ouvrez le menu « Démarrer ».",
  stepTwo:
    'Dans la barre de recherche de Windows, recherchez « Edge », puis cliquez sur « Microsoft Edge ».',
  stepsToFindMSE:
    'Vous pouvez le rechercher manuellement en suivant ces étapes :',
  tryWithMSE: 'Essayez Microsoft Edge, plus récent et plus performant',
  unsupportedNavigator: "Votre navigateur n'est plus supporté",
};

export default wording;

import React from 'react';

function DotLayerOutlined() {
  return (
    <>
      <path
        d='M 12.228714,12.428572 H 8.3427143 c -0.8993572,0 -1.6284286,0.959385 -1.6284286,2.142857 0,1.183471 0.7290714,2.142857 1.6284286,2.142857 h 3.8859997 c 0.899357,0 1.628429,-0.959386 1.628429,-2.142857 0,-1.183472 -0.729072,-2.142857 -1.628429,-2.142857 z'
        fill='transparent'
        id='path1'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 23.331571,12.428572 h -4.663142 c -1.079258,0 -1.954143,0.959385 -1.954143,2.142857 0,1.183471 0.874885,2.142857 1.954143,2.142857 h 4.663142 c 1.079286,0 1.954143,-0.959386 1.954143,-2.142857 0,-1.183472 -0.874857,-2.142857 -1.954143,-2.142857 z'
        fill='transparent'
        id='path2'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 33.657286,12.428572 h -3.886 c -0.899286,0 -1.628429,0.959385 -1.628429,2.142857 0,1.183471 0.729143,2.142857 1.628429,2.142857 h 3.886 c 0.899428,0 1.628428,-0.959386 1.628428,-2.142857 0,-1.183472 -0.729,-2.142857 -1.628428,-2.142857 z'
        fill='transparent'
        id='path3'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 7.6173143,19.571429 H 2.9541086 C 1.8748857,19.571429 1,20.211 1,21 c 0,0.789 0.8748857,1.428571 1.9541086,1.428571 h 4.6632057 c 1.0792286,0 1.9541143,-0.639571 1.9541143,-1.428571 0,-0.789 -0.8748857,-1.428571 -1.9541143,-1.428571 z'
        fill='transparent'
        id='path4'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 17.617314,19.571429 h -4.6632 C 11.874886,19.571429 11,20.211 11,21 c 0,0.789 0.874886,1.428571 1.954114,1.428571 h 4.6632 c 1.079257,0 1.954115,-0.639571 1.954115,-1.428571 0,-0.789 -0.874858,-1.428571 -1.954115,-1.428571 z'
        fill='transparent'
        id='path5'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 29.045857,19.571429 h -4.663143 c -1.079285,0 -1.954143,0.639571 -1.954143,1.428571 0,0.789 0.874858,1.428571 1.954143,1.428571 h 4.663143 C 30.125143,22.428571 31,21.789 31,21 31,20.211 30.125143,19.571429 29.045857,19.571429 Z'
        fill='transparent'
        id='path6'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 39.045857,19.571429 h -4.663143 c -1.079285,0 -1.954143,0.639571 -1.954143,1.428571 0,0.789 0.874858,1.428571 1.954143,1.428571 h 4.663143 C 40.125143,22.428571 41,21.789 41,21 41,20.211 40.125143,19.571429 39.045857,19.571429 Z'
        fill='transparent'
        id='path7'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 13.3316,25.285714 H 8.6684 c -1.0792286,0 -1.9541143,0.959428 -1.9541143,2.142857 0,1.183429 0.8748857,2.142857 1.9541143,2.142857 h 4.6632 c 1.079229,0 1.954114,-0.959428 1.954114,-2.142857 0,-1.183429 -0.874885,-2.142857 -1.954114,-2.142857 z'
        fill='transparent'
        id='path8'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 23.657286,25.285714 h -3.886 c -0.899429,0 -1.628429,0.959428 -1.628429,2.142857 0,1.183429 0.729,2.142857 1.628429,2.142857 h 3.886 c 0.899285,0 1.628428,-0.959428 1.628428,-2.142857 0,-1.183429 -0.729143,-2.142857 -1.628428,-2.142857 z'
        fill='transparent'
        id='path9'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 34.760143,25.285714 H 30.097 c -1.079286,0 -1.954143,0.959428 -1.954143,2.142857 0,1.183429 0.874857,2.142857 1.954143,2.142857 h 4.663143 c 1.079286,0 1.954143,-0.959428 1.954143,-2.142857 0,-1.183429 -0.874857,-2.142857 -1.954143,-2.142857 z'
        fill='transparent'
        id='path10'
        stroke='currentColor'
        strokeWidth='2'
      />
    </>
  );
}

export default DotLayerOutlined;

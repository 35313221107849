import { Image } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import wording from './wording';

const CenterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BrowserLogo = styled(Image)`
  height: 50px;
  width: auto;
`;

const DownloadButton = styled(Button)`
  margin-top: 12px;
`;
interface DownloadNavigatorProps {
  browserLogo: string;
  browserName: string;
  downloadURL: string;
}
function DownloadNavigator({
  browserLogo,
  browserName,
  downloadURL,
}: DownloadNavigatorProps) {
  return (
    <CenterContainer>
      <BrowserLogo name={browserLogo} />
      <DownloadButton href={downloadURL} size='sm'>
        {`${wording.download} ${browserName}`}
      </DownloadButton>
    </CenterContainer>
  );
}

DownloadNavigator.propTypes = {
  browserLogo: PropTypes.string.isRequired,
  browserName: PropTypes.string.isRequired,
  downloadURL: PropTypes.string.isRequired,
};

export default DownloadNavigator;

import React from 'react';

function MailFilled() {
  return (
    <path
      d='M 37.000111,4.999949 H 4.9999998 c -2.1999934,0 -3.9800004,1.7999955 -3.9800004,4.0000071 L 1,32.999981 c 0,2.200066 1.8000064,4.00007 3.9999998,4.00007 H 37.000111 C 39.199995,37.000051 41,35.200047 41,32.999981 V 8.9999561 C 41,6.7999445 39.199995,4.999949 37.000111,4.999949 Z m -0.800123,8.500019 -14.140006,8.839998 c -0.63988,0.40008 -1.479882,0.40008 -2.119944,0 L 5.800014,13.499968 C 5.3000006,13.179955 4.9999998,12.639953 4.9999998,12.059964 c 0,-1.340016 1.4600159,-2.1400116 2.6000187,-1.440004 L 21.00001,18.999969 34.399983,10.61996 c 1.140094,-0.7000076 2.600128,0.09999 2.600128,1.440004 0,0.579989 -0.300092,1.119991 -0.800123,1.440004 z'
      fill='currentColor'
    />
  );
}

export default MailFilled;

import React from 'react';

function ZoomOutFilled() {
  return (
    <path
      d='M 37.888903,41 23.888908,27.000006 c -1.111114,0.888868 -2.388912,1.592646 -3.833395,2.111105 -1.444369,0.518573 -2.981454,0.777747 -4.611028,0.777747 -4.03712,0 -7.453692,-1.398069 -10.250043,-4.194431 C 2.3981474,22.898177 1,19.481492 1,15.444485 1,11.407365 2.3981474,7.990793 5.194442,5.194442 7.990793,2.3981474 11.407365,1 15.444485,1 c 4.037007,0 7.453692,1.3981474 10.249942,4.194442 2.796362,2.796351 4.194431,6.212923 4.194431,10.250043 0,1.629574 -0.259174,3.166659 -0.777747,4.611028 -0.518459,1.444483 -1.222237,2.722281 -2.111105,3.833395 L 41,37.888903 Z M 15.444485,25.444401 c 2.777729,0 5.138861,-0.972211 7.083283,-2.916633 1.944422,-1.944422 2.916633,-4.305554 2.916633,-7.083283 0,-2.777842 -0.972211,-5.138974 -2.916633,-7.083395 -1.944422,-1.944422 -4.305554,-2.916644 -7.083283,-2.916644 -2.777842,0 -5.138974,0.972222 -7.083395,2.916644 -1.944422,1.944421 -2.916644,4.305553 -2.916644,7.083395 0,2.777729 0.972222,5.138861 2.916644,7.083283 1.944421,1.944422 4.305553,2.916633 7.083395,2.916633 z M 9.888914,17.666714 v -4.444457 h 11.111142 v 4.444457 z'
      fill='currentColor'
    />
  );
}

export default ZoomOutFilled;

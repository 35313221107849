import React from 'react';

function MarkerDotStackHighOutlined() {
  return (
    <>
      <path
        d='M 32.1742 5.4507 C 28.9575 2.48357 25.231 1 20.9983 1 C 16.7655 1 13.039 2.48357 9.8223 5.4507 C 6.60556 8.41783 4.99895 12.401 4.99895 17.4001 C 4.99895 20.7328 6.32432 24.3574 8.97505 28.2737 C 11.6258 32.19 15.6335 36.4333 21.0018 41 C 26.37 36.4333 30.3778 32.19 33.0285 28.2737 C 35.6792 24.3574 37.0046 20.7328 37.0046 17.4001 C 37.0046 12.401 35.398 8.41783 32.1777 5.4507 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 7.74913 17.249 C 7.74913 18.0026 7.12742 18.6307 6.35819 18.6307 L 5.05153 18.6307 C 4.94616 17.9293 4.89347 17.2385 4.89347 16.5372 C 4.89347 16.3174 4.89347 16.0871 4.91455 15.8673 L 6.35819 15.8673 C 7.12742 15.8673 7.74913 16.4849 7.74913 17.249 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 12.9547 15.8673 L 9.63534 15.8673 C 8.86716 15.8673 8.2444 16.4859 8.2444 17.249 C 8.2444 18.0121 8.86716 18.6307 9.63534 18.6307 L 12.9547 18.6307 C 13.7229 18.6307 14.3456 18.0121 14.3456 17.249 C 14.3456 16.4859 13.7229 15.8673 12.9547 15.8673 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 19.5406 15.8673 L 16.2212 15.8673 C 15.4531 15.8673 14.8303 16.4859 14.8303 17.249 C 14.8303 18.0121 15.4531 18.6307 16.2212 18.6307 L 19.5406 18.6307 C 20.3088 18.6307 20.9315 18.0121 20.9315 17.249 C 20.9315 16.4859 20.3088 15.8673 19.5406 15.8673 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 26.1371 15.8673 L 22.8178 15.8673 C 22.0495 15.8673 21.4268 16.4859 21.4268 17.249 C 21.4268 18.0121 22.0495 18.6307 22.8178 18.6307 L 26.1371 18.6307 C 26.9053 18.6307 27.528 18.0121 27.528 17.249 C 27.528 16.4859 26.9053 15.8673 26.1371 15.8673 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 32.723 15.8673 L 29.4037 15.8673 C 28.6355 15.8673 28.0128 16.4859 28.0128 17.249 C 28.0128 18.0121 28.6355 18.6307 29.4037 18.6307 L 32.723 18.6307 C 33.4912 18.6307 34.114 18.0121 34.114 17.249 C 34.114 16.4859 33.4912 15.8673 32.723 15.8673 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 37.0046 16.4938 C 37.0046 17.1474 37.0185 17.9293 36.9131 18.6307 L 36.0002 18.6307 C 35.2309 18.6307 34.6092 18.0026 34.6092 17.249 C 34.6092 16.4953 35.2309 15.8673 36.0002 15.8673 L 36.9377 15.8673 C 36.9588 16.0871 37.0046 16.274 37.0046 16.4938 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 11.0474 20.5881 C 11.0474 21.3417 10.4151 21.9698 9.65641 21.9698 L 6.33711 21.9698 C 6.16851 21.9698 5.99991 21.9384 5.85238 21.8861 C 5.61002 21.1638 5.40981 20.452 5.25175 19.7402 C 5.49411 19.4157 5.89453 19.2064 6.33711 19.2064 L 9.65641 19.2064 C 10.4151 19.2064 11.0474 19.824 11.0474 20.5881 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 16.2529 19.2064 L 12.9336 19.2064 C 12.1653 19.2064 11.5426 19.825 11.5426 20.5881 C 11.5426 21.3512 12.1653 21.9698 12.9336 21.9698 L 16.2529 21.9698 C 17.0211 21.9698 17.6438 21.3512 17.6438 20.5881 C 17.6438 19.825 17.0211 19.2064 16.2529 19.2064 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 22.8388 19.2064 L 19.5195 19.2064 C 18.7513 19.2064 18.1286 19.825 18.1286 20.5881 C 18.1286 21.3512 18.7513 21.9698 19.5195 21.9698 L 22.8388 21.9698 C 23.607 21.9698 24.2298 21.3512 24.2298 20.5881 C 24.2298 19.825 23.607 19.2064 22.8388 19.2064 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 29.4353 19.2064 L 26.116 19.2064 C 25.3478 19.2064 24.7251 19.825 24.7251 20.5881 C 24.7251 21.3512 25.3478 21.9698 26.116 21.9698 L 29.4353 21.9698 C 30.2035 21.9698 30.8263 21.3512 30.8263 20.5881 C 30.8263 19.825 30.2035 19.2064 29.4353 19.2064 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 36.8642 19.4995 C 36.6864 20.2985 36.4849 21.1324 36.1898 21.9488 C 36.1371 21.9698 36.0739 21.9698 36.0212 21.9698 L 32.7019 21.9698 C 31.9432 21.9698 31.311 21.3417 31.311 20.5881 C 31.311 19.8344 31.9432 19.2064 32.7019 19.2064 L 36.0212 19.2064 C 36.3374 19.2064 36.6324 19.3111 36.8642 19.4995 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 7.74914 23.9272 C 7.74914 24.3773 7.52785 24.775 7.19065 25.0263 C 7.10798 24.862 7.02702 24.6973 6.94784 24.5323 C 6.63344 23.8767 6.34729 23.2144 6.09476 22.5455 L 6.35819 22.5455 C 7.12743 22.5455 7.74914 23.1631 7.74914 23.9272 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 12.9547 22.5455 L 9.63534 22.5455 C 8.86716 22.5455 8.2444 23.1641 8.2444 23.9272 C 8.2444 24.6903 8.86716 25.3089 9.63534 25.3089 L 12.9547 25.3089 C 13.7229 25.3089 14.3456 24.6903 14.3456 23.9272 C 14.3456 23.1641 13.7229 22.5455 12.9547 22.5455 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 19.5406 22.5455 L 16.2212 22.5455 C 15.4531 22.5455 14.8303 23.1641 14.8303 23.9272 C 14.8303 24.6903 15.4531 25.3089 16.2212 25.3089 L 19.5406 25.3089 C 20.3088 25.3089 20.9315 24.6903 20.9315 23.9272 C 20.9315 23.1641 20.3088 22.5455 19.5406 22.5455 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 26.1371 22.5455 L 22.8178 22.5455 C 22.0495 22.5455 21.4268 23.1641 21.4268 23.9272 C 21.4268 24.6903 22.0495 25.3089 22.8178 25.3089 L 26.1371 25.3089 C 26.9053 25.3089 27.528 24.6903 27.528 23.9272 C 27.528 23.1641 26.9053 22.5455 26.1371 22.5455 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 32.723 22.5455 L 29.4037 22.5455 C 28.6355 22.5455 28.0128 23.1641 28.0128 23.9272 C 28.0128 24.6903 28.6355 25.3089 29.4037 25.3089 L 32.723 25.3089 C 33.4912 25.3089 34.114 24.6903 34.114 23.9272 C 34.114 23.1641 33.4912 22.5455 32.723 22.5455 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 35.9791 22.5455 C 35.684 23.3201 35.3469 24.0842 34.9675 24.8378 C 34.7462 24.5971 34.6092 24.2726 34.6092 23.9272 C 34.6092 23.1735 35.2204 22.556 35.9791 22.5455 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 10.7945 27.2663 C 10.7945 28.0304 10.1728 28.648 9.41409 28.648 L 9.34031 28.648 C 8.73967 27.7582 8.16011 26.8267 7.65431 25.8846 L 9.41409 25.8846 C 10.1728 25.8846 10.7945 26.5126 10.7945 27.2663 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 16 25.8846 L 12.6807 25.8846 C 11.9125 25.8846 11.2898 26.5032 11.2898 27.2663 C 11.2898 28.0294 11.9125 28.648 12.6807 28.648 L 16 28.648 C 16.7682 28.648 17.391 28.0294 17.391 27.2663 C 17.391 26.5032 16.7682 25.8846 16 25.8846 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 22.5964 25.8846 L 19.2771 25.8846 C 18.5089 25.8846 17.8862 26.5032 17.8862 27.2663 C 17.8862 28.0294 18.5089 28.648 19.2771 28.648 L 22.5964 28.648 C 23.3647 28.648 23.9874 28.0294 23.9874 27.2663 C 23.9874 26.5032 23.3647 25.8846 22.5964 25.8846 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 29.1824 25.8846 L 25.8631 25.8846 C 25.0949 25.8846 24.4721 26.5032 24.4721 27.2663 C 24.4721 28.0294 25.0949 28.648 25.8631 28.648 L 29.1824 28.648 C 29.9506 28.648 30.5734 28.0294 30.5734 27.2663 C 30.5734 26.5032 29.9506 25.8846 29.1824 25.8846 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 34.4195 25.8846 C 33.9137 26.8267 33.3342 27.7582 32.7335 28.648 L 32.4595 28.648 C 31.6903 28.648 31.0686 28.0304 31.0686 27.2663 C 31.0686 26.5022 31.6903 25.8846 32.4595 25.8846 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 14.1981 30.6054 C 14.1981 31.3695 13.5659 31.9871 12.8072 31.9871 L 11.8272 31.9871 C 11.1106 31.1078 10.4046 30.1762 9.73022 29.2237 L 12.8072 29.2237 C 13.5659 29.2237 14.1981 29.8517 14.1981 30.6054 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 19.4036 29.2237 L 16.0843 29.2237 C 15.3161 29.2237 14.6933 29.8423 14.6933 30.6054 C 14.6933 31.3685 15.3161 31.9871 16.0843 31.9871 L 19.4036 31.9871 C 20.1718 31.9871 20.7946 31.3685 20.7946 30.6054 C 20.7946 29.8423 20.1718 29.2237 19.4036 29.2237 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 25.9896 29.2237 L 22.6702 29.2237 C 21.902 29.2237 21.2793 29.8423 21.2793 30.6054 C 21.2793 31.3685 21.902 31.9871 22.6702 31.9871 L 25.9896 31.9871 C 26.7577 31.9871 27.3805 31.3685 27.3805 30.6054 C 27.3805 29.8423 26.7577 29.2237 25.9896 29.2237 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 32.3436 29.2237 C 31.6692 30.1762 30.9632 31.1078 30.2467 31.9871 L 29.2562 31.9871 C 28.4974 31.9871 27.8757 31.3695 27.8757 30.6054 C 27.8757 29.8413 28.4974 29.2237 29.2562 29.2237 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 17.6334 33.9445 C 17.6334 34.7086 17.0116 35.3262 16.2424 35.3262 L 14.8945 35.3262 C 14.1148 34.5097 13.1865 33.62 12.3962 32.6675 C 12.5543 32.6047 12.7439 32.5628 12.9336 32.5628 L 16.2424 32.5628 C 17.0116 32.5628 17.6334 33.1908 17.6334 33.9445 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 22.8388 32.5628 L 19.5195 32.5628 C 18.7513 32.5628 18.1286 33.1814 18.1286 33.9445 C 18.1286 34.7076 18.7513 35.3262 19.5195 35.3262 L 22.8388 35.3262 C 23.607 35.3262 24.2298 34.7076 24.2298 33.9445 C 24.2298 33.1814 23.607 32.5628 22.8388 32.5628 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 29.7409 32.6047 C 28.919 33.5781 28.0971 34.4888 27.2962 35.3262 L 26.116 35.3262 C 25.3468 35.3262 24.7251 34.7086 24.7251 33.9445 C 24.7251 33.1804 25.3468 32.5628 26.116 32.5628 L 29.4353 32.5628 C 29.5407 32.5628 29.6461 32.5628 29.7409 32.6047 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 20.9315 37.2941 C 20.9315 38.0477 20.3092 38.6354 19.5406 38.6653 L 18.3936 38.7097 C 17.6033 37.9978 16.6122 37.0887 15.6533 36.1152 C 15.864 35.9896 15.9684 35.9122 16.2213 35.9122 L 19.5406 35.9122 C 20.3098 35.9122 20.9315 36.5298 20.9315 37.2941 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 26.386 35.9122 C 25.3955 36.9378 24.3459 37.8238 23.5345 38.567 L 22.8178 38.6653 C 22.0558 38.7697 21.4268 38.0477 21.4268 37.2941 C 21.4268 36.5402 22.0485 35.9122 22.8178 35.9122 L 26.1371 35.9122 C 26.3162 35.9122 26.228 35.8494 26.386 35.9122 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
    </>
  );
}

export default MarkerDotStackHighOutlined;

import { Components, Theme } from '@mui/material';

import { palette } from '../palette';

export default {
  styleOverrides: {
    root: {
      '.Mui-checked': {
        color: palette.secondary.main,
      },
      color: palette.secondary.main,
    },
  },
} as Components<Theme>['MuiRadio'];

import { Expose, Type } from 'class-transformer';
import Site from 'models/Site/Site.model';

class Actor {
  @Expose()
  id: number;

  @Expose()
  name: string;

  @Expose()
  address: string;

  @Expose()
  extraAddress?: string;

  @Expose()
  postalCode: string;

  @Expose()
  city: string;

  @Expose()
  latitude: number;

  @Expose()
  longitude: number;

  @Expose()
  type: string;

  @Expose()
  status: string;

  @Expose()
  @Type(() => Site)
  public sites?: Array<Site>;

  @Expose()
  countSites?: number;

  @Expose()
  @Type(() => Actor)
  public companies?: Array<this>;

  @Expose()
  countCompanies?: number;

  @Type(() => Actor)
  // eslint-disable-next-line no-use-before-define
  author?: Actor;

  @Expose()
  ecologicalSensitivityDistribution: Record<
    string,
    {
      occurrence: number;
      percentage: number;
    }
  >;

  @Expose()
  progressStatusDistribution: Record<
    string,
    {
      occurrence: number;
      percentage: number;
    }
  >;

  constructor(model: Partial<Actor> = {}) {
    if (model.sites && typeof model.sites === 'string') {
      this.sites = JSON.parse(model.sites);
    }

    if (model.author && typeof model.author === 'string') {
      this.author = JSON.parse(model.author);
    }

    Object.assign(this, model);
  }
}

export default Actor;

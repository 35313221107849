enum MapReducerActionTypeEnum {
  SET_COMPANY_SELECTED = 'setCompanySelected',
  SET_SITE_SELECTED = 'setSiteSelected',
  SET_CENTER_COORDINATES = 'setCenterCoordinates',
  SET_ZOOM = 'setZoom',
  SET_ORDERED_CUSTOM_LAYER_IDS = 'setOrderedCustomLayerIds',
  SET_MAP = 'setMap',
  SET_COMPANY_DATA = 'setCompanyData',
  SET_SITE_DATA = 'setSiteData',
  SET_SITE_INDICATOR_MARKER_STYLE = 'setSiteIndicatorMarkerStyle',
  SET_SITE_INDICATOR_LAYER_IDS = 'setSiteIndicatorLayerIds',
  SET_SITE_FEATURE_LAYER_IDS = 'setSiteFeatureLayerIds',
  SET_LAYER_VISIBILITY = 'setLayerVisibility',
  SET_ACTOR_LAYER_IDS = 'setActorLayerIds',
  SET_MAP_SERVICE_LAYERS = 'setMapServiceLayers',
  SET_MAP_STYLE = 'setMapStyle',
  CLEAN_MAP = 'cleanMap',
}

export { MapReducerActionTypeEnum };

import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {
    color: ({ palette }) => palette.text.primary,
    variantMapping: {
      body1Bold: 'p',
      body2Bold: 'p',
      subtitle: 'p',
      subtitleBold: 'p',
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: theme.fontFamily.main,
      letterSpacing: 0,
    }),
  },
  variants: [
    {
      props: { variant: 'body1' },
      style: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '18px',
      },
    },
    {
      props: { variant: 'body1Bold' },
      style: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '18px',
      },
    },
    {
      props: { variant: 'body2' },
      style: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '16px',
      },
    },
    {
      props: { variant: 'body2Bold' },
      style: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '16px',
      },
    },
    {
      props: { variant: 'h1' },
      style: ({ theme }) => ({
        fontFamily: theme.fontFamily.main,
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '28px',
        [theme.breakpoints.up('md')]: {
          fontSize: 24,
          lineHeight: '46px',
        },
      }),
    },
    {
      props: { variant: 'h2' },
      style: ({ theme }) => ({
        fontFamily: theme.fontFamily.main,
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '24px',
        [theme.breakpoints.up('md')]: {
          fontSize: 28,
          lineHeight: '24px',
        },
      }),
    },
    {
      props: { variant: 'h3' },
      style: ({ theme }) => ({
        fontFamily: theme.fontFamily.main,
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '20px',
        [theme.breakpoints.up('md')]: {
          fontSize: 28,
          lineHeight: '32px',
        },
      }),
    },
    {
      props: { variant: 'h4' },
      style: ({ theme }) => ({
        fontFamily: theme.fontFamily.main,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '18px',
        [theme.breakpoints.up('md')]: {
          fontSize: 24,
          lineHeight: '28px',
        },
      }),
    },
    {
      props: { variant: 'subtitle' },
      style: ({ theme }) => ({
        fontSize: 18,
        fontWeight: 400,
        lineHeight: '24px',
        [theme.breakpoints.up('md')]: {
          fontSize: 20,
          lineHeight: '28px',
        },
      }),
    },
    {
      props: { variant: 'subtitleBold' },
      style: ({ theme }) => ({
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '24px',
        [theme.breakpoints.up('md')]: {
          fontSize: 20,
          lineHeight: '28px',
        },
      }),
    },
  ],
} as Components<Theme>['MuiTypography'];

import React from 'react';

function ChevronLeftFilled() {
  return (
    <path
      d='M 28.65,1 33.35,5.6999999 18.083333,21 33.35,36.3 28.65,41 8.65,21 Z'
      fill='currentColor'
    />
  );
}

export default ChevronLeftFilled;

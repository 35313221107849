import { Components, Theme } from '@mui/material';

export default {
  defaultProps: { color: 'secondary' },
  styleOverrides: {
    label: ({ ownerState, theme }) => {
      switch (ownerState.labelPlacement) {
        case 'bottom':
          return {
            marginTop: theme.spacing(1),
          };
        case 'start':
          return {
            marginRight: theme.spacing(1),
          };
        case 'top':
          return {
            marginBottom: theme.spacing(1),
          };
        default:
          return {
            marginLeft: theme.spacing(1),
          };
      }
    },
  },
} as Components<Theme>['MuiFormControlLabel'];

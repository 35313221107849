import RouteConfig from 'interfaces/RouteConfig.interface';
import React, { Suspense } from 'react';

import Loader from '../../components/Loader';
import routesPaths from './routesPaths';

const SitesPageRoute = React.lazy(() => import('routes/Sites/routes/Sites'));
const SitePageRoute = React.lazy(() => import('routes/Sites/routes/Site'));
const SiteEditPageRoute = React.lazy(
  () => import('routes/Sites/routes/SiteEdit')
);

const routes: Record<string, RouteConfig> = {
  siteCreate: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <SiteEditPageRoute />
      </Suspense>
    ),
    path: routesPaths.siteNew,
    roles: 'ADMIN',
  },
  siteEdit: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <SiteEditPageRoute />
      </Suspense>
    ),
    path: routesPaths.siteEdit,
    roles: 'ADMIN',
  },
  siteView: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <SitePageRoute />
      </Suspense>
    ),
    exact: true,
    path: routesPaths.site,
    roles: 'ADMIN',
  },
  sites: {
    Component: () => (
      <Suspense fallback={<Loader />}>
        <SitesPageRoute />
      </Suspense>
    ),
    exact: true,
    path: routesPaths.sites,
    roles: 'ADMIN',
  },
};

export default routes;

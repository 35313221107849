import CustomSwitch from 'components/CustomSwitch';
import React from 'react';

import routes from './routes';

function PrescribersRoutes() {
  return <CustomSwitch routes={routes} />;
}

export default PrescribersRoutes;

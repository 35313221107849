import React from 'react';

function MarkerFullFilled() {
  return (
    <path
      d='M 32.816307,5.4506923 C 29.414154,2.4835646 25.472923,1 20.996307,1 16.519662,1 12.578446,2.4835646 9.176354,5.4506923 5.7742616,8.4178308 4.075077,12.400954 4.075077,17.4 c 0,3.33277 1.4017354,6.957385 4.2052,10.873692 C 11.083754,32.19 15.322431,36.43323 21,41 26.677539,36.43323 30.916307,32.19 33.719692,28.273692 36.523231,24.357385 37.924923,20.73277 37.924923,17.4 37.924923,12.400954 36.225692,8.4178308 32.82,5.4506923 Z'
      fill='currentColor'
    />
  );
}

export default MarkerFullFilled;

/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Components, Theme } from '@mui/material';

import { palette } from '../palette';

export default {
  styleOverrides: {
    'html, body, #root': {
      background: palette.background.paper,
      margin: 0,
      width: '100%',
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    ':focus': {
      outline: 'none',
    },
    '::-moz-focus-inner': {
      border: 0,
    },
    'h1,h2,h3,h4,h5,h6,p,ul,li': {
      margin: 0,
      padding: 0,
      border: 0,
      outline: 0,
      fontWeight: 'inherit',
      fontStyle: 'inherit',
      fontSize: ' 100%',
      fontFamily: 'inherit',
      verticalAlign: 'baseline',
    },
    ul: {
      listStyle: 'none',
    },
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    button: {
      border: 'none',
      background: 'transparent',
      color: 'inherit',
      font: 'inherit',
    },
    "input, input[type='checkbox'], input[type='radio'], select, button": {
      '-moz-appearance': 'none',
      '-webkit-appearance': 'none',
      appearance: 'none',
      outline: 'none',
    },
    'input, textarea, button, select, a': {
      '-webkit-tap-highlight-color': 'transparent',
    },
  },
} as Components<Theme>['MuiCssBaseline'];

import React from 'react';

function PlusOutlined() {
  return (
    <path
      d='m 19,31 h 4 v -8 h 8 v -4 h -8 v -8 h -4 v 8 h -8 v 4 h 8 z m 2,10 C 18.23334,41 15.63334,40.475 13.2,39.425 10.76666,38.375 8.65,36.95 6.85,35.15 5.05,33.35 3.625,31.2334 2.575,28.8 1.525,26.3666 1,23.7666 1,21 1,18.23334 1.525,15.63334 2.575,13.2 3.625,10.76666 5.05,8.65 6.85,6.85 8.65,5.05 10.76666,3.625 13.2,2.575 15.63334,1.525 18.23334,1 21,1 c 2.7666,0 5.3666,0.525 7.8,1.575 2.4334,1.05 4.55,2.475 6.35,4.275 1.8,1.8 3.225,3.91666 4.275,6.35 1.05,2.43334 1.575,5.03334 1.575,7.8 0,2.7666 -0.525,5.3666 -1.575,7.8 -1.05,2.4334 -2.475,4.55 -4.275,6.35 -1.8,1.8 -3.9166,3.225 -6.35,4.275 C 26.3666,40.475 23.7666,41 21,41 Z m 0,-4 c 4.4666,0 8.25,-1.55 11.35,-4.65 C 35.45,29.25 37,25.4666 37,21 37,16.53334 35.45,12.75 32.35,9.65 29.25,6.55 25.4666,5 21,5 16.53334,5 12.75,6.55 9.65,9.65 6.55,12.75 5,16.53334 5,21 5,25.4666 6.55,29.25 9.65,32.35 12.75,35.45 16.53334,37 21,37 Z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default PlusOutlined;

import React from 'react';

function InformationOutlined() {
  return (
    <>
      <path
        d='M 41,21 A 20,20 0 0 1 21,41 20,20 0 0 1 1,21 20,20 0 0 1 21,1 20,20 0 0 1 41,21 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 21.748133,4.2421867 H 28.0268 L 26.845422,9.9941778 H 20.5668 Z m -1.837644,8.9377243 h 6.278667 l -4.856667,23.494711 h -6.278667 z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
    </>
  );
}

export default InformationOutlined;

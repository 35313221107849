import { useTheme } from '@mui/material';
import React from 'react';

function MarkerTriangleFilled() {
  const {
    palette: { tertiary },
  } = useTheme();

  return (
    <>
      <path
        d='M 32.816307,5.4506923 C 29.414153,2.4835646 25.472923,1 20.996307,1 16.519662,1 12.578447,2.4835646 9.1763539,5.4506923 5.7742616,8.4178307 4.075077,12.400953 4.075077,17.4 c 0,3.332769 1.4017353,6.957385 4.2052,10.873692 C 11.083754,32.19 15.32243,36.43323 21,41 26.677538,36.43323 30.916307,32.19 33.719692,28.273692 36.52323,24.357385 37.924923,20.732769 37.924923,17.4 37.924923,12.400953 36.225692,8.4178307 32.82,5.4506923 Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1'
      />
      <path
        d='M 34.012007,22.092924 H 7.9879922 L 21.002468,5.569877 Z'
        fill={tertiary.light}
        strokeWidth='1'
      />
    </>
  );
}

export default MarkerTriangleFilled;

import React from 'react';

function MapSatelliteOutlined() {
  return (
    <>
      <path
        d='M 1,41 H 41 V 1 H 1 Z m 37.333332,-2.666667 h -2.114415 l -8.666667,-8.666666 4.576,-4.575917 6.205082,6.205083 z M 3.6666671,3.6666672 H 38.333332 V 27.525 L 32.12825,21.319917 25.666668,27.781083 16.333332,18.44775 3.6666671,31.114417 Z m 0,31.2189158 L 16.333332,22.218917 32.44775,38.333333 H 3.6666671 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='1'
      />
      <path
        d='M 9.6666671,6.3333328 H 6.3333329 V 9.6666672 A 3.3333332,3.3333333 0 0 0 9.6666671,6.3333328 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='1'
      />
      <path
        d='M 6.3333329,13.305083 V 16.05 A 12.705667,12.705667 0 0 0 16.05,6.3333328 H 13.305083 A 10.03925,10.03925 0 0 1 6.3333329,13.305083 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='1'
      />
    </>
  );
}

export default MapSatelliteOutlined;

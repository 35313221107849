import React from 'react';

function CrossFilled() {
  return (
    <path
      d='M 4.99985 41 L 0.999936 37 L 16.9999 21 L 0.999936 4.99997 L 4.99985 0.999915 L 20.9999 17 L 37 0.999915 L 40.9999 4.99997 L 25 21 L 40.9999 37 L 37 41 L 20.9999 24.9999 Z'
      fill='currentColor'
    />
  );
}

export default CrossFilled;

import React, { createContext, useReducer } from 'react';
import useSafeContext from 'utils/useSafeContext';

import { WebAppReducerActionTypeEnum } from './WebAppContext.enum';

// Reducer function to manage webapp state
type Action = {
  type: WebAppReducerActionTypeEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};

interface WebAppState {
  selectedPrescriberId?: number;
  selectedCompanyId?: number;
  selectedSiteId?: number;
}

interface WebAppContextData {
  webAppDispatch: React.Dispatch<Action>;
  webAppState: WebAppState;
}

/**
 * Function to get the initial state of the webapp.
 * @returns {WebAppState} The initial webapp state.
 */
const getInitialState = (): WebAppState => ({
  selectedCompanyId: undefined,
  selectedPrescriberId: undefined,
  selectedSiteId: undefined,
});

/**
 * Context for managing webapp-related information.
 */
const WebAppContext: React.Context<WebAppContextData> =
  createContext<WebAppContextData>({
    webAppDispatch: () => {},
    webAppState: getInitialState(),
  });

/**
 * Custom hook to access the WebApp context.
 * @returns {WebAppContextData} The WebApp context data.
 */
const useWebApp = () => useSafeContext(WebAppContext, 'WebApp');

/**
 * Reducer function to manage WebApp state.
 * @param {WebAppState} state - The current WebApp state.
 * @param {Action} action - The action to perform on the state.
 * @returns {WebAppState} The new WebApp state.
 * @throws {Error} If an unsupported action type is provided.
 */
const reducer = (state: WebAppState, action: Action): WebAppState => {
  switch (action.type) {
    case WebAppReducerActionTypeEnum.SET_PRESCRIBER_SELECTED: {
      return {
        ...state,
        selectedCompanyId: undefined,
        selectedPrescriberId: action.value || undefined,
        selectedSiteId: undefined,
      };
    }
    case WebAppReducerActionTypeEnum.SET_COMPANY_SELECTED: {
      return {
        ...state,
        selectedCompanyId: action.value || undefined,
        selectedSiteId: undefined,
      };
    }

    case WebAppReducerActionTypeEnum.SET_SITE_SELECTED: {
      return {
        ...state,
        selectedSiteId: action.value || undefined,
      };
    }

    default:
      return state;
      throw new Error(`WebApp action type not handled: ${action.type}`);
  }
};

/**
 * Component that provides the webapp context to its children.
 * @param {React.ReactNode} children - The children components.
 * @returns {React.ReactNode} The webappProvider component.
 */
function WebAppProvider({ children }: { children: React.ReactNode }) {
  const [webAppState, webAppDispatch] = useReducer<
    React.Reducer<WebAppState, Action>
  >(reducer, getInitialState());

  return (
    <WebAppContext.Provider
      value={{
        webAppDispatch,
        webAppState,
      }}
    >
      {children}
    </WebAppContext.Provider>
  );
}

export { WebAppProvider, useWebApp };

import { Expose } from 'class-transformer';

class ArtificialAreaType {
  @Expose()
  type: string;

  @Expose()
  label: string;
  @Expose()
  colorHex?: string;

  constructor(model: Partial<ArtificialAreaType> = {}) {
    Object.assign(this, model);
  }
}

export default ArtificialAreaType;

import { useTheme } from '@mui/material';
import React from 'react';

function CheckboxChecked() {
  const {
    palette: { common },
  } = useTheme();

  return (
    <>
      <circle
        cx='24'
        cy='24'
        fill='currentColor'
        r='20'
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        d='M16 24L22 30L34 18'
        stroke={common.white}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
    </>
  );
}

export default CheckboxChecked;

import { StyledOptions } from '@emotion/styled';
import { styled as materialStyled } from '@mui/material';
import { MuiStyledOptions } from '@mui/system';
import { startsWith } from 'lodash';
import { ComponentClass } from 'react';

const styled: typeof materialStyled = (
  tag: ComponentClass<unknown, unknown>,
  options?: StyledOptions<unknown> & MuiStyledOptions
) =>
  materialStyled(tag, {
    shouldForwardProp: (prop) => !startsWith(prop.toString(), '$'),
    ...options,
  });

export { styled };

import { EndpointName, SourceName } from './api-endpoints.type';
import ApiSource from './config';

interface ApiUrlBuilderProps {
  apiSourceName: SourceName;
  endpoint: EndpointName;
  params?: Record<string, unknown>;
  queryParams?: Record<string, unknown>;
}

function apiUrlBuilder({
  apiSourceName,
  endpoint,
  params,
  queryParams,
}: ApiUrlBuilderProps): string {
  const { endpoints, url } = ApiSource[apiSourceName];
  const endpointPath = endpoints[endpoint];

  if (!endpointPath) {
    throw new Error(`Unknown endpoint: ${endpoint}`);
  }

  let builtEndpoint = endpointPath;

  if (params) {
    Object.keys(params).forEach((key) => {
      const placeholder = `:${key}`;
      const value = params[key];
      builtEndpoint = builtEndpoint.replace(
        placeholder,
        encodeURIComponent(String(value))
      );
    });
  }
  if (queryParams) {
    const queryString = Object.keys(queryParams)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            queryParams[key] as string | number | boolean
          )}`
      )
      .join('&');
    builtEndpoint += `?${queryString}`;
  }

  return `${url}/${builtEndpoint}`;
}

export { apiUrlBuilder };

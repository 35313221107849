import { DialogActions, DialogContent, styled } from '@mui/material';
import Dialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import { Button } from 'design-system/button/action-button/Button';
import { IconButton } from 'design-system/button/icon-button/IconButton';
import * as React from 'react';

import wording from './wording';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

const CloseButtonDialog = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
`;

interface ModalDialogProps extends Omit<MuiDialogProps, 'open'> {
  handleClose: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  withCloseButton?: boolean;
}

function ModalDialog({
  children,
  handleClose,
  isOpen = false,
  onCancel,
  onConfirm,
  withCloseButton = true,
}: ModalDialogProps) {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    handleClose();
  };

  return (
    <BootstrapDialog
      aria-labelledby='customized-dialog-title'
      fullWidth
      maxWidth='lg'
      onClose={handleClose}
      open={isOpen}
    >
      {withCloseButton && (
        <CloseButtonDialog
          aria-label='close'
          color='tertiary'
          icon='cross'
          iconVariant='filled'
          onClick={handleClose}
        />
      )}
      <DialogContent>{children}</DialogContent>
      {(onConfirm || onCancel) && (
        <DialogActions>
          <Button color='primary' onClick={handleCancel}>
            {wording.cancelButtonLabel}
          </Button>
          {onConfirm && (
            <Button
              color='primary'
              onClick={() => {
                onConfirm();
                handleClose();
              }}
            >
              {wording.confirmButtonLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}

export default ModalDialog;

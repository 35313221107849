import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import { IconSize } from 'design-system/icon/Icon.types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../../icon/Icon';
import type { IconName } from '../../icon/svg/IconMapping';
import { ButtonSize, ICON_SIZE_MAP } from '../shared/types';
import type {
  ButtonColor,
  ButtonVariant,
  ExcludedMuiButtonProps,
} from './Button.types';

export interface ButtonProps
  extends Omit<MuiButtonProps, ExcludedMuiButtonProps> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  iconSize?: IconSize;
  color?: ButtonColor;
  startIcon?: IconName;
  endIcon?: IconName;
  to?: string;
}

export function Button({
  children,
  endIcon,
  iconSize = 'md',
  size = 'md',
  startIcon,
  to,
  variant = 'contained',
  ...props
}: ButtonProps) {
  const buttonStartIcon = startIcon && (
    <Icon
      color='inherit'
      name={startIcon}
      size={iconSize || ICON_SIZE_MAP[size]}
    />
  );
  const buttonEndIcon = endIcon && (
    <Icon
      color='inherit'
      name={endIcon}
      size={iconSize || ICON_SIZE_MAP[size]}
    />
  );

  const button = (
    <MuiButton
      disableElevation
      disableFocusRipple={variant === 'outlined'}
      endIcon={buttonEndIcon}
      size={size}
      startIcon={buttonStartIcon}
      variant={variant}
      {...props}
    >
      {children}
    </MuiButton>
  );

  return to ? <Link to={to}>{button}</Link> : button;
}

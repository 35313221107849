import React, { createContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSafeContext from 'utils/useSafeContext';

import { useSafeHistory } from './useSafeHistory';

// Define the shape of the history entry
interface HistoryEntry {
  hash: string;
  key?: string;
  pathname: string;
  search: string;
}

interface ExtendedLocation {
  hash: string;
  key?: string;
  pathname: string;
  search: string;
}

// Define the shape of the browser history context
interface BrowserHistoryContextType {
  historyIndex: number;
  historyStack: HistoryEntry[];
}

// Create a context for browser history
const BrowserHistoryContext = createContext<
  BrowserHistoryContextType | undefined
>(undefined);

// Define a hook to access the browser history context
const useBrowserHistory = () =>
  useSafeContext(BrowserHistoryContext, 'BrowserHistory');

// Function to retrieve the initial browser history from sessionStorage
const getInitialBrowserHistory = ({
  hash,
  key,
  pathname,
  search,
}: ExtendedLocation): BrowserHistoryContextType => {
  const storedHistory = sessionStorage.getItem('browserHistory');
  if (!storedHistory)
    return {
      historyIndex: 0,
      historyStack: [{ hash, key, pathname, search }],
    };
  return JSON.parse(storedHistory);
};

// BrowserHistoryProvider component
function BrowserHistoryProvider({ children }: { children: React.ReactNode }) {
  const { hash, key, pathname, search } = useLocation();
  const { history } = useSafeHistory();
  const [{ historyIndex, historyStack }, setBrowserHistory] =
    useState<BrowserHistoryContextType>(() =>
      getInitialBrowserHistory({ hash, key, pathname, search })
    );

  // Use a ref for history because it can be mutated between re-renders
  const historyRef = useRef({ ...history });
  historyRef.current = { ...history };

  useEffect(() => {
    setBrowserHistory(
      ({ historyIndex: prevIndex, historyStack: prevStack }) => {
        switch (historyRef.current.action) {
          case 'REPLACE': {
            const newEntry = { hash, key, pathname, search };
            const stackCopy = [...prevStack];
            stackCopy[prevIndex] = newEntry;
            return { historyIndex: prevIndex, historyStack: stackCopy };
          }
          case 'PUSH': {
            const newEntry = { hash, key, pathname, search };
            const beforePrevious = prevStack.slice(0, prevIndex + 1);
            return {
              historyIndex: prevIndex + 1,
              historyStack: [...beforePrevious, newEntry],
            };
          }
          case 'POP': {
            const currentRouteIndex = prevStack.findIndex(
              ({ key: entryKey }) => entryKey === key
            );
            if (currentRouteIndex < 0)
              return {
                historyIndex: 0,
                historyStack: [{ hash, key, pathname, search }],
              };
            return { historyIndex: currentRouteIndex, historyStack: prevStack };
          }
          default:
            return { historyIndex: prevIndex, historyStack: prevStack };
        }
      }
    );
  }, [pathname, search, hash, key]);

  useEffect(() => {
    sessionStorage.setItem(
      'browserHistory',
      JSON.stringify({ historyIndex, historyStack })
    );
  }, [historyIndex, historyStack]);

  return (
    <BrowserHistoryContext.Provider value={{ historyIndex, historyStack }}>
      {children}
    </BrowserHistoryContext.Provider>
  );
}

export { BrowserHistoryProvider, useBrowserHistory };

import React from 'react';

function StackFilled() {
  return (
    <path
      d='M 1,41 V 36.555527 H 7.6666667 V 41 Z m 0,-8.88886 v -4.444473 h 17.777807 v 4.444473 z m 0,-8.888947 v -4.444386 h 40 v 4.444386 z m 0,-8.88886 V 9.88886 h 17.777807 v 4.444473 z M 1,5.4444733 V 1 H 7.6666667 V 5.4444733 Z M 12.11114,41 v -4.444473 h 6.666667 V 41 Z m 0,-35.5555267 V 1 h 6.666667 V 5.4444733 Z M 23.222193,41 V 36.555527 H 29.88886 V 41 Z m 0,-8.88886 V 27.666667 H 41 v 4.444473 z m 0,-17.777807 V 9.88886 H 41 v 4.444473 z m 0,-8.8888597 V 1 H 29.88886 V 5.4444733 Z M 34.333333,41 V 36.555527 H 41 V 41 Z m 0,-35.5555267 V 1 H 41 v 4.4444733 z'
      fill='currentColor'
    />
  );
}

export default StackFilled;

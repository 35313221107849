import useDeviceType from 'mui-theme/utils/useDeviceType';
import React, { createContext, useCallback, useReducer } from 'react';
import useSafeContext from 'utils/useSafeContext';

import { HEADER_PROPS } from './LayoutContext.constant';
import {
  LayoutReducerActionTypeEnum,
  PanelOpeningStatusEnum,
} from './LayoutContext.enum';
import wording from './wording';

// Reducer function to manage layout state
type Action = {
  type: LayoutReducerActionTypeEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  isDesktop?: boolean;
};

interface PanelStateProps {
  hasPanel: boolean;
  openingStatus?: PanelOpeningStatusEnum;
  component?: React.ReactNode;
}

interface LayoutState {
  pageTitle: string;
  headerHeight: number;
  sidePanel: PanelStateProps;
}

interface LayoutContextData {
  layoutDispatch: React.Dispatch<Action>;
  layoutState: LayoutState;
  openLeftPanel: () => void;
  closeLeftPanel: () => void;
}

// Define the initial state with a default pageTitle
const getInitialState = (): LayoutState => ({
  headerHeight: HEADER_PROPS.height,
  pageTitle: wording.layoutTitle,
  sidePanel: {
    component: null,
    hasPanel: true,
  },
});

// Create a context for Layout information
const LayoutContext: React.Context<LayoutContextData> =
  createContext<LayoutContextData>({
    closeLeftPanel: () => {},
    layoutDispatch: () => {},
    layoutState: getInitialState(),
    openLeftPanel: () => {},
  });

// Custom hook to access the Layout context
const useLayout = () => useSafeContext(LayoutContext, 'Layout');

// Reducer function to manage layout state
const reducer = (state: LayoutState, action: Action): LayoutState => {
  switch (action.type) {
    case LayoutReducerActionTypeEnum.SET_PAGE_TITLE: {
      document.title = action.value ? `${action.value}` : wording.layoutTitle;
      return {
        ...state,
        pageTitle: action.value || wording.layoutTitle,
      };
    }
    case LayoutReducerActionTypeEnum.SET_IS_SIDE_PANEL_OPEN: {
      const closedStatus = PanelOpeningStatusEnum.CLOSED;
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          openingStatus:
            action.value || action.isDesktop
              ? PanelOpeningStatusEnum.OPEN
              : closedStatus,
        },
      };
    }
    case LayoutReducerActionTypeEnum.SET_HAS_SIDE_PANEL: {
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          hasPanel: action.value,
        },
      };
    }
    case LayoutReducerActionTypeEnum.SET_SIDE_PANEL_COMPONENT: {
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          component: action.value,
        },
      };
    }
    default:
      throw new Error(`Layout action type not handled : ${action.type}`);
  }
};

function LayoutProvider({ children }: { children: React.ReactNode }) {
  const { isDesktop } = useDeviceType();
  const [layoutState, layoutDispatch] = useReducer<
    React.Reducer<LayoutState, Action>
  >(reducer, getInitialState());

  const openLeftPanel = useCallback(() => {
    layoutDispatch({
      isDesktop,
      type: LayoutReducerActionTypeEnum.SET_IS_SIDE_PANEL_OPEN,
      value: true,
    });
  }, [layoutDispatch, isDesktop]);

  const closeLeftPanel = useCallback(() => {
    layoutDispatch({
      isDesktop,
      type: LayoutReducerActionTypeEnum.SET_IS_SIDE_PANEL_OPEN,
      value: false,
    });
  }, [layoutDispatch, isDesktop]);

  return (
    <LayoutContext.Provider
      value={{
        closeLeftPanel,
        layoutDispatch,
        layoutState,
        openLeftPanel,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, useLayout };

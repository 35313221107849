import React from 'react';

function GridFilled() {
  return (
    <path
      d='M 1,41 V 1 H 41 V 41 Z M 36.555555,36.555555 V 23.222222 H 23.222222 v 13.333333 z m 0,-31.1111105 H 23.222222 V 18.777778 h 13.333333 z m -31.1111105,0 V 18.777778 H 18.777778 V 5.4444445 Z m 0,31.1111105 H 18.777778 V 23.222222 H 5.4444445 Z'
      fill='currentColor'
    />
  );
}

export default GridFilled;

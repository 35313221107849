import React from 'react';

function ChevronRightOutlined() {
  return (
    <path
      d='M 13.35,1 8.65,5.6999999 23.916667,21 8.65,36.3 l 4.7,4.7 20,-20 z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default ChevronRightOutlined;

const routesPaths = {
  auth: 'auth',
  companies: 'companies',
  home: '',
  maps: 'maps',
  prescribers: 'prescribers',
  sites: 'sites',
  users: 'users',
};

export default routesPaths;

import React from 'react';

function TurnCircleFilled() {
  return (
    <path
      d='m 32.319957,16.059979 -14.139953,14.140119 -8.480016,-8.480099 2.819996,-2.819913 5.66002,5.660019 11.320039,-11.320039 z M 5,21 C 5,16.340034 7.0399972,12.160051 10.239987,9.240049 l 4.759939,4.760071 V 2.0000538 H 3.0000083 L 7.3999967,6.4000587 C 3.4800076,10.040043 1,15.21998 1,21 1,31.380068 8.899995,39.900057 18.999975,40.899945 V 36.860006 C 11.119992,35.879979 5,29.139961 5,21 Z m 36,0 C 41,10.620047 33.100038,2.1000591 23.000025,1.100055 V 5.1400605 C 30.879959,6.1200536 36.99995,12.860039 36.99995,21 c 0,4.659966 -2.039997,8.840098 -5.239937,11.759984 L 26.999909,28.000045 V 40.000029 H 38.999892 L 34.599954,35.60009 C 38.519893,31.96004 41,26.78002 41,21 Z'
      fill='currentColor'
    />
  );
}

export default TurnCircleFilled;

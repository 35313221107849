import {
  Divider,
  Box as MuiBox,
  BoxProps as MuiBoxProps,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  styled,
} from '@mui/material';
import { useLayout } from 'design-components/LayoutContext';
import {
  IconButton,
  IconButtonProps,
} from 'design-system/button/icon-button/IconButton';
import { IconName } from 'design-system/icon/svg/IconMapping';
import React from 'react';

/**
 * Types and interfaces for the Panel component.
 */
import { AnchorType, ExcludedMuiDrawerProps } from './Panel.types';

/**
 * Props for the Drawer component used in the Panel.
 */
interface PanelDrawerProps
  extends Omit<MuiDrawerProps, ExcludedMuiDrawerProps> {
  gridAreaName: string;
  headerHeight: number;
  width: number;
}

/**
 * Props for the Panel component, extending MuiDrawerProps and excluding some.
 */
interface PanelProps extends Omit<MuiDrawerProps, ExcludedMuiDrawerProps> {
  width?: number;
  handleClose?: () => void;
  handleOpen?: () => void;
  isOpen?: boolean;
  children: React.ReactNode;
  gridAreaName?: string;
  withCollapse?: boolean;
  anchor?: AnchorType;
  multiIndex?: number;
  currentButtonOffSet?: number;
  iconOpen?: IconName;
  iconClose?: IconName;
}

/**
 * Styled MuiDrawer component for the Panel.
 */
const PanelDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    prop !== 'gridAreaName' && prop !== 'headerHeight' && prop !== 'width',
})<PanelDrawerProps>(({ gridAreaName, headerHeight, width }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    overflowY: 'auto',
    padding: 20,
    position: 'relative',
    width,
  },
  WebkitFlexShrink: 0,
  gridArea: gridAreaName,
  height: `calc(100vh - ${headerHeight}px)`,
  maxHeight: `calc(100vh - ${headerHeight}px)`,
  width,
}));

/**
 * Styled MuiBox component for the DrawerHeader in the Panel.
 */
const PanelDrawerHeader = styled(MuiBox)<MuiBoxProps & { anchor?: AnchorType }>(
  ({ anchor = 'left', theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: anchor === 'left' ? 'flex-end' : 'flex-start',
    padding: theme.spacing(0, 1),
  })
);

/**
 * Props for the IconButton component used in the Panel.
 */
interface PanelButtonProps extends IconButtonProps {
  anchor?: AnchorType;
  multiIndex?: number;
  offsetX?: number;
}

/**
 * Styled IconButton component for the open state of the Panel.
 */
const OpenPanelButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'multiIndex' && prop !== 'offsetX',
})<PanelButtonProps>(
  ({ anchor = 'left', multiIndex = 0, offsetX = 0, theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.background.background2,
    },
    alignItems: 'center',
    borderRadius: anchor === 'left' ? '0 12px 12px 0' : '12px 0px 0px 12px',
    height: 50,
    left: anchor === 'left' ? offsetX : 'unset',
    maxWidth: 50,
    position: 'absolute',
    right: anchor === 'right' ? offsetX : 'unset',
    top: theme.spacing(5 + (multiIndex && multiIndex * 8 + 2)),
    width: 50,
    zIndex: theme.zIndex.drawer - 1,
  })
);

/**
 * Styled IconButton component for the close state of the Panel.
 */
const ClosePanelButton = styled(IconButton)<PanelButtonProps>(() => ({
  background: 'none',
}));

/**
 * Panel component that includes collapsible functionality and optional open/close buttons.
 */
function Panel({
  anchor = 'left',
  children,
  currentButtonOffSet = 0,
  gridAreaName = 'panel',
  handleClose,
  handleOpen,
  iconClose = 'chevronLeft',
  iconOpen = 'chevronRight',
  isOpen = true,
  multiIndex = 0,
  variant = 'persistent',
  width = 400,
  withCollapse = false,
  ...props
}: PanelProps) {
  /**
   * Layout state information obtained from the LayoutContext.
   */
  const {
    layoutState: { headerHeight },
  } = useLayout();

  /**
   * Function to determine the horizontal offset for the open button based on the current state.
   */
  const getButtonOffsetX = (): number | null => {
    if (isOpen) return width;
    if (currentButtonOffSet) return currentButtonOffSet;
    return null;
  };

  /**
   * Function to determine the appropriate collapse icon based on the anchor and open state.
   */
  const getCollapseIcon = (): IconName => {
    if (anchor === 'right') {
      if (isOpen) return iconClose || 'chevronRight';
      return iconOpen || 'chevronLeft';
    }
    if (anchor === 'left') {
      if (isOpen) return iconClose || 'chevronLeft';
      return iconOpen || 'chevronRight';
    }
    return isOpen ? 'chevronLeft' : 'chevronRight';
  };

  /**
   * Render the Panel component.
   */
  return (
    <>
      {withCollapse && !isOpen && (
        <OpenPanelButton
          anchor={anchor}
          color='secondary'
          icon={getCollapseIcon()}
          multiIndex={multiIndex}
          offsetX={getButtonOffsetX() || 0}
          onClick={handleOpen}
          size='md'
        />
      )}
      {isOpen && (
        <PanelDrawer
          anchor={anchor}
          gridAreaName={gridAreaName}
          headerHeight={headerHeight}
          open={isOpen}
          variant={variant || 'persistent'}
          width={width}
          {...props}
        >
          {withCollapse && (
            <>
              <PanelDrawerHeader anchor={anchor}>
                <ClosePanelButton
                  color='secondary'
                  icon={getCollapseIcon()}
                  iconVariant='filled'
                  onClick={handleClose}
                  size='sm'
                />
              </PanelDrawerHeader>
              <Divider />
            </>
          )}
          <MuiBox sx={{ overflow: 'auto' }}>{children}</MuiBox>
        </PanelDrawer>
      )}
    </>
  );
}

export default Panel;

import { QueryCache, QueryClient } from '@tanstack/react-query';
import windowRedirectTo from 'utils/windowRedirectTo';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: () => {
      windowRedirectTo(null);
    },
  }),
});

export default queryClient;

import LogoInvert from './cbs-lacen-icon-app-i.svg';
import Logo from './cbs-lacen-icon-app.svg';
import BrandingInvert from './cbs-lacen-logo-app-i.svg';
import Branding from './cbs-lacen-logo-app.svg';

const LogoMapping = {
  branding: {
    invert: BrandingInvert,
    main: Branding,
  },
  logo: {
    invert: LogoInvert,
    main: Logo,
  },
};

type LogoName = keyof typeof LogoMapping;

export { LogoMapping, type LogoName };

import { Components, Theme } from '@mui/material';

import { palette } from '../palette';

export default {
  styleOverrides: {
    colorDisabled: {
      color: palette.other.disabled,
    },
    fontSizeLg: {
      fontSize: '40px',
    },
    fontSizeMd: {
      fontSize: '24px',
    },
    fontSizeSm: {
      fontSize: '20px',
    },
    fontSizeXs: {
      fontSize: '14px',
    },
    /*     root: {
      fill: 'none',
    }, */
  },
  variants: [
    // primary
    {
      props: { color: 'primary-light' },
      style: {
        color: palette.primary.light,
      },
    },
    {
      props: { color: 'primary-dark' },
      style: {
        color: palette.primary.dark,
      },
    },
    // secondary
    {
      props: { color: 'secondary-light' },
      style: {
        color: palette.secondary.light,
      },
    },
    {
      props: { color: 'secondary-dark' },
      style: {
        color: palette.secondary.dark,
      },
    },
    // alert
    {
      props: { color: 'alert-light' },
      style: {
        color: palette.alert.light,
      },
    },
    {
      props: { color: 'alert-dark' },
      style: {
        color: palette.alert.dark,
      },
    },
    // warning
    {
      props: { color: 'warning-light' },
      style: {
        color: palette.warning.light,
      },
    },
    {
      props: { color: 'warning-dark' },
      style: {
        color: palette.warning.dark,
      },
    },
    // tertiary
    {
      props: { color: 'tertiary-light' },
      style: {
        color: palette.tertiary.light,
      },
    },
    {
      props: { color: 'tertiary-dark' },
      style: {
        color: palette.tertiary.dark,
      },
    },
    // info
    {
      props: { color: 'info-light' },
      style: {
        color: palette.info.light,
      },
    },
    {
      props: { color: 'info-dark' },
      style: {
        color: palette.info.dark,
      },
    },
    // success
    {
      props: { color: 'success-light' },
      style: {
        color: palette.success.light,
      },
    },
    {
      props: { color: 'success-dark' },
      style: {
        color: palette.success.dark,
      },
    },
    // light
    {
      props: { color: 'light' },
      style: {
        color: palette.text.tertiary,
      },
    },
  ],
} as Components<Theme>['MuiSvgIcon'];

import React from 'react';

function MapStreetOutlined() {
  return (
    <path
      d='M 15,37 3,31 V 5 l 12,6 m 0,26 12,-6 M 15,37 V 11 m 12,20 12,6 V 11 L 27,5 m 0,26 V 5 M 15,11 27,5'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='3'
    />
  );
}

export default MapStreetOutlined;

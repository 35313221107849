import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  css,
} from '@mui/material';
import { styled } from 'mui-theme/styled';
import React from 'react';

function Heading1({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='h1'>
      {children}
    </MuiTypography>
  );
}
function Heading2({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='h2'>
      {children}
    </MuiTypography>
  );
}
function Heading3({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='h3'>
      {children}
    </MuiTypography>
  );
}
function Heading4({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='h4'>
      {children}
    </MuiTypography>
  );
}
function Subtitle({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='subtitle'>
      {children}
    </MuiTypography>
  );
}
function SubtitleBold({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='subtitleBold'>
      {children}
    </MuiTypography>
  );
}
function TextBody1({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='body1'>
      {children}
    </MuiTypography>
  );
}
function TextBody1Bold({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='body1Bold'>
      {children}
    </MuiTypography>
  );
}
function TextBody2({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='body2'>
      {children}
    </MuiTypography>
  );
}
function TextBody2Bold({ children, ...props }: MuiTypographyProps) {
  return (
    <MuiTypography {...props} variant='body2Bold'>
      {children}
    </MuiTypography>
  );
}

interface TypographyProps extends Omit<MuiTypographyProps, 'component'> {
  fontFamily?: 'main' | 'secondary';
  component?: 'span' | string | undefined;
}

const StyledTypography = styled(MuiTypography)<{
  $fontFamily?: TypographyProps['fontFamily'];
}>(
  ({ $fontFamily, theme }) => css`
    font-family: ${$fontFamily && theme.fontFamily[$fontFamily]};
  `
);

function Typography({ children, fontFamily, ...props }: TypographyProps) {
  return (
    <StyledTypography $fontFamily={fontFamily} {...props}>
      {children}
    </StyledTypography>
  );
}

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Subtitle,
  SubtitleBold,
  TextBody1,
  TextBody1Bold,
  TextBody2,
  TextBody2Bold,
  Typography,
  TypographyProps,
};

import BuildingFilled from './BuildingFilled';
import BuildingOutlined from './BuildingOutlined';
import BulletFilled from './BulletFilled';
import BulletOutlined from './BulletOutlined';
import BulletTriangleFilled from './BulletTriangleFilled';
import BulletTriangleOutlined from './BulletTriangleOutlined';
import ChartArrowFilled from './ChartArrowFilled';
import ChartArrowOutlined from './ChartArrowOutlined';
import ChevronLeftFilled from './ChevronLeftFilled';
import ChevronLeftOutlined from './ChevronLeftOutlined';
import ChevronRightFilled from './ChevronRightFilled';
import ChevronRightOutlined from './ChevronRightOutlined';
import CrossFilled from './CrossFilled';
import CrossOutlined from './CrossOutlined';
import DotLayerFilled from './DotLayerFilled';
import DotLayerOutlined from './DotLayerOutlined';
import EyeCloseFilled from './EyeCloseFilled';
import EyeCloseOutlined from './EyeCloseOutlined';
import EyeOpenFilled from './EyeOpenFilled';
import EyeOpenOutlined from './EyeOpenOutlined';
import FileFilled from './FileFilled';
import FileOutlined from './FileOutlined';
import FilesFilled from './FilesFilled';
import FilesOutlined from './FilesOutlined';
import GridFilled from './GridFilled';
import GridOutlined from './GridOutlined';
import HomeFilled from './HomeFilled';
import HomeOutlined from './HomeOutlined';
import InformationFilled from './InformationFilled';
import InformationOutlined from './InformationOutlined';
import KeyFilled from './KeyFilled';
import KeyOutlined from './KeyOutlined';
import LayerFilled from './LayerFilled';
import LayerOutlined from './LayerOutlined';
import LayerSwitchFilled from './LayerSwitchFilled';
import LayerSwitchOutlined from './LayerSwitchOutlined';
import LeafFilled from './LeafFilled';
import LeafOutlined from './LeafOutlined';
import MailFilled from './MailFilled';
import MailOutlined from './MailOutlined';
import MapSatelliteFilled from './MapSatelliteFilled';
import MapSatelliteOutlined from './MapSatelliteOutlined';
import MapStreetFilled from './MapStreetFilled';
import MapStreetOutlined from './MapStreetOutlined';
import MarkerBuildingFilled from './MarkerBuildingFilled';
import MarkerBuildingOutlined from './MarkerBuildingOutlined';
import MarkerCheckFilled from './MarkerCheckFilled';
import MarkerCheckOutlined from './MarkerCheckOutlined';
import MarkerCircleFilled from './MarkerCircleFilled';
import MarkerCircleOutlined from './MarkerCircleOutlined';
import MarkerDotStackHighFilled from './MarkerDotStackHighFilled';
import MarkerDotStackHighOutlined from './MarkerDotStackHighOutlined';
import MarkerDotStackLowFilled from './MarkerDotStackLowFilled';
import MarkerDotStackLowOutlined from './MarkerDotStackLowOutlined';
import MarkerDotStackMediumFilled from './MarkerDotStackMediumFilled';
import MarkerDotStackMediumOutlined from './MarkerDotStackMediumOutlined';
import MarkerFullFilled from './MarkerFullFilled';
import MarkerFullOutlined from './MarkerFullOutlined';
import MarkerSquareFilled from './MarkerSquareFilled';
import MarkerSquareOutlined from './MarkerSquareOutlined';
import MarkerTriangleFilled from './MarkerTriangleFilled';
import MarkerTriangleOutlined from './MarkerTriangleOutlined';
import PencilFilled from './PencilFilled';
import PencilOutlined from './PencilOutlined';
import Perimeter1Filled from './Perimeter1Filled';
import Perimeter1Outlined from './Perimeter1Outlined';
import Perimeter2Filled from './Perimeter2Filled';
import Perimeter2Outlined from './Perimeter2Outlined';
import PetTrackFilled from './PetTrackFilled';
import PetTrackOutlined from './PetTrackOutlined';
import PlusFilled from './PlusFilled';
import PlusOutlined from './PlusOutlined';
import RectangleFilled from './RectangleFilled';
import RectangleOutlined from './RectangleOutlined';
import SettingFilled from './SettingFilled';
import SettingOutlined from './SettingOutlined';
import StackFilled from './StackFilled';
import StackOutlined from './StackOutlined';
import StateStep0Filled from './StateStep0Filled';
import StateStep0Outlined from './StateStep0Outlined';
import StateStep1Filled from './StateStep1Filled';
import StateStep1Outlined from './StateStep1Outlined';
import StateStep2Filled from './StateStep2Filled';
import StateStep2Outlined from './StateStep2Outlined';
import StateStep3Filled from './StateStep3Filled';
import StateStep3Outlined from './StateStep3Outlined';
import StateStep4Filled from './StateStep4Filled';
import StateStep4Outlined from './StateStep4Outlined';
import TrashFilled from './TrashFilled';
import TrashOutlined from './TrashOutlined';
import TrophyFilled from './TrophyFilled';
import TrophyOutlined from './TrophyOutlined';
import TurnCircleFilled from './TurnCircleFilled';
import TurnCircleOutlined from './TurnCircleOutlined';
import UserFilled from './UserFilled';
import UserOutlined from './UserOutlined';
import WorldSearchFilled from './WorldSearchFilled';
import WorldSearchOutlined from './WorldSearchOutlined';
import ZoomEnterpriseFilled from './ZoomEnterpriseFilled';
import ZoomEnterpriseOutlined from './ZoomEnterpriseOutlined';
import ZoomInFilled from './ZoomInFilled';
import ZoomInOutlined from './ZoomInOutlined';
import ZoomOutFilled from './ZoomOutFilled';
import ZoomOutOutlined from './ZoomOutOutlined';
import ZoomSiteFilled from './ZoomSiteFilled';

const IconMapping = {
  building: {
    filled: BuildingFilled,
    outlined: BuildingOutlined,
  },
  bullet: {
    filled: BulletFilled,
    outlined: BulletOutlined,
  },
  bulletTriangle: {
    filled: BulletTriangleFilled,
    outlined: BulletTriangleOutlined,
  },
  chartArrow: {
    filled: ChartArrowFilled,
    outlined: ChartArrowOutlined,
  },
  chevronLeft: {
    filled: ChevronLeftFilled,
    outlined: ChevronLeftOutlined,
  },
  chevronRight: {
    filled: ChevronRightFilled,
    outlined: ChevronRightOutlined,
  },
  cross: {
    filled: CrossFilled,
    outlined: CrossOutlined,
  },
  dotLayer: {
    filled: DotLayerFilled,
    outlined: DotLayerOutlined,
  },
  eyeClose: {
    filled: EyeCloseFilled,
    outlined: EyeCloseOutlined,
  },
  eyeOpen: {
    filled: EyeOpenFilled,
    outlined: EyeOpenOutlined,
  },
  file: {
    filled: FileFilled,
    outlined: FileOutlined,
  },
  files: {
    filled: FilesFilled,
    outlined: FilesOutlined,
  },
  grid: {
    filled: GridFilled,
    outlined: GridOutlined,
  },
  home: {
    filled: HomeFilled,
    outlined: HomeOutlined,
  },
  information: {
    filled: InformationFilled,
    outlined: InformationOutlined,
  },
  key: {
    filled: KeyFilled,
    outlined: KeyOutlined,
  },
  layer: {
    filled: LayerFilled,
    outlined: LayerOutlined,
  },
  layerSwitch: {
    filled: LayerSwitchFilled,
    outlined: LayerSwitchOutlined,
  },
  leaf: {
    filled: LeafFilled,
    outlined: LeafOutlined,
  },
  mail: {
    filled: MailFilled,
    outlined: MailOutlined,
  },
  mapSatellite: {
    filled: MapSatelliteFilled,
    outlined: MapSatelliteOutlined,
  },
  mapStreet: {
    filled: MapStreetFilled,
    outlined: MapStreetOutlined,
  },
  markerBuilding: {
    filled: MarkerBuildingFilled,
    outlined: MarkerBuildingOutlined,
  },
  markerCheck: {
    filled: MarkerCheckFilled,
    outlined: MarkerCheckOutlined,
  },
  markerCircle: {
    filled: MarkerCircleFilled,
    outlined: MarkerCircleOutlined,
  },
  markerDotStackHigh: {
    filled: MarkerDotStackHighFilled,
    outlined: MarkerDotStackHighOutlined,
  },
  markerDotStackLow: {
    filled: MarkerDotStackLowFilled,
    outlined: MarkerDotStackLowOutlined,
  },
  markerDotStackMedium: {
    filled: MarkerDotStackMediumFilled,
    outlined: MarkerDotStackMediumOutlined,
  },
  markerFull: {
    filled: MarkerFullFilled,
    outlined: MarkerFullOutlined,
  },
  markerSquare: {
    filled: MarkerSquareFilled,
    outlined: MarkerSquareOutlined,
  },
  markerTriangle: {
    filled: MarkerTriangleFilled,
    outlined: MarkerTriangleOutlined,
  },
  pencil: {
    filled: PencilFilled,
    outlined: PencilOutlined,
  },
  perimeter1: {
    filled: Perimeter1Filled,
    outlined: Perimeter1Outlined,
  },
  perimeter2: {
    filled: Perimeter2Filled,
    outlined: Perimeter2Outlined,
  },
  petTrack: {
    filled: PetTrackFilled,
    outlined: PetTrackOutlined,
  },
  plus: {
    filled: PlusFilled,
    outlined: PlusOutlined,
  },
  rectangle: {
    filled: RectangleFilled,
    outlined: RectangleOutlined,
  },
  setting: {
    filled: SettingFilled,
    outlined: SettingOutlined,
  },
  stack: {
    filled: StackFilled,
    outlined: StackOutlined,
  },
  stateStep0: {
    filled: StateStep0Filled,
    outlined: StateStep0Outlined,
  },
  stateStep1: {
    filled: StateStep1Filled,
    outlined: StateStep1Outlined,
  },
  stateStep2: {
    filled: StateStep2Filled,
    outlined: StateStep2Outlined,
  },
  stateStep3: {
    filled: StateStep3Filled,
    outlined: StateStep3Outlined,
  },
  stateStep4: {
    filled: StateStep4Filled,
    outlined: StateStep4Outlined,
  },
  trash: {
    filled: TrashFilled,
    outlined: TrashOutlined,
  },
  trophy: {
    filled: TrophyFilled,
    outlined: TrophyOutlined,
  },
  turnCircle: {
    filled: TurnCircleFilled,
    outlined: TurnCircleOutlined,
  },
  user: {
    filled: UserFilled,
    outlined: UserOutlined,
  },
  worldSearch: {
    filled: WorldSearchFilled,
    outlined: WorldSearchOutlined,
  },
  zoomEnterprise: {
    filled: ZoomEnterpriseFilled,
    outlined: ZoomEnterpriseOutlined,
  },
  zoomIn: {
    filled: ZoomInFilled,
    outlined: ZoomInOutlined,
  },
  zoomOut: {
    filled: ZoomOutFilled,
    outlined: ZoomOutOutlined,
  },
  zoomSite: {
    filled: ZoomSiteFilled,
    outlined: ZoomSiteFilled,
  },
};

type IconName = keyof typeof IconMapping;

export { IconMapping, type IconName };

import React from 'react';

function HomeFilled() {
  return (
    <path
      d='M 16.647593,37.566788 V 26.262455 h 8.695646 v 11.304333 c 0,1.243561 0.97838,2.260957 2.174076,2.260957 h 6.521774 c 1.19547,0 2.173851,-1.017396 2.173851,-2.260957 V 21.740676 h 3.695747 c 0.99993,0 1.478117,-1.288703 0.717281,-1.966968 L 22.451881,2.7492704 c -0.826007,-0.7686999 -2.086877,-0.7686999 -2.912976,0 L 1.3648975,19.773708 c -0.73913366,0.678265 -0.2826094,1.966968 0.7173839,1.966968 h 3.6956798 v 15.826112 c 0,1.243561 0.9782662,2.260957 2.1739173,2.260957 h 6.5217745 c 1.195674,0 2.17394,-1.017396 2.17394,-2.260957 z'
      fill='currentColor'
    />
  );
}

export default HomeFilled;

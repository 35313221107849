import { CompanySourceData } from 'business/company/map-company-marker/map-company-source';
import { MapSiteFeatureLayerProps } from 'business/site/map-site-data/feature/map-site-feature-layer.interface';
import {
  MapSiteMarkerLayerProps,
  MapSiteMarkerStyleEnum,
} from 'business/site/map-site-data/marker/map-site-marker-layer.interface';
import { SiteSourceData } from 'business/site/map-site-data/marker/map-site-source';
import { LngLat } from 'mapbox-gl';
import Site from 'models/Site/Site.model';
import React, { createContext, useReducer } from 'react';
import useSafeContext from 'utils/useSafeContext';

import { MapActorLayerProps } from '../company/map-company-marker/map-company-layer.interface';
import { MapServiceLayerProps } from '../map-service-layer/map-service-layer.interface';
import { MapLayerProps } from './layer/MapLayer.interface';
import { MAP_INITIAL } from './MapContext.constant';
import { MapReducerActionTypeEnum } from './MapContext.enum';

// Reducer function to manage map state
type Action = {
  type: MapReducerActionTypeEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};

interface MapState {
  map: React.MutableRefObject<mapboxgl.Map | null> | undefined;

  selectedSite?: Site;
  siteSourceData?: SiteSourceData | null;
  companyData?: CompanySourceData | null;
  siteIndicatorMarkerStyle: MapSiteMarkerStyleEnum;
  siteIndicatorLayerIds: Array<MapSiteMarkerLayerProps>;
  siteFeatureLayerIds: Array<MapSiteFeatureLayerProps>;
  mapServiceLayers: Array<MapServiceLayerProps>;
  actorLayerIds: Array<MapActorLayerProps>;
  currentCoordinates: LngLat;
  zoom: number;
  orderedCustomLayerIds: Array<string>;
  mapStyle: 'satellite' | 'street';
}

interface MapContextData {
  mapDispatch: React.Dispatch<Action>;
  mapState: MapState;
}

/**
 * Function to get the initial state of the map.
 * @returns {MapState} The initial map state.
 */
const getInitialState = (): MapState => ({
  actorLayerIds: [],
  companyData: null,
  currentCoordinates: new LngLat(
    MAP_INITIAL.initialCoordinates.longitude,
    MAP_INITIAL.initialCoordinates.latitude
  ),
  map: undefined,
  mapServiceLayers: [],
  mapStyle: 'street',
  orderedCustomLayerIds: [],

  siteFeatureLayerIds: [],
  siteIndicatorLayerIds: [],
  siteIndicatorMarkerStyle: MapSiteMarkerStyleEnum.ECOLOGICAL_SENSITIVITY,
  siteSourceData: null,
  zoom: MAP_INITIAL.zoom,
});

/**
 * Context for managing map-related information.
 */
const MapContext: React.Context<MapContextData> = createContext<MapContextData>(
  {
    mapDispatch: () => {},
    mapState: getInitialState(),
  }
);

/**
 * Custom hook to access the Map context.
 * @returns {MapContextData} The Map context data.
 */
const useMap = () => useSafeContext(MapContext, 'Map');

/**
 * Reducer function to manage map state.
 * @param {MapState} state - The current map state.
 * @param {Action} action - The action to perform on the state.
 * @returns {MapState} The new map state.
 * @throws {Error} If an unsupported action type is provided.
 */
const reducer = (state: MapState, action: Action): MapState => {
  switch (action.type) {
    case MapReducerActionTypeEnum.SET_MAP: {
      return {
        ...state,
        map: action.value || undefined,
      };
    }

    case MapReducerActionTypeEnum.SET_MAP_STYLE: {
      return {
        ...state,
        mapStyle: action.value || 'street',
      };
    }

    case MapReducerActionTypeEnum.SET_COMPANY_DATA: {
      return {
        ...state,
        companyData: action.value || undefined,
      };
    }

    case MapReducerActionTypeEnum.SET_SITE_DATA: {
      return {
        ...state,
        siteSourceData: action.value || undefined,
      };
    }

    case MapReducerActionTypeEnum.SET_LAYER_VISIBILITY: {
      const { layerId, visibility } = action.value;

      return {
        ...state,
        actorLayerIds: state.actorLayerIds.map((layer) =>
          layer.id === layerId ? { ...layer, visibility } : layer
        ),
        mapServiceLayers: state.mapServiceLayers.map((layer) =>
          layer.id === layerId ? { ...layer, visibility } : layer
        ),
        siteFeatureLayerIds: state.siteFeatureLayerIds.map((layer) =>
          layer.id === layerId ? { ...layer, visibility } : layer
        ),
        siteIndicatorLayerIds: state.siteIndicatorLayerIds.map((layer) =>
          layer.id === layerId ? { ...layer, visibility } : layer
        ),
      };
    }

    case MapReducerActionTypeEnum.SET_SITE_INDICATOR_LAYER_IDS: {
      const newLayerIds = action.value.filter(
        (newLayer: MapLayerProps) =>
          !state.siteIndicatorLayerIds.some(
            (layer) =>
              layer.id === newLayer.id && layer.group === newLayer.group
          )
      );

      if (newLayerIds?.length > 0)
        return {
          ...state,
          siteIndicatorLayerIds: [
            ...state.siteIndicatorLayerIds,
            ...newLayerIds,
          ],
        };
      return state;
    }

    case MapReducerActionTypeEnum.SET_SITE_FEATURE_LAYER_IDS: {
      const newLayerIds = action.value.filter(
        (newLayer: MapLayerProps) =>
          !state.siteFeatureLayerIds.some(
            (layer) =>
              layer.id === newLayer.id && layer.group === newLayer.group
          )
      );
      if (newLayerIds?.length > 0)
        return {
          ...state,
          siteFeatureLayerIds: [...state.siteFeatureLayerIds, ...newLayerIds],
        };
      return state;
    }

    case MapReducerActionTypeEnum.SET_ORDERED_CUSTOM_LAYER_IDS: {
      return {
        ...state,
        orderedCustomLayerIds: action.value || [],
      };
    }

    case MapReducerActionTypeEnum.SET_ACTOR_LAYER_IDS: {
      const newLayerIds = action.value.filter(
        (newLayer: MapLayerProps) =>
          !state.actorLayerIds.some((layer) => layer.id === newLayer.id)
      );

      if (newLayerIds?.length > 0) {
        return {
          ...state,
          actorLayerIds: [...state.actorLayerIds, ...newLayerIds],
        };
      }
      return state;
    }

    case MapReducerActionTypeEnum.CLEAN_MAP: {
      return {
        ...state,
        actorLayerIds: [],
        mapServiceLayers: [],
        siteFeatureLayerIds: [],
        siteIndicatorLayerIds: [],
      };
    }

    case MapReducerActionTypeEnum.SET_MAP_SERVICE_LAYERS: {
      const newMapServiceLayers = action.value.filter(
        (newLayer: MapLayerProps) =>
          !state.mapServiceLayers.some(
            (layer) =>
              layer.id === newLayer.id && layer.group === newLayer.group
          )
      );
      if (newMapServiceLayers?.length > 0)
        return {
          ...state,
          mapServiceLayers: [...state.mapServiceLayers, ...newMapServiceLayers],
        };
      return state;
    }

    case MapReducerActionTypeEnum.SET_SITE_INDICATOR_MARKER_STYLE: {
      if (!state.map) return state;
      const updatedLayers = state.siteIndicatorLayerIds.map((layer) => {
        const updatedLayer = { ...layer };
        if (layer.group === action.value) {
          updatedLayer.visibility = 'visible';
        } else {
          updatedLayer.visibility = 'none';
        }
        return updatedLayer;
      });
      return {
        ...state,
        siteIndicatorLayerIds: updatedLayers,
        siteIndicatorMarkerStyle: action.value || undefined,
      };
    }

    case MapReducerActionTypeEnum.SET_CENTER_COORDINATES: {
      return {
        ...state,
        currentCoordinates: action.value || null,
      };
    }

    case MapReducerActionTypeEnum.SET_ZOOM: {
      return {
        ...state,
        zoom: action.value || null,
      };
    }
    default:
      throw new Error(`Map action type not handled: ${action.type}`);
      return state;
  }
};

/**
 * Component that provides the Map context to its children.
 * @param {React.ReactNode} children - The children components.
 * @returns {React.ReactNode} The MapProvider component.
 */
function MapProvider({ children }: { children: React.ReactNode }) {
  const [mapState, mapDispatch] = useReducer<React.Reducer<MapState, Action>>(
    reducer,
    getInitialState()
  );

  return (
    <MapContext.Provider
      value={{
        mapDispatch,
        mapState,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

export { MapProvider, useMap };

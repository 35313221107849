const wording = {
  chunkLoadErrorMessage: 'Une mise à jour est nécessaire',
  errorMessage: "Une erreur inattendue empêche l'affichage",
  refreshButton: 'Actualiser la page',
  refreshMessage:
    'Essayez de rafraîchir la page ou de vider le cache de votre navigateur',
  takenCareOfMessage:
    'Le support a été prévenu et traitera le problème dans les meilleurs délais',
};

export default wording;

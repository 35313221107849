import {
  AppBar,
  Link,
  Container as MuiContainer,
  Toolbar as MuiToolBar,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useUser } from 'business/loginAndRights/AuthContext';
import { Logo } from 'design-system/logo/Logo';
import { muiTheme } from 'mui-theme/theme';
import React from 'react';

import NavAuth from '../Login';
import NavMenu from './NavMenu';

const AppToolBar = styled(MuiToolBar)`
  display: grid;
  grid-template-columns: 300px 1fr minmax(auto, 400px);
  grid-template-areas: 'branding navigation-menu navigation-login';
  ${({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      gridTemplateAreas: `'navigation-menu branding navigation-login'`,
      gridTemplateColumns: 'auto 1fr auto',
    },
  })}
`;

const NavBarContainer = styled(MuiContainer)`
  max-width: 'none';
  ${({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.between('md', 'lg')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920 - 2 * parseFloat(theme.spacing(10)),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
  })}
`;

function NavBar({ gridAreaName }: { gridAreaName: string }) {
  const { isAuthenticated, isLoading } = useUser();
  const withNameDisplay = useMediaQuery(muiTheme.breakpoints.up('md'));

  return (
    <AppBar
      position='static'
      style={{
        backgroundColor: muiTheme.palette.secondary.main,
        gridArea: gridAreaName,
      }}
    >
      <NavBarContainer maxWidth={undefined}>
        <AppToolBar disableGutters>
          <Link href='https://cbs.lacen.org' rel='noopener' target='_blank'>
            <Logo
              darkness='invert'
              logoHeight={55}
              name='branding'
              sx={{ justifyContent: 'center' }}
            />
          </Link>
          {!isLoading && isAuthenticated && (
            <NavMenu gridAreaName='navigation-menu' />
          )}
          {!isLoading && isAuthenticated && (
            <NavAuth
              gridAreaName='navigation-login'
              withNameDisplay={withNameDisplay}
            />
          )}
        </AppToolBar>
      </NavBarContainer>
    </AppBar>
  );
}

export default NavBar;

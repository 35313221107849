enum PanelOpeningStatusEnum {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  SEMI_OPEN = 'SEMI_OPEN',
}

enum LayoutReducerActionTypeEnum {
  SET_PAGE_TITLE = 'setPageTitle',
  SET_IS_SIDE_PANEL_OPEN = 'setIsSidePanelPanelOpen',
  SET_HAS_SIDE_PANEL = 'setHasSidePanel',
  SET_SIDE_PANEL_COMPONENT = 'setSidePanelComponent',
}

export { PanelOpeningStatusEnum, LayoutReducerActionTypeEnum };

import React from 'react';

function ZoomEnterpriseFilled() {
  return (
    <>
      <path
        d='m 21.619403,28.842076 c -1.54073,0 -2.90289,-0.328844 -4.086592,-0.986419 -1.183703,-0.676402 -2.113755,-1.606454 -2.790157,-2.790157 -0.676402,-1.183702 -1.014602,-2.536506 -1.014602,-4.058409 0,-1.54073 0.319374,-2.893534 0.958235,-4.05841 0.638749,-1.183703 1.512434,-2.113755 2.621056,-2.790156 1.127336,-0.676402 2.423773,-1.014603 3.889309,-1.014603 1.427884,0 2.667954,0.328844 3.720209,0.986419 1.070969,0.638862 1.888288,1.531261 2.451956,2.677423 0.582382,1.146163 0.873685,2.451956 0.873685,3.917493 0,0.319374 -0.103376,0.582494 -0.310017,0.789135 -0.206753,0.187927 -0.469761,0.281834 -0.789135,0.281834 H 15.362689 v -1.944655 h 11.921578 l -1.211886,0.845502 c 0.01871,-1.033429 -0.169101,-1.963481 -0.563668,-2.790156 -0.394568,-0.826676 -0.958236,-1.474894 -1.691004,-1.944655 -0.732769,-0.469761 -1.606454,-0.704585 -2.621057,-0.704585 -1.070969,0 -2.010491,0.244294 -2.818339,0.732769 -0.789136,0.488474 -1.399814,1.174346 -1.831921,2.057388 -0.413395,0.864328 -0.620035,1.850747 -0.620035,2.959257 0,1.108509 0.244181,2.094928 0.732768,2.959257 0.488475,0.864328 1.15552,1.54073 2.001022,2.029204 0.864215,0.488475 1.850634,0.732769 2.959256,0.732769 0.638749,0 1.286967,-0.112734 1.944655,-0.338201 0.676402,-0.225467 1.211886,-0.507301 1.606454,-0.845502 0.225467,-0.1691 0.479118,-0.253651 0.760952,-0.253651 0.300547,-0.01883 0.544841,0.05637 0.732768,0.225468 0.281834,0.244293 0.422751,0.507301 0.422751,0.789135 0.01871,0.281834 -0.103377,0.526128 -0.366384,0.732768 -0.620035,0.526128 -1.40917,0.958236 -2.367406,1.296437 -0.958235,0.3382 -1.869574,0.507301 -2.73379,0.507301 z'
        fill='currentColor'
      />
      <path
        d='m 21.255859,6.3769531 c -8.261434,2e-7 -14.9960932,6.7346589 -14.9960934,14.9960939 0,8.261435 6.7346584,14.996093 14.9960934,14.996094 8.261435,0 14.996094,-6.734659 14.996094,-14.996094 0,-8.261435 -6.734659,-14.9960939 -14.996094,-14.9960939 z m 0,3.5 c 6.369897,0 11.496094,5.1261969 11.496094,11.4960939 0,6.369896 -5.126197,11.496094 -11.496094,11.496094 C 14.885963,32.86914 9.7597656,27.742943 9.7597656,21.373047 9.7597658,15.003151 14.885963,9.8769533 21.255859,9.8769531 Z'
        fill='currentColor'
      />
      <path
        d='M 7.57617 3.13672 L 3.45703 3.80078 L 2.66016 4.59766 L 1.99609 8.7168 L 3.58984 7.12305 L 7.04492 10.5781 L 9.43555 8.18555 L 5.98242 4.73242 L 7.57617 3.13672 Z'
        fill='currentColor'
      />
      <path
        d='M 35.9512 31.4219 L 33.5605 33.8145 L 37.0137 37.2676 L 35.4199 38.8633 L 39.5391 38.1992 L 40.3359 37.4023 L 41 33.2832 L 39.4063 34.877 L 35.9512 31.4219 Z'
        fill='currentColor'
      />
      <path
        d='M 35.4199 3.37305 L 37.0137 4.9668 L 33.5605 8.41992 L 35.9512 10.8125 L 39.4063 7.35938 L 41 8.95312 L 40.3359 4.83398 L 39.5391 4.03711 L 35.4199 3.37305 Z'
        fill='currentColor'
      />
      <path
        d='M 6.04883 31.4219 L 2.59375 34.877 L 1 33.2832 L 1.66406 37.4023 L 2.46094 38.1992 L 6.58008 38.8633 L 4.98633 37.2676 L 8.43945 33.8145 L 6.04883 31.4219 Z'
        fill='currentColor'
      />
    </>
  );
}

export default ZoomEnterpriseFilled;

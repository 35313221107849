import React from 'react';

function LeafOutlined() {
  return (
    <path
      d='m 6.1254329,11.147432 c -6.82835196,6.828353 -6.82835196,17.853673 -0.03329,24.682025 3.697299,-8.493802 10.2259391,-15.588687 18.4199191,-19.818943 -6.92833,5.862416 -11.791411,14.023106 -13.49015,23.283 6.495241,3.064474 14.489376,1.965271 19.852125,-3.430767 C 39.567794,27.202389 41,1.0214702 41,1.0214702 c 0,0 -26.180919,1.432314 -34.8745671,10.1259618 z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default LeafOutlined;

import 'reflect-metadata';
import 'fonts';
import 'dayjs/locale/fr';
import 'mapbox-gl/dist/mapbox-gl.css';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'business/loginAndRights/AuthContext.tsx';
import { MapProvider } from 'business/map/MapContext.tsx';
import { ToastProvider } from 'business/ToastContext.tsx';
import { WebAppProvider } from 'business/WebAppContext.tsx';
import CustomSwitch from 'components/CustomSwitch';
import UnsupportedNavigatorDetector from 'components/UnsupportedNavigatorDetector';
import Layout from 'design-components/index.tsx';
import { LayoutProvider } from 'design-components/LayoutContext.tsx';
import { LoadingProvider } from 'design-components/web-app/Layout/LoadingContext.tsx';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import routes from 'routes';
import { BrowserHistoryProvider } from 'utils/browserHistoryContext';

import ErrorBoundary from './components/ErrorBoundary';
import { muiTheme } from './mui-theme/theme.ts';
import queryClient from './utils/external-services/react-query';

function App() {
  return (
    <ErrorBoundary name='App' size='l'>
      <CookiesProvider>
        <Router>
          <BrowserHistoryProvider>
            <QueryClientProvider client={queryClient}>
              <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                <UnsupportedNavigatorDetector>
                  <LoadingProvider>
                    <AuthProvider>
                      <WebAppProvider>
                        <MapProvider>
                          <ToastProvider>
                            <LayoutProvider>
                              <Layout>
                                <CustomSwitch routes={routes} />
                              </Layout>
                            </LayoutProvider>
                          </ToastProvider>
                        </MapProvider>
                      </WebAppProvider>
                    </AuthProvider>
                  </LoadingProvider>
                </UnsupportedNavigatorDetector>
              </MuiThemeProvider>
            </QueryClientProvider>
          </BrowserHistoryProvider>
        </Router>
      </CookiesProvider>
    </ErrorBoundary>
  );
}

export default App;

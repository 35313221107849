import React, { useState } from 'react';

import UnsupportedNavigatorGuide from './UnsupportedNavigatorGuide';

const UNSUPPORTED_USER_AGENT_SELECTORS = ['MSIE ', 'Trident/'];

const computeUnsupportedNavigator = (): boolean =>
  UNSUPPORTED_USER_AGENT_SELECTORS.some(
    (selector) => navigator.userAgent.indexOf(selector) > -1
  );

interface UnsupportedNavigatorDetectorProps {
  children: React.ReactNode;
}

function UnsupportedNavigatorDetector({
  children,
}: UnsupportedNavigatorDetectorProps) {
  const [isUnsupportedNavigator] = useState<boolean>(
    computeUnsupportedNavigator()
  );

  if (isUnsupportedNavigator) return <UnsupportedNavigatorGuide />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default UnsupportedNavigatorDetector;

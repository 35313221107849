import { Stack as MuiStack } from '@mui/material';
import { useUser } from 'business/loginAndRights/AuthContext';
import { Menu } from 'design-system/menu/Menu';
import { MenuItemProps } from 'design-system/menu/menu-item/MenuItem';
import { SubtitleBold } from 'design-system/typography/Typography';
import { styled } from 'mui-theme/styled';
import { muiTheme } from 'mui-theme/theme';
import React from 'react';
import routesPaths from 'routesPaths';

import wording from './wording';

/**
 * Styled component for the user name text in the navigation.
 * @component
 */
const StyleUserNameText = styled(SubtitleBold)`
  color: ${({ theme }) => theme.palette.primary.main};
  span {
    color: ${({ theme }) => theme.palette.text.tertiary};
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Props for the NavAuth component.
 * @interface
 */
interface NavAuthProps {
  gridAreaName: string;
  withNameDisplay?: boolean;
}

/**
 * Navigation component for authenticated users.
 *
 * @param {NavAuthProps} props - The component props.
 * @returns {React.ReactElement} The rendered NavAuth component.
 */
function NavAuth({ gridAreaName, withNameDisplay = true }: NavAuthProps) {
  const { disconnectUser, isAuthenticated, userData } = useUser();

  if (!userData) return null;

  /**
   * Renders the authenticated user's display name.
   *
   * @returns {React.ReactElement | null} The rendered user name or null if not requested.
   */
  const renderAuthName = () => {
    if (withNameDisplay)
      return (
        <StyleUserNameText>
          {wording.displayNamePrefix}
          {withNameDisplay && (
            <span>{` ${
              userData.firstname &&
              `${userData.firstname.charAt(0).toUpperCase()}. `
            } ${userData.lastname}`}</span>
          )}
        </StyleUserNameText>
      );
    return null;
  };

  /**
   * Handles the click event for the logout option in the account menu.
   */
  const handleLogoutClick = () => {
    disconnectUser();
  };

  /**
   * Account menu items for the authenticated user.
   */
  const AccountMenuItems: MenuItemProps[] = [
    {
      id: 'logout',
      isDisplayed: () => isAuthenticated,
      label: wording.logoutLabel,
      onClick: () => handleLogoutClick(),
      path: routesPaths.home,
    },
  ];

  return (
    <MuiStack
      alignItems='center'
      direction='row'
      justifyContent='end'
      spacing={muiTheme.spacing(2)}
      sx={{
        flexGrow: 0,
        gridArea: gridAreaName,
        textAlign: 'end',
      }}
    >
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        color='secondary'
        id='account-menu'
        isIconMenu
        label={wording.accountMenuLabel}
        menuItems={AccountMenuItems}
      />
      {renderAuthName()}
    </MuiStack>
  );
}

export default NavAuth;

import React from 'react';

function LayerSwitchFilled() {
  return (
    <>
      <path
        d='M 14.333333,38.64051 1.0000001,28.270157 l 2.4444695,-1.851843 10.8888634,8.44447 10.888863,-8.44447 2.444471,1.851843 z m 0,-7.481491 L 1.0000001,20.788666 14.333333,10.418314 27.666667,20.788666 Z m 0,-3.777725 8.51851,-6.592628 -8.51851,-6.592548 -8.5185091,6.592548 z'
        fill='currentcolor'
      />
      <path
        d='m 32.411765,15.90851 c -1.751608,0 -3.241804,-0.607844 -4.470589,-1.823531 -1.228784,-1.215685 -1.843137,-2.699371 -1.843137,-4.4509795 V 9.4967452 L 24.843137,10.751647 23.745098,9.6536081 26.882353,6.5163528 30.019608,9.6536081 28.921569,10.751647 27.666667,9.4967452 v 0.1372543 c 0,1.3072165 0.460784,2.4182755 1.382353,3.3333335 0.921569,0.915059 2.042511,1.372549 3.362745,1.372549 0.339843,0 0.673176,-0.03922 1,-0.117647 0.326824,-0.07843 0.647059,-0.196078 0.960784,-0.352941 l 1.176471,1.17647 c -0.496707,0.287608 -1.00651,0.503295 -1.529412,0.647059 -0.522902,0.143765 -1.058823,0.215687 -1.607843,0.215687 z m 5.45098,-3.156863 -3.137255,-3.1372551 1.09804,-1.0980391 1.254901,1.2549019 V 9.6340005 c 0,-1.3072162 -0.460784,-2.4182753 -1.382353,-3.3333343 -0.921568,-0.9150581 -2.042509,-1.3725486 -3.362745,-1.3725486 -0.339843,0 -0.673176,0.039219 -1,0.1176467 -0.326823,0.078429 -0.647059,0.196079 -0.960784,0.3529409 L 29.196078,4.2222348 C 29.692785,3.9346271 30.202589,3.7189414 30.72549,3.5751767 31.248392,3.4314119 31.784313,3.35949 32.333333,3.35949 c 1.751608,0 3.241804,0.6078429 4.470589,1.8235305 1.228784,1.2156847 1.843137,2.6993714 1.843137,4.45098 V 9.7712547 L 39.901961,8.5163528 41,9.6143919 Z'
        fill='currentcolor'
      />
    </>
  );
}

export default LayerSwitchFilled;

import { useTheme } from '@mui/material';
import React from 'react';

function StateStep2Filled() {
  const {
    palette: { tertiary },
  } = useTheme();

  return (
    <>
      <path
        d='M 32.249278,5.4506944 C 29.010444,2.4835667 25.258278,1 20.996444,1 16.734611,1 12.9825,2.4835667 9.7436277,5.4506944 6.5047721,8.4178333 4.887111,12.400944 4.887111,17.400056 c 0,3.332777 1.3344833,6.957277 4.0034444,10.873611 C 11.5595,32.19 15.594833,36.433278 21,41 c 5.405167,-4.566722 9.440444,-8.81 12.109444,-12.726333 2.668945,-3.916334 4.003445,-7.540834 4.003445,-10.873611 0,-4.999112 -1.617667,-8.9822227 -4.860056,-11.9493616 z'
        fill='currentColor'
      />
      <path
        d='m 27.335937,9.0859373 2.19336,3.4082037 -6.238281,3.982421 -7.388672,0.982423 -5.732422,5.4375 V 10.273437 H 9.1347656 V 25.609375 H 10.169922 25.556641 V 24.585937 H 11.380859 l 5.462891,-5.18164 7.181641,-0.960937 6.621094,-4.21875 2.359375,3.666015 1.615234,-7.21875 z'
        fill={tertiary.light}
      />
    </>
  );
}

export default StateStep2Filled;

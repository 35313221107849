import { Components, Theme } from '@mui/material';

import { palette } from '../palette';

export default {
  defaultProps: {
    variant: 'normal',
  },
  styleOverrides: {
    colorPrimary: {
      '&.Mui-focusVisible': {
        backgroundColor: palette.primary.main,
      },
      '&:hover': {
        backgroundColor: palette.secondary.main,
      },
    },
    colorSecondary: {
      '&.Mui-focusVisible': {
        backgroundColor: palette.secondary.main,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.primary.main,
      },
    },
    root: {
      '&.Mui-focusVisible': {
        color: palette.primary.main,
      },
      '&:hover': {
        backgroundColor: palette.primary.main,
      },
      backgroundColor: palette.background.background1,
      borderRadius: '5px',
    },
    sizeLg: {
      height: '42px',
      width: '42px',
    },
    sizeMd: {
      height: '36px',
      width: '36px',
    },
    sizeSm: {
      height: '32px',
      width: '32px',
    },
    sizeXs: {
      height: '10px',
      width: '10px',
    },
  },
  variants: [
    // colors
    {
      props: { color: 'alert' },
      style: {
        '&.Mui-focusVisible': {
          backgroundColor: palette.alert.main,
        },
        '&:hover': {
          backgroundColor: palette.alert.hover,
        },
      },
    },
    {
      props: { color: 'tertiary' },
      style: {
        '&.Mui-focusVisible': {
          backgroundColor: palette.background.background2,
          border: `1px solid ${palette.background.background2}`,
          color: palette.primary.main,
        },
        '&:hover': {
          backgroundColor: palette.tertiary.hover,
        },
        backgroundColor: 'transparent', // prevent dark mode issue
      },
    },
    // variants
    {
      props: { variant: 'contained' },
      style: {
        '&.Mui-disabled': {
          color: palette.other.disabled,
        },
        border: '1px solid transparent', // prevent layout shift
      },
    },
    {
      props: { variant: 'outlined' },
      style: {
        '&.Mui-disabled': {
          border: `1px solid ${palette.other.disabled}`,
          color: palette.other.disabled,
        },
        border: '1px solid currentColor',
      },
    },
  ],
} as Components<Theme>['MuiIconButton'];

import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {
    size: 'small',
    variant: 'contained',
  },
  styleOverrides: {
    root: {
      borderRadius: '10px',
      textTransform: 'none',
    },
  },
} as Components<Theme>['MuiAutocomplete'];

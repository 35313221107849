import React from 'react';

function PencilOutlined() {
  return (
    <path
      d='m 0.99999983,33.134392 v 6.754713 c 0,0.62208 0.48882387,1.110974 1.11097407,1.110974 h 6.7546199 c 0.288834,0 0.577692,-0.111261 0.777658,-0.333316 L 33.906548,16.42558 25.574359,8.0933907 1.333292,32.334574 C 1.1110972,32.556629 0.99999982,32.823235 0.99999983,33.134392 Z M 40.350105,9.9820227 c 0.866527,-0.86655 0.866527,-2.2663588 0,-3.1329093 L 35.150942,1.649834 c -0.866527,-0.86655035 -2.266499,-0.86655035 -3.133026,0 l -4.06603,4.0660998 8.332189,8.3321892 z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default PencilOutlined;

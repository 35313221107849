import { useTheme } from '@mui/material';
import React from 'react';

function BulletTriangleOutlined() {
  const {
    palette: { primary },
  } = useTheme();

  return (
    <>
      <path
        d='m 15.036125,15.036375 c -2.125499,2.125473 -2.968363,5.240409 -2.180473,8.135473 0.78789,2.913338 3.059987,5.18556 5.95505,5.95505 2.913364,0.78799 6.01,-0.055 8.135474,-2.180472 2.125473,-2.125474 2.968462,-5.24041 2.180472,-8.135474 -0.78799,-2.913388 -3.059961,-5.18546 -5.95505,-5.95505 -2.913389,-0.78789 -6.01,0.05497 -8.135473,2.180473 z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 41.00025,29.474144 v 10.297621 c 0,0.677991 -0.549744,1.227735 -1.227735,1.227735 H 29.474894 c -1.099236,0 -1.648979,-1.319484 -0.861239,-2.088974 L 38.911276,28.612905 c 0.76974,-0.769491 2.088974,-0.219748 2.088974,0.861239 z'
        fill='transparent'
        stroke={primary.main}
        strokeWidth='2'
      />
      <path
        d='M 12.525531,1.0005004 H 2.2279025 c -0.6779565,0 -1.2276521,0.5496956 -1.2276521,1.2276521 V 12.525781 c 0,1.099386 1.3192685,1.649079 2.0888413,0.861189 L 13.38672,3.0893417 C 14.156286,2.3197689 13.624917,1.0005004 12.525531,1.0005004 Z'
        fill='transparent'
        stroke={primary.main}
        strokeWidth='2'
      />
    </>
  );
}

export default BulletTriangleOutlined;

import { useContext } from 'react';

const useSafeContext = <T>(
  newContext: React.Context<T>,
  contextName: string
): T => {
  const context = useContext(newContext);
  if (!context) {
    throw new Error(
      `useSafeContext(${contextName}) must be used within a ${contextName} Provider`
    );
  }
  return context;
};

export default useSafeContext;

import {
  Button as MuiButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import routesPaths from 'routesPaths';

import { ButtonSize } from '../button/shared/types';
import { ExcludedMuiLogoButtonProps, LogoDarkness } from './LogoButton.types';
import { LogoMapping, LogoName } from './svg/LogoMapping';

export interface LogoButtonProps
  extends Omit<MuiIconButtonProps, ExcludedMuiLogoButtonProps> {
  href?: string;
  name: LogoName;
  size?: ButtonSize;
  logoHeight?: string | number | undefined;
  logoWidth?: string | number | undefined;
  target?: string;
  darkness?: LogoDarkness;
  isButton?: boolean;
}

export function Logo({
  darkness = 'main',
  isButton = false,
  logoHeight = 'auto',
  logoWidth = 'auto',
  name,
  size = 'md',
  target,
  ...props
}: LogoButtonProps) {
  const imageComponent = (
    <img
      alt='Lacen CBS'
      height={logoHeight}
      src={LogoMapping[name]?.[darkness]}
      width={logoWidth}
    />
  );

  if (isButton) {
    return (
      <MuiButton
        component={Link as React.ElementType}
        disableRipple
        size={size}
        to={target || routesPaths.home}
        {...props}
      >
        {imageComponent}
      </MuiButton>
    );
  }

  return <div>{imageComponent}</div>;
}

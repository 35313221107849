import React from 'react';

function StateStep1Outlined() {
  return (
    <>
      <path
        d='M 32.172437,5.4506943 C 28.955704,2.4835648 25.229214,1 20.996484,1 16.763755,1 13.037265,2.4835648 9.8205308,5.4506943 6.6037967,8.4178239 4.9971875,12.400949 4.9971875,17.40007 c 0,3.332748 1.3253647,6.957286 3.9760942,10.873616 C 11.624011,32.190016 15.631746,36.433292 21,41 c 5.368255,-4.566708 9.375989,-8.809984 12.026718,-12.726314 2.650729,-3.91633 3.976094,-7.540868 3.976094,-10.873616 0,-4.999121 -1.606609,-8.9822461 -4.826859,-11.9493757 z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 13.0746 23.6793 L 16.8492 20.0465 L 21.0548 19.4762 L 21.0758 19.4762 L 23.5046 19.1488 L 24.2406 23.1936 L 28.5514 16.931 L 22.3165 12.5906 L 23.1261 17.0683 L 15.8924 18.0505 L 10.2673 23.4681 L 10.2673 10.1405 L 9.21586 10.1405 L 9.21586 24.482 L 9.21586 24.8833 L 9.21586 25.9605 L 10.1516 25.9605 L 10.2462 25.9605 L 10.2673 25.9605 L 10.7089 25.9605 L 25.9019 25.9605 L 25.9019 24.9044 L 11.8024 24.9044 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
    </>
  );
}

export default StateStep1Outlined;

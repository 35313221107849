import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material';
import { IconVariant } from 'design-system/icon/Icon.types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../../icon/Icon';
import type { IconName } from '../../icon/svg/IconMapping';
import { ButtonSize, ICON_SIZE_MAP } from '../shared/types';
import type {
  ExcludedMuiIconButtonProps,
  IconButtonColor,
  IconButtonVariant,
} from './IconButton.types';

export interface IconButtonProps
  extends Omit<MuiIconButtonProps, ExcludedMuiIconButtonProps> {
  href?: string;
  icon: IconName;
  variant?: IconButtonVariant;
  iconVariant?: IconVariant;
  size?: ButtonSize;
  target?: string;
  color?: IconButtonColor;
  to?: string;
}

export function IconButton({
  icon,
  iconVariant = 'filled',
  size = 'md',
  to,
  ...props
}: IconButtonProps) {
  const button = (
    <MuiIconButton focusRipple={false} size={size} {...props}>
      <Icon
        color='inherit'
        name={icon}
        size={ICON_SIZE_MAP[size]}
        titleAccess={undefined}
        variant={iconVariant}
      />
    </MuiIconButton>
  );

  return to ? <Link to={to}>{button}</Link> : button;
}

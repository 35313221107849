import React from 'react';

function KeyFilled() {
  return (
    <path
      d='M 32.444457,40.333367 19.000052,26.777807 c -0.740736,0.296295 -1.490742,0.518516 -2.250018,0.666692 -0.759248,0.148147 -1.564817,0.222221 -2.416649,0.222221 -3.703709,0 -6.8518813,-1.296303 -9.4444578,-3.888909 -2.5926057,-2.592605 -3.8889027,-5.740748 -3.8889027,-9.444458 0,-1.333325 0.185184,-2.601847 0.555555,-3.805565 C 1.9259505,9.324099 2.4444687,8.1851844 3.1111371,7.1111315 L 11.222264,15.222236 15.222269,11.222232 7.1111357,3.1111271 C 8.1852176,2.4444355 9.3241031,1.925926 10.527821,1.555555 11.73151,1.185184 13.000031,1 14.333385,1 c 3.70371,0 6.851854,1.2962883 9.444459,3.8888939 2.592577,2.5926057 3.88888,5.7407491 3.88888,9.4444591 0,0.851861 -0.07407,1.657401 -0.222221,2.416677 -0.148147,0.759247 -0.370368,1.509253 -0.666663,2.24999 l 13.555502,13.444463 c 0.444615,0.444325 0.666634,0.981352 0.666634,1.611079 0,0.629728 -0.222019,1.166754 -0.666634,1.611079 l -4.666725,4.666726 C 35.222291,40.777983 34.685265,41 34.055537,41 c -0.629436,0 -1.166463,-0.222017 -1.61108,-0.666633 z m 1.61108,-4.72223 1.50007,-1.500071 -14.222221,-14.222163 c 0.666662,-0.740736 1.148155,-1.601838 1.44445,-2.583336 0.296294,-0.981468 0.444442,-1.972206 0.444442,-2.972214 0,-2.222238 -0.712955,-4.157422 -2.138894,-5.8055527 -1.42591,-1.6481598 -3.194436,-2.638898 -5.305549,-2.9722438 l 4.111101,4.1111293 c 0.444441,0.4444422 0.666662,0.9629572 0.666662,1.5555462 0,0.592589 -0.222221,1.111133 -0.666662,1.555575 l -7.111126,7.111096 c -0.444441,0.444471 -0.962957,0.666692 -1.555546,0.666692 -0.592588,0 -1.111103,-0.222221 -1.555574,-0.666692 l -4.1111003,-4.1111 c 0.3333167,2.111112 1.3240841,3.879638 2.9722148,5.305548 1.6481595,1.425939 3.5833435,2.138894 5.8055805,2.138894 0.962957,0 1.925914,-0.148147 2.888871,-0.444442 0.962987,-0.296294 1.833359,-0.759247 2.611117,-1.388887 z'
      fill='currentColor'
    />
  );
}

export default KeyFilled;

import React from 'react';

function PetTrackFilled() {
  return (
    <>
      <path
        d='m 5.9999793,19.995752 c 2.7614667,0 4.9999587,-2.238492 4.9999587,-4.999907 0,-2.761414 -2.238492,-5.0000104 -4.9999587,-5.0000104 C 3.2385648,9.9958346 1,12.234431 1,14.995845 c 0,2.761415 2.2385648,4.999907 4.9999793,4.999907 z'
        fill='currentColor'
      />
      <path
        d='m 15.00007,11.995591 c 2.761415,0 5.000011,-2.2385964 5.000011,-5.0000104 0,-2.7613739 -2.238596,-4.999949 -5.000011,-4.999949 -2.761414,0 -5.00001,2.2385751 -5.00001,4.999949 0,2.761414 2.238596,5.0000104 5.00001,5.0000104 z'
        fill='currentColor'
      />
      <path
        d='m 27.000365,11.995591 c 2.761414,0 5.00001,-2.2385964 5.00001,-5.0000104 0,-2.7613739 -2.238596,-4.999949 -5.00001,-4.999949 -2.761415,0 -5.000011,2.2385751 -5.000011,4.999949 0,2.761414 2.238596,5.0000104 5.000011,5.0000104 z'
        fill='currentColor'
      />
      <path
        d='M 35.99999,19.995752 C 38.761404,19.995752 41,17.75726 41,14.995845 41,12.234431 38.761404,9.9958346 35.99999,9.9958346 c -2.761415,0 -5.000011,2.2385964 -5.000011,5.0000104 0,2.761415 2.238596,4.999907 5.000011,4.999907 z'
        fill='currentColor'
      />
      <path
        d='m 31.680369,25.715596 c -1.740003,-2.040029 -3.199961,-3.780032 -4.960049,-5.819956 -0.919954,-1.080009 -2.099971,-2.160018 -3.499987,-2.639976 -0.219998,-0.08003 -0.439996,-0.140074 -0.659994,-0.180036 -0.499938,-0.08003 -1.039943,-0.08003 -1.559967,-0.08003 -0.520022,0 -1.060027,0 -1.579947,0.100008 -0.219998,0.04007 -0.439996,0.100009 -0.659994,0.180036 -1.400016,0.479958 -2.560052,1.559967 -3.499986,2.639977 -1.740003,2.040027 -3.200065,3.78003 -4.960049,5.819955 -2.619994,2.619994 -5.839947,5.520034 -5.2399478,9.580006 0.5799975,2.039924 2.039987,4.059972 4.6599818,4.639938 1.459958,0.300025 6.11998,-0.879992 11.079926,-0.879992 h 0.360072 c 4.959944,0 9.619967,1.160036 11.079925,0.879992 2.619996,-0.579966 4.079954,-2.619995 4.660023,-4.639938 0.619929,-4.079954 -2.600013,-6.979993 -5.220007,-9.599986 z'
        fill='currentColor'
      />
    </>
  );
}

export default PetTrackFilled;

import React from 'react';

function EyeCloseFilled() {
  return (
    <>
      <path
        d='m 20.999912,11.23524 c 6.74965,0 12.769016,3.843633 15.707493,9.924851 -2.938477,6.081202 -8.939976,9.924957 -15.707493,9.924957 -6.767342,0 -12.768946,-3.843755 -15.7074225,-9.924957 C 8.230966,15.078873 14.250385,11.23524 20.999912,11.23524 Z m 0,-3.6090624 c -8.904416,0 -16.5088252,5.6120784 -19.589785,13.5339134 3.0809598,7.921888 10.685369,13.533984 19.589785,13.533984 8.904591,0 16.508896,-5.612096 19.589961,-13.533984 C 37.508808,13.238256 29.904503,7.6261776 20.999912,7.6261776 Z m 0,9.0226384 c 2.457635,0 4.452296,2.021059 4.452296,4.511275 0,2.490217 -1.994661,4.511328 -4.452296,4.511328 -2.457635,0 -4.452173,-2.021111 -4.452173,-4.511328 0,-2.490216 1.994538,-4.511275 4.452173,-4.511275 z m 0,-3.609062 c -4.416561,0 -8.013957,3.645147 -8.013957,8.120337 0,4.475243 3.597396,8.120355 8.013957,8.120355 4.416736,0 8.014027,-3.645112 8.014027,-8.120355 0,-4.47519 -3.597291,-8.120337 -8.014027,-8.120337 z'
        fill='currentColor'
      />
      <path d='M 2.3178923,1.4340831 23.079966,23.107099' fill='currentColor' />
      <path
        d='M 5.8295152,2.9176189 3.4429248,5.2014952 35.556159,38.725536 37.942748,36.441661 Z'
        fill='currentColor'
      />
    </>
  );
}

export default EyeCloseFilled;

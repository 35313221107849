import React, { ReactNode, createContext, useCallback, useState } from 'react';
import useSafeContext from 'utils/useSafeContext';

/**
 * Interface defining the context value provided by LoadingBarContext.
 */
interface LoadingContextValue {
  decrementQueriesLoading: () => void;
  incrementQueriesLoading: () => void;
  numberOfQueriesLoading: number;
}

// Create the context for LoadingBar
const LoadingContext = createContext<LoadingContextValue | undefined>(
  undefined
);

/**
 * Custom hook to access the LoadingBarContext.
 * Throws an error if used outside of the LoadingBarProvider.
 * @returns {LoadingContextValue} The context value.
 */
const useLoading = (): LoadingContextValue => {
  const context = useSafeContext(LoadingContext, 'Loading');
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};

/**
 * Provider component for LoadingBarContext.
 * @param {Object} props - Component props.
 * @param {ReactNode} props.children - The child elements to be wrapped by the provider.
 * @returns {JSX.Element} The provider component.
 */
function LoadingProvider({ children }: { children: ReactNode }): JSX.Element {
  // State to track the number of queries loading
  const [numberOfQueriesLoading, setNumberOfQueriesLoading] =
    useState<number>(0);

  /**
   * Increment the number of queries loading.
   * @returns {void}
   */
  const incrementQueriesLoading = useCallback(() => {
    setNumberOfQueriesLoading(
      (previousNumberOfQueriesLoading) => previousNumberOfQueriesLoading + 1
    );
  }, []);

  /**
   * Decrement the number of queries loading.
   * @returns {void}
   */
  const decrementQueriesLoading = useCallback(() => {
    setNumberOfQueriesLoading(
      (previousNumberOfQueriesLoading) => previousNumberOfQueriesLoading - 1
    );
  }, []);

  // Provide the context value and wrap the children
  return (
    <LoadingContext.Provider
      value={{
        decrementQueriesLoading,
        incrementQueriesLoading,
        numberOfQueriesLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export { LoadingProvider, useLoading };

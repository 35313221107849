import {
  Feature,
  MultiPolygon,
  Properties,
  area,
  multiPolygon,
} from '@turf/turf';
import { Expose, Type } from 'class-transformer';
import ArtificialArea from 'models/ArtificialArea/ArtificialArea.model';
import EcologicalSensitivityEnum from 'shared-api-front/enums/ecological-sensibility.enum';
import SiteProgressStatusEnum from 'shared-api-front/enums/site-progress-status.enum';

class SiteIndicatorValue {
  @Expose()
  public id: number;

  @Expose()
  public geom: MultiPolygon;

  @Expose()
  public geomRequestor: MultiPolygon;

  @Expose()
  public progressStatus: SiteProgressStatusEnum;

  @Expose()
  public ecologicalSensitivity?: EcologicalSensitivityEnum;

  @Expose()
  public tspComputed?: number;

  @Expose()
  public cbsComputed?: number;

  @Expose()
  public siteArea?: number;

  @Expose()
  public geomGeoJson?: Feature<MultiPolygon>;

  @Expose()
  public geomRequestorGeoJson?: Feature<MultiPolygon>;

  @Expose()
  @Type(() => ArtificialArea)
  public artificialAreas?: Array<ArtificialArea>;

  public getGeomGeoJson = () => {
    if (!this.geom) return undefined;
    const geomGeoJson: Feature<MultiPolygon, Properties> = multiPolygon(
      this.geom.coordinates,
      this
    );

    return geomGeoJson || undefined;
  };

  public getGeomRequestorGeoJson = () => {
    if (!this.geomRequestor) return undefined;
    const geomRequestorGeoJson: Feature<MultiPolygon, Properties> =
      multiPolygon(this.geomRequestor.coordinates, this);

    return geomRequestorGeoJson || undefined;
  };

  public getArea = () => {
    const geomGeoJson = this.getGeomGeoJson();
    if (!geomGeoJson) return undefined;
    return area(geomGeoJson);
  };

  constructor(model: Partial<SiteIndicatorValue> = {}) {
    if (model.artificialAreas && typeof model.artificialAreas === 'string') {
      this.artificialAreas = JSON.parse(model.artificialAreas);
    }

    Object.assign(this, model);
  }
}

export default SiteIndicatorValue;

import React from 'react';

function ChevronRightFilled() {
  return (
    <path
      d='M 13.35,1 8.65,5.6999999 23.916667,21 8.65,36.3 l 4.7,4.7 20,-20 z'
      fill='currentColor'
    />
  );
}

export default ChevronRightFilled;

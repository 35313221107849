import React from 'react';

function ZoomInFilled() {
  return (
    <path
      d='M 37.888901,41 23.888899,26.999998 c -1.111115,0.888869 -2.388914,1.592647 -3.833285,2.111107 -1.444483,0.518573 -2.981568,0.777859 -4.611144,0.777859 -4.037008,0 -7.4536948,-1.398182 -10.2500252,-4.194546 C 2.3981482,22.898167 1,19.48148 1,15.44447 1,11.407462 2.3981482,7.9907752 5.1944448,5.1944448 7.9907752,2.3981482 11.407462,1 15.44447,1 c 4.03701,0 7.453697,1.3981482 10.249948,4.1944448 2.796364,2.7963304 4.194546,6.2130172 4.194546,10.2500252 0,1.629576 -0.259286,3.166661 -0.777859,4.611144 -0.51846,1.444371 -1.222238,2.72217 -2.111107,3.833285 L 41,37.888901 Z M 15.44447,25.444505 c 2.777732,0 5.138864,-0.972212 7.083287,-2.916748 1.944536,-1.944423 2.916748,-4.305555 2.916748,-7.083287 0,-2.777843 -0.972212,-5.138863 -2.916748,-7.0833986 -1.944423,-1.9444228 -4.305555,-2.9166228 -7.083287,-2.9166228 -2.777843,0 -5.138863,0.9722 -7.0833986,2.9166228 -1.9444228,1.9445356 -2.9166228,4.3055556 -2.9166228,7.0833986 0,2.777732 0.9722,5.138864 2.9166228,7.083287 1.9445356,1.944536 4.3055556,2.916748 7.0833986,2.916748 z M 13.222241,22.11116 V 17.666701 H 8.7777819 v -4.44446 H 13.222241 V 8.7777819 h 4.44446 v 4.4444591 h 4.444459 v 4.44446 h -4.444459 v 4.444459 z'
      fill='currentColor'
    />
  );
}

export default ZoomInFilled;

import {
  Box as MuiBox,
  Container as MuiContainer,
  ContainerProps as MuiContainerProps,
} from '@mui/material';
import { useToast } from 'business/ToastContext';
import { useLayout } from 'design-components/LayoutContext';
import { LayoutReducerActionTypeEnum } from 'design-components/LayoutContext.enum';
import Panel from 'design-system/panel/Panel';
import Toast from 'design-system/toast/Toast';
import { styled } from 'mui-theme/styled';
import { muiTheme } from 'mui-theme/theme';
import useDeviceType, { Devices } from 'mui-theme/utils/useDeviceType';
import React, { useEffect } from 'react';

const getMarginTop = (devices: Devices) => {
  if (devices.isMobile) {
    return '30px';
  }
  if (devices.isTablet) {
    return '64px';
  }
  return '100px';
};

interface PageProps extends Omit<MuiContainerProps, 'maxWidth'> {
  hasSidePanel?: boolean;
  sidePanelComponent?: React.ReactNode;
  title?: string;
  maxWidth?: boolean;
  hasMarginTop?: boolean;
  withPadding?: true;
  color?: 'primary';
}

// Enum pour les tailles du Drawer
enum SidePanelWidthEnum {
  DESKTOP = 400,
  TABLET = 300,
  MOBILE = 200,
}

const StyledPage = styled(MuiContainer, {
  shouldForwardProp: (prop) =>
    prop !== 'sidePanelWidth' && prop !== 'hasSidePanel' && prop !== 'isOpen',
})<
  PageProps & {
    isOpen: boolean;
    sidePanelWidth?: number;
    devices: Devices;
  }
>(
  ({
    devices,
    hasMarginTop = false,
    hasSidePanel,
    isOpen,
    sidePanelWidth = 400,
  }) => ({
    display: 'grid',
    gridTemplateAreas:
      hasSidePanel && isOpen ? `'sidePanel mainContent'` : `'mainContent'`,
    gridTemplateColumns:
      hasSidePanel && isOpen ? ` ${sidePanelWidth}px 1fr` : `1fr`,
    height: `calc(100vh - 72px)`,
    marginTop: hasMarginTop ? getMarginTop(devices) : 'unset',
    maxHeight: `calc(100vh - 72px)`,
    position: 'relative',
    transition: muiTheme.transitions.create(
      ['grid-template-columns', 'grid-template-areas'],
      {
        duration: muiTheme.transitions.duration.leavingScreen,
        easing: muiTheme.transitions.easing.sharp,
      }
    ),
  })
);

const SidePanelContainer = styled(Panel)`
  grid-area: sidePanel;
`;

const MainContainer = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== 'withPadding',
})<
  {
    withPadding?: boolean;
  } & Pick<PageProps, 'color'>
>(({ color, withPadding }) => ({
  backgroundColor: color ? muiTheme.palette.primary.shade50 : `none`,
  gridArea: 'mainContent',
  padding: withPadding ? muiTheme.spacing(10) : 0,
}));

function Page({
  children,
  color,
  hasSidePanel = true,
  maxWidth = false,
  sidePanelComponent = null,
  title,
  withPadding,
  ...props
}: PageProps) {
  const devices = useDeviceType();
  const {
    layoutDispatch,
    layoutState: {
      sidePanel: { component: panelComponent, hasPanel },
    },
  } = useLayout();

  const { toastState } = useToast();

  useEffect(() => {
    layoutDispatch({
      type: LayoutReducerActionTypeEnum.SET_PAGE_TITLE,
      value: title,
    });
  }, [layoutDispatch, title]);

  const getSidePanelWidth = (): number => {
    if (devices.isDesktop) return SidePanelWidthEnum.DESKTOP;
    if (devices.isTablet) return SidePanelWidthEnum.TABLET;
    return SidePanelWidthEnum.MOBILE;
  };

  useEffect(() => {
    layoutDispatch({
      type: LayoutReducerActionTypeEnum.SET_HAS_SIDE_PANEL,
      value: hasSidePanel,
    });
  }, [layoutDispatch, hasSidePanel]);

  useEffect(() => {
    layoutDispatch({
      type: LayoutReducerActionTypeEnum.SET_SIDE_PANEL_COMPONENT,
      value: sidePanelComponent,
    });
  }, [layoutDispatch, sidePanelComponent]);

  return (
    <StyledPage
      devices={devices}
      hasSidePanel={hasPanel}
      isOpen
      maxWidth={maxWidth === false ? false : undefined}
      sidePanelWidth={getSidePanelWidth()}
      {...props}
    >
      {hasPanel && (
        <SidePanelContainer
          anchor='left'
          gridAreaName='sidePanel'
          width={getSidePanelWidth()}
        >
          {panelComponent}
        </SidePanelContainer>
      )}

      <MainContainer color={color} withPadding={withPadding}>
        {children}
        {toastState.message && (
          <Toast isOpen={toastState.isOpen} message={toastState.message} />
        )}
      </MainContainer>
    </StyledPage>
  );
}

export default Page;

import React from 'react';

function TrashFilled() {
  return (
    <path
      d='M 7.6666667,36.555556 C 7.6666667,39 9.6666667,41 12.111112,41 h 17.777776 c 2.444445,0 4.444445,-2 4.444445,-4.444444 V 9.888889 H 7.6666667 Z M 12.111112,14.333333 H 29.888888 V 36.555556 H 12.111112 Z M 28.777777,3.2222222 26.555555,1 H 15.444444 L 13.222222,3.2222222 H 5.4444445 V 7.6666667 H 36.555555 V 3.2222222 Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0'
    />
  );
}

export default TrashFilled;

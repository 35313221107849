import React from 'react';

function FileFilled() {
  return (
    <path
      d='M 13 33 L 29 33 L 29 28.9999 L 13 28.9999 Z M 13 24.9999 L 29 24.9999 L 29 20.9999 L 13 20.9999 Z M 9 41 C 7.90001 41 6.95833 40.6083 6.17501 39.8249 C 5.39167 39.0416 5.00001 38.0999 5.00001 37 L 5.00001 4.99999 C 5.00001 3.9 5.39167 2.95832 6.17501 2.175 C 6.95833 1.39166 7.90001 1 9 1 L 24.9999 1 L 37 13 L 37 37 C 37 38.0999 36.6083 39.0416 35.8249 39.8249 C 35.0416 40.6083 34.0999 41 33 41 Z M 23 14.9999 L 23 4.99999 L 9 4.99999 L 9 37 L 33 37 L 33 14.9999 Z'
      fill='currentColor'
    />
  );
}

export default FileFilled;

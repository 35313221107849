const wording = {
  company: {
    AddLabel: 'ajouter une entreprise',
    ListLabel: 'liste des entreprises',
    MenuLabel: 'entreprises',
  },
  menuLabel: 'Cartographie',
  prescriber: {
    AddLabel: 'ajouter un prescripteur',
    ListLabel: 'liste des prescripteurs',
    MenuLabel: 'prescripteurs',
  },
  site: {
    AddLabel: 'ajouter un site',
    ListLabel: 'liste des sites',
    MenuLabel: 'sites',
  },
  user: {
    AddLabel: 'ajouter un utilisateur',
    ListLabel: 'liste des utilisateurs',
    MenuLabel: 'utilisateurs',
  },
};

export default wording;

import React from 'react';

function FilesOutlined() {
  return (
    <>
      <path
        d='M 6.7575964,34.712305 H 18.272816 V 31.83349 H 6.7575964 Z m 0,-5.75763 H 18.272816 V 28.738699 26.07586 H 17.969685 6.7575964 Z M 3.8788018,40.469833 c -0.7916768,0 -1.4693899,-0.281834 -2.0331549,-0.845604 C 1.281883,39.060459 1,38.382756 1,37.591017 V 14.560702 C 1,13.768963 1.281883,13.091261 1.8456469,12.52749 2.4094119,11.96372 3.087125,11.681887 3.8788018,11.681887 H 15.394001 l 8.636342,8.636342 v 17.272788 c 0,0.791739 -0.281833,1.469442 -0.845603,2.033212 -0.563771,0.56377 -1.241473,0.845604 -2.033109,0.845604 z M 13.954541,21.757689 V 14.560702 H 3.8788018 V 37.591017 H 21.151631 v -7.272872 -2.878816 -5.68164 H 20.8485 17.969685 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 35.242473,24.560514 H 23.727315 v -2.878712 h 11.515158 z m 0,-5.757527 H 23.727315 V 15.924172 H 35.242473 Z M 21.151631,30.318145 h 16.969657 c 0.791636,0 1.469338,-0.281834 2.033108,-0.845604 C 40.718167,28.908771 41,28.230965 41,27.439329 V 10.166562 L 32.363658,1.5301665 H 20.8485 c -0.791739,0 -1.469442,0.281882 -2.033212,0.8456469 -0.56377,0.5637598 -0.845603,1.2414833 -0.845603,2.0331497 V 21.757689 H 20.8485 V 4.4089631 h 10.075802 v 7.1969959 h 7.196986 v 15.83337 H 21.151631 Z M 17.969685,26.07586 v 1.363469 c 0,0.474031 0.101009,0.907119 0.303131,1.29937 V 26.07586 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
    </>
  );
}

export default FilesOutlined;

/* eslint-disable react/jsx-props-no-spreading */

import { styled } from '@mui/material';
import { useUser } from 'business/loginAndRights/AuthContext';
import { checkRoles } from 'business/loginAndRights/userRoles';
import InternalRedirect from 'design-system/link/InternalRedirect';
import Page from 'design-system/page/Page';
import { Heading2, Subtitle } from 'design-system/typography/Typography';
import RouteConfig from 'interfaces/RouteConfig.interface';
import { muiTheme } from 'mui-theme/theme';
import React from 'react';
import { Route } from 'react-router-dom';
import routesPaths from 'routesPaths';

import Loader from '../Loader';
import wording from './wording';

const StyledPage = styled(Page)`
  text-align: center;
`;

const Title = styled(Heading2)`
  color: ${muiTheme.palette.alert.main};
  margin-bottom: 20px;
`;

interface NoAccessPageProps {
  subTitle: string;
  title: string;
}

function NoAccessPage({ subTitle, title }: NoAccessPageProps) {
  return (
    <StyledPage hasMarginTop title='No Access Page'>
      <Page>
        <Title>{title}</Title>
        <Subtitle>{subTitle}</Subtitle>
      </Page>
    </StyledPage>
  );
}

interface RedirectLoginComponentProps {
  path: string;
}

function RedirectLoginComponent({
  path,
  ...other
}: RedirectLoginComponentProps) {
  return (
    <Route
      path={path}
      render={() => <InternalRedirect to={`/${routesPaths.auth}`} />}
      {...other}
    />
  );
}

interface PrivateRouteProps
  extends Omit<RouteConfig, 'Component' | 'testExistence'> {
  children?: React.ReactNode;
  rest?: Record<string, unknown>;
}

function PrivateRoute({
  children,
  path = '',
  roles,
  rolesDifferentFrom,
  ...rest
}: PrivateRouteProps) {
  const { isAuthenticated, isLoading, userData, validateToken } = useUser();

  if (roles || rolesDifferentFrom) {
    // the user query is pending, wait
    if (isLoading || (isAuthenticated && !userData?.role)) return <Loader />;

    if (!userData || !validateToken())
      return <RedirectLoginComponent path={path} {...rest} />;

    if (
      userData?.role &&
      !checkRoles({
        isAuthenticated,
        roles,
        rolesDifferentFrom,
        userData,
      })
    ) {
      return isAuthenticated ? (
        <NoAccessPage
          subTitle={wording.NoAccessSubtitle}
          title={wording.NoAccessTitle}
        />
      ) : (
        <RedirectLoginComponent path={path} {...rest} />
      );
    }
  }

  return <Route {...rest}>{children}</Route>;
}

export default PrivateRoute;

import React from 'react';

function TrophyOutlined() {
  return (
    <path
      d='m 9.888889,41 v -4.444444 h 8.888889 v -6.888889 c -1.814889,-0.407334 -3.435178,-1.176 -4.861111,-2.305556 -1.425934,-1.129555 -2.472223,-2.546222 -3.138889,-4.25 C 8,22.777778 5.6759333,21.564889 3.8055556,19.472222 1.9351778,17.379556 1,14.925933 1,12.111111 V 9.888889 C 1,8.666667 1.4351778,7.6203778 2.3055556,6.75 3.1759333,5.8796222 4.2222222,5.4444444 5.4444444,5.4444444 H 9.888889 V 1 h 22.222222 v 4.4444444 h 4.444445 c 1.222222,0 2.268444,0.4351778 3.138888,1.3055556 C 40.564889,7.6203778 41,8.666667 41,9.888889 v 2.222222 c 0,2.814822 -0.935111,5.268445 -2.805556,7.361111 C 36.324,21.564889 34,22.777778 31.222222,23.111111 c -0.666666,1.703778 -1.712889,3.120445 -3.138889,4.25 -1.426,1.129556 -3.046222,1.898222 -4.861111,2.305556 v 6.888889 h 8.888889 V 41 Z m 0,-22.666667 V 9.888889 H 5.4444444 v 2.222222 c 0,1.4074 0.4074,2.675933 1.2222223,3.805556 0.8148222,1.129555 1.8888893,1.935111 3.2222223,2.416666 z M 21,25.444444 c 1.851778,0 3.426,-0.648222 4.722222,-1.944444 1.296222,-1.296222 1.944445,-2.870444 1.944445,-4.722222 V 5.4444444 H 14.333333 V 18.777778 c 0,1.851778 0.648156,3.426 1.944445,4.722222 C 17.574,24.796222 19.148222,25.444444 21,25.444444 Z m 11.111111,-7.111111 c 1.333333,-0.481555 2.407333,-1.287111 3.222222,-2.416666 0.814889,-1.129623 1.222223,-2.398156 1.222223,-3.805556 V 9.888889 h -4.444445 z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default TrophyOutlined;

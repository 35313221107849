import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(4),
      paddingTop: 0,
    }),
  },
} as Components<Theme>['MuiTableContainer'];

import { useHistory } from 'react-router-dom';

function useSafeHistory() {
  const history = useHistory();

  return {
    history,
  };
}

export { useSafeHistory };

import jwtDecode from 'jwt-decode';
import windowRedirectTo from 'utils/windowRedirectTo';

interface AuthenticationService {
  connectUser: (token: string) => Promise<void>;
  hasValidToken: () => boolean;
  disconnectUser: () => Promise<void>;
  expireUserToken: () => Promise<void>;
  getIsAuthenticated: () => boolean;
  getToken: () => string | undefined;
  init: () => Promise<string | null>;
}

interface DecodedToken {
  exp: number;
}

const authenticationService = (): AuthenticationService => {
  let userToken: string | undefined;
  let isAuthenticated: boolean;

  return {
    connectUser: (token) =>
      new Promise((resolve) => {
        localStorage.setItem('token', token);
        userToken = token;
        isAuthenticated = !!token;
        resolve();
      }),

    disconnectUser: () =>
      new Promise((resolve) => {
        localStorage.removeItem('token');
        localStorage.clear();
        windowRedirectTo(null);
        resolve();
      }),

    expireUserToken: () =>
      new Promise((resolve) => {
        userToken = undefined;
        localStorage.removeItem('token');
        localStorage.clear();
        windowRedirectTo(null);
        resolve();
      }),

    getIsAuthenticated: () => isAuthenticated,

    getToken: () => userToken,

    hasValidToken: () => {
      if (!userToken) return false;
      try {
        const decodedToken = jwtDecode(userToken) as DecodedToken;
        return decodedToken.exp * 1000 > Date.now();
      } catch (error) {
        windowRedirectTo('/');
        return false;
      }
    },

    init: () =>
      new Promise((resolve) => {
        const token = localStorage.getItem('token');
        userToken = token || undefined;
        isAuthenticated = !!userToken;
        resolve(token);
      }),
  };
};

const authenticationServiceInstance: AuthenticationService =
  authenticationService();

export default authenticationServiceInstance;

import React from 'react';

function StateStep0Outlined() {
  return (
    <>
      <path
        d='M 32.172437,5.4506943 C 28.955704,2.4835648 25.229214,1 20.996484,1 16.763755,1 13.037265,2.4835648 9.8205308,5.4506943 6.6037967,8.4178239 4.9971875,12.400949 4.9971875,17.40007 c 0,3.332748 1.3253647,6.957286 3.9760942,10.873616 C 11.624011,32.190016 15.631746,36.433292 21,41 c 5.368255,-4.566708 9.375989,-8.809984 12.026718,-12.726314 2.650729,-3.91633 3.976094,-7.540868 3.976094,-10.873616 0,-4.999121 -1.606609,-8.9822461 -4.826859,-11.9493757 z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 11.8332 24.9044 L 16.8765 20.0993 L 19.8982 23.2041 L 20.0259 15.5898 L 12.3546 15.4525 L 15.3976 18.589 L 10.2798 23.4576 L 10.2798 10.1405 L 9.21586 10.1405 L 9.21586 24.4714 L 9.21586 24.8939 L 9.21586 25.9605 L 10.2586 25.9605 L 10.7267 25.9605 L 26.0905 25.9605 L 26.0905 24.9044 Z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
    </>
  );
}

export default StateStep0Outlined;

import { useTheme } from '@mui/material';
import React from 'react';

function StateStep4Filled() {
  const {
    palette: { tertiary },
  } = useTheme();

  return (
    <>
      <path
        d='M 32.249278,5.4506944 C 29.010444,2.4835667 25.258278,1 20.996444,1 16.734611,1 12.9825,2.4835667 9.7436277,5.4506944 6.5047721,8.4178333 4.887111,12.400944 4.887111,17.400056 c 0,3.332777 1.3344833,6.957277 4.0034444,10.873611 C 11.5595,32.19 15.594833,36.433278 21,41 c 5.405167,-4.566722 9.440444,-8.81 12.109444,-12.726333 2.668945,-3.916334 4.003445,-7.540834 4.003445,-10.873611 0,-4.999112 -1.617667,-8.9822227 -4.860056,-11.9493616 z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0'
      />
      <path
        d='M 20.0786 23.2945 L 15.7128 23.2945 C 14.2031 23.2945 12.9161 22.7661 11.8519 21.7092 C 10.7878 20.6523 10.2558 19.3742 10.2558 17.8747 C 10.2558 16.3752 10.7878 15.0971 11.8519 14.0402 C 12.9161 12.9833 14.2031 12.4549 15.7128 12.4549 L 20.0786 12.4549 L 20.0786 14.6228 L 15.7128 14.6228 C 14.8033 14.6228 14.0303 14.939 13.3936 15.5713 C 12.7569 16.2036 12.4386 16.9714 12.4386 17.8747 C 12.4386 18.778 12.7569 19.5458 13.3936 20.1781 C 14.0303 20.8105 14.8033 21.1266 15.7128 21.1266 L 20.0786 21.1266 Z M 16.8043 18.9587 L 16.8043 16.7907 L 25.5356 16.7907 L 25.5356 18.9587 Z M 22.2614 23.2945 L 22.2614 21.1266 L 26.6271 21.1266 C 27.5366 21.1266 28.3097 20.8105 28.9464 20.1781 C 29.583 19.5458 29.9013 18.778 29.9013 17.8747 C 29.9013 16.9714 29.583 16.2036 28.9464 15.5713 C 28.3097 14.939 27.5366 14.6228 26.6271 14.6228 L 22.2614 14.6228 L 22.2614 12.4549 L 26.6271 12.4549 C 28.1369 12.4549 29.4238 12.9833 30.488 14.0402 C 31.5521 15.0971 32.0842 16.3752 32.0842 17.8747 C 32.0842 19.3742 31.5521 20.6523 30.488 21.7092 C 29.4238 22.7661 28.1369 23.2945 26.6271 23.2945 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
    </>
  );
}

export default StateStep4Filled;

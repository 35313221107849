import React from 'react';

function MarkerCheckOutlined() {
  return (
    <path
      d='M 18.9,23.7 28.8,13.8 25.95,10.95 18.9,18 16.1,15.2 13.25,18.05 Z m 2.1,12 C 25.0666,31.9666 28.0834,28.575 30.05,25.525 32.0166,22.475 33,19.7666 33,17.4 33,13.76666 31.8416,10.79166 29.525,8.475 27.2084,6.15834 24.3666,5 21,5 17.6334,5 14.79166,6.15834 12.475,8.475 10.15834,10.79166 9,13.76666 9,17.4 c 0,2.3666 0.98334,5.075 2.95,8.125 1.96666,3.05 4.98334,6.4416 9.05,10.175 z M 21,41 C 15.63334,36.4334 11.625,32.1916 8.975,28.275 6.325,24.3584 5,20.7334 5,17.4 5,12.4 6.60834,8.41666 9.825,5.45 13.04166,2.48334 16.76666,1 21,1 25.2334,1 28.9584,2.48334 32.175,5.45 35.3916,8.41666 37,12.4 37,17.4 37,20.7334 35.675,24.3584 33.025,28.275 30.375,32.1916 26.3666,36.4334 21,41 Z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default MarkerCheckOutlined;

import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

import type {
  ExcludedMuiSvgIconProps,
  IconColor,
  IconDarkness,
  IconSize,
  IconVariant,
} from './Icon.types';
import { IconMapping, type IconName } from './svg/IconMapping';
import { SystemIconMapping, SystemIconName } from './system/SystemIconMapping';

interface CoreIconProps extends Omit<SvgIconProps, ExcludedMuiSvgIconProps> {
  MappedIcon: () => JSX.Element;
  name: IconName | SystemIconName;
  variant: IconVariant;
  size?: IconSize;
  color?: IconColor;
  darkness?: IconDarkness;
}

interface IconProps
  extends Omit<CoreIconProps, 'MappedIcon' | 'name' | 'variant'> {
  name: IconName;
  variant?: IconVariant;
}

interface SystemIconProps
  extends Omit<CoreIconProps, 'MappedIcon' | 'name' | 'variant'> {
  name: SystemIconName;
}

const computeColor = ({
  color = 'primary',
  darkness,
}: {
  color?: IconColor;
  darkness: IconDarkness;
  variant: IconVariant;
}): SvgIconProps['color'] => {
  const currentColor = color || 'primary';

  if (!currentColor || currentColor === 'inherit') {
    return undefined; // force consistent-return rule
  }

  if (
    darkness === 'main' ||
    currentColor === 'light' ||
    currentColor === 'disabled'
  ) {
    return currentColor;
  }

  return `${currentColor}-${darkness}`;
};

function CoreIcon({
  MappedIcon,
  color,
  darkness = 'main',
  name,
  size = 'md',
  variant,
  ...props
}: CoreIconProps) {
  // calling the component from a JS file
  if (!MappedIcon)
    throw new Error(
      'Icon name not found. The icon name provided does not correspond to any available icons.'
    );

  return (
    <SvgIcon
      color={computeColor({ color, darkness, variant })}
      fontSize={size}
      titleAccess={name}
      {...props}
      viewBox='0 0 42 42'
    >
      <MappedIcon />
    </SvgIcon>
  );
}

function Icon({ name, variant = 'filled', ...props }: IconProps) {
  return (
    <CoreIcon
      MappedIcon={IconMapping[name]?.[variant]}
      name={name}
      variant={variant}
      {...props}
    />
  );
}

function SystemIcon({ name, ...props }: SystemIconProps) {
  return (
    <CoreIcon
      MappedIcon={SystemIconMapping[name]}
      name={name}
      variant='outlined'
      {...props}
    />
  );
}

export { Icon, IconProps, SystemIconProps, SystemIcon };

import { useTheme } from '@mui/material';
import React from 'react';

function MarkerDotStackMediumFilled() {
  const {
    palette: { tertiary },
  } = useTheme();

  return (
    <>
      <path
        d='M 32.1742 5.4507 C 28.9575 2.48357 25.231 1 20.9983 1 C 16.7655 1 13.039 2.48357 9.8223 5.4507 C 6.60556 8.41783 4.99895 12.401 4.99895 17.4001 C 4.99895 20.7328 6.32432 24.3574 8.97505 28.2737 C 11.6258 32.19 15.6335 36.4333 21.0018 41 C 26.37 36.4333 30.3778 32.19 33.0285 28.2737 C 35.6792 24.3574 37.0046 20.7328 37.0046 17.4001 C 37.0046 12.401 35.398 8.41783 32.1777 5.4507 Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 8.87387 17.5571 C 8.87387 18.2864 8.25764 18.8941 7.49519 18.8941 L 5.15562 18.8941 C 5.05117 18.2155 4.99895 17.547 4.99895 16.8684 C 4.99895 16.6557 4.99895 16.4329 5.01984 16.2202 L 7.49519 16.2202 C 8.25764 16.2202 8.87387 16.8177 8.87387 17.5571 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 15.1405 16.2202 L 11.8505 16.2202 C 11.0891 16.2202 10.4718 16.8188 10.4718 17.5571 C 10.4718 18.2955 11.0891 18.8941 11.8505 18.8941 L 15.1405 18.8941 C 15.902 18.8941 16.5192 18.2955 16.5192 17.5571 C 16.5192 16.8188 15.902 16.2202 15.1405 16.2202 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 22.786 16.2202 L 19.4959 16.2202 C 18.7345 16.2202 18.1172 16.8188 18.1172 17.5571 C 18.1172 18.2955 18.7345 18.8941 19.4959 18.8941 L 22.786 18.8941 C 23.5474 18.8941 24.1646 18.2955 24.1646 17.5571 C 24.1646 16.8188 23.5474 16.2202 22.786 16.2202 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 30.4314 16.2202 L 27.1413 16.2202 C 26.3799 16.2202 25.7627 16.8188 25.7627 17.5571 C 25.7627 18.2955 26.3799 18.8941 27.1413 18.8941 L 30.4314 18.8941 C 31.1928 18.8941 31.81 18.2955 31.81 17.5571 C 31.81 16.8188 31.1928 16.2202 30.4314 16.2202 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 36.9905 16.8684 C 36.9905 17.547 36.9383 18.2155 36.8339 18.8941 L 34.7867 18.8941 C 34.0243 18.8941 33.408 18.2864 33.408 17.5571 C 33.408 16.8279 34.0243 16.2202 34.7867 16.2202 L 36.9696 16.2202 C 36.9905 16.4329 36.9905 16.6557 36.9905 16.8684 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 11.3284 20.3019 L 8.03833 20.3019 C 7.27691 20.3019 6.65966 20.9005 6.65966 21.6389 C 6.65966 22.3773 7.27691 22.9759 8.03833 22.9759 L 11.3284 22.9759 C 12.0898 22.9759 12.707 22.3773 12.707 21.6389 C 12.707 20.9005 12.0898 20.3019 11.3284 20.3019 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 18.9738 20.3019 L 15.6837 20.3019 C 14.9223 20.3019 14.3051 20.9005 14.3051 21.6389 C 14.3051 22.3773 14.9223 22.9759 15.6837 22.9759 L 18.9738 22.9759 C 19.7352 22.9759 20.3525 22.3773 20.3525 21.6389 C 20.3525 20.9005 19.7352 20.3019 18.9738 20.3019 Z'
        fill={tertiary.light}
        id='path8'
        strokeWidth='0'
      />
      <path
        d='M 26.6192 20.3019 L 23.3292 20.3019 C 22.5677 20.3019 21.9505 20.9005 21.9505 21.6389 C 21.9505 22.3773 22.5677 22.9759 23.3292 22.9759 L 26.6192 22.9759 C 27.3806 22.9759 27.9978 22.3773 27.9978 21.6389 C 27.9978 20.9005 27.3806 20.3019 26.6192 20.3019 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 34.2645 20.3019 L 30.9745 20.3019 C 30.2131 20.3019 29.5958 20.9005 29.5958 21.6389 C 29.5958 22.3773 30.2131 22.9759 30.9745 22.9759 L 34.2645 22.9759 C 35.026 22.9759 35.6432 22.3773 35.6432 21.6389 C 35.6432 20.9005 35.026 20.3019 34.2645 20.3019 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 15.5897 24.3837 L 12.2997 24.3837 C 11.5382 24.3837 10.921 24.9823 10.921 25.7207 C 10.921 26.4591 11.5382 27.0576 12.2997 27.0576 L 15.5897 27.0576 C 16.3511 27.0576 16.9684 26.4591 16.9684 25.7207 C 16.9684 24.9823 16.3511 24.3837 15.5897 24.3837 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 23.2351 24.3837 L 19.9451 24.3837 C 19.1836 24.3837 18.5664 24.9823 18.5664 25.7207 C 18.5664 26.4591 19.1836 27.0576 19.9451 27.0576 L 23.2351 27.0576 C 23.9965 27.0576 24.6138 26.4591 24.6138 25.7207 C 24.6138 24.9823 23.9965 24.3837 23.2351 24.3837 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 30.87 24.3837 L 27.58 24.3837 C 26.8186 24.3837 26.2013 24.9823 26.2013 25.7207 C 26.2013 26.4591 26.8186 27.0576 27.58 27.0576 L 30.87 27.0576 C 31.6314 27.0576 32.2487 26.4591 32.2487 25.7207 C 32.2487 24.9823 31.6314 24.3837 30.87 24.3837 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 13.1353 29.8127 C 13.1353 30.5419 12.519 31.1496 11.767 31.1496 L 11.3179 31.1496 C 10.6286 30.2988 9.96014 29.3974 9.32302 28.4757 L 11.767 28.4757 C 12.519 28.4757 13.1353 29.0733 13.1353 29.8127 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 19.4124 28.4757 L 16.1224 28.4757 C 15.361 28.4757 14.7437 29.0743 14.7437 29.8127 C 14.7437 30.5511 15.361 31.1496 16.1224 31.1496 L 19.4124 31.1496 C 20.1739 31.1496 20.7911 30.5511 20.7911 29.8127 C 20.7911 29.0743 20.1739 28.4757 19.4124 28.4757 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 27.0578 28.4757 L 23.7678 28.4757 C 23.0064 28.4757 22.3891 29.0743 22.3891 29.8127 C 22.3891 30.5511 23.0064 31.1496 23.7678 31.1496 L 27.0578 31.1496 C 27.8193 31.1496 28.4365 30.5511 28.4365 29.8127 C 28.4365 29.0743 27.8193 28.4757 27.0578 28.4757 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 32.6561 28.4757 C 32.0607 29.3467 31.4341 30.1874 30.7865 30.9977 C 30.3374 30.7749 30.024 30.3292 30.024 29.8127 C 30.024 29.0733 30.6507 28.4757 31.4027 28.4757 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 16.958 33.8944 C 16.958 34.6338 16.3417 35.2314 15.5793 35.2314 L 14.9526 35.2314 C 14.1588 34.411 13.3233 33.5197 12.4877 32.5575 L 15.5793 32.5575 C 16.3417 32.5575 16.958 33.1652 16.958 33.8944 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 23.2351 32.5575 L 19.9451 32.5575 C 19.1836 32.5575 18.5664 33.1561 18.5664 33.8944 C 18.5664 34.6328 19.1836 35.2314 19.9451 35.2314 L 23.2351 35.2314 C 23.9965 35.2314 24.6138 34.6328 24.6138 33.8944 C 24.6138 33.1561 23.9965 32.5575 23.2351 32.5575 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 29.4913 32.5575 C 28.6871 33.4893 27.8829 34.3502 27.11 35.1504 C 26.5878 34.9579 26.2013 34.4718 26.2013 33.8944 C 26.2013 33.1652 26.828 32.5575 27.58 32.5575 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 20.7807 37.9865 C 20.7807 38.7156 20.1644 39.3133 19.402 39.3133 L 19.3393 39.3133 C 18.5455 38.6446 17.5324 37.7432 16.394 36.6495 L 19.402 36.6495 C 20.1644 36.6495 20.7807 37.2468 20.7807 37.9865 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 25.5852 36.6495 C 24.5929 37.6015 23.6947 38.4119 22.9531 39.05 C 22.6085 38.8169 22.3891 38.4217 22.3891 37.9865 C 22.3891 37.2468 23.0054 36.6495 23.7573 36.6495 Z'
        fill={tertiary.light}
        strokeWidth='0'
      />
    </>
  );
}

export default MarkerDotStackMediumFilled;

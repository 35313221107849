import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse as MuiCollapse,
  Link as MuiLink,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  styled,
} from '@mui/material';
import { Icon } from 'design-system/icon/Icon';
import { IconColor } from 'design-system/icon/Icon.types';
import { IconName } from 'design-system/icon/svg/IconMapping';
import { TextBody2 } from 'design-system/typography/Typography';
import { muiTheme } from 'mui-theme/theme';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ExcludedMuiMenuItemProps, MenuItemIconColor } from './MenuItem.types';

const StyledMenuItem = styled(MuiMenuItem, {
  shouldForwardProp: (prop) => prop !== 'isGroup',
})<{ isGroup?: boolean }>`
  color: ${({ isGroup }) =>
    isGroup ? muiTheme.palette.text.primary : muiTheme.palette.text.dark};
  background-color: ${({ isGroup }) =>
    isGroup
      ? muiTheme.palette.secondary.main
      : muiTheme.palette.background.background1};
  border-radius: 5px;
  margin-bottom: ${({ isGroup }) => (isGroup ? '8px' : '4px')};
  &:hover {
    background-color: ${({ isGroup }) =>
      isGroup
        ? muiTheme.palette.secondary.light
        : muiTheme.palette.background.background2};
  }
`;

const Collapse = styled(MuiCollapse)<{ isGroup?: boolean }>`
  margin-bottom: 10px;
  margin-left: 10px;
`;
/**
 * Props for the MenuItem component.
 */
interface MenuItemProps
  extends Omit<MuiMenuItemProps, ExcludedMuiMenuItemProps> {
  id: string;
  isDisplayed?: () => boolean;
  onClick?: () => void;
  label: string;
  icon?: IconName;
  color?: MenuItemIconColor;
  path?: string;
  menuItems?: Array<MenuItemProps>;
  handleCloseMenu?: () => void;
}

/**
 * A menu item component representing an option in a menu.
 * @param {MenuItemProps} props - The props for the MenuItem component.
 * @returns {JSX.Element | null} - The rendered MenuItem component or null if not displayed.
 */
function MenuItem({
  color = 'primary',
  disabled = false,
  handleCloseMenu,
  icon,
  isDisplayed,
  label = 'Button',
  menuItems,
  onClick,
  path,
}: MenuItemProps): JSX.Element | null {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = () => {
    if (handleCloseMenu) handleCloseMenu();
    if (onClick) onClick();
  };

  /**
   * Gets the JSX for the icon, if provided.
   * @returns {JSX.Element | null} - The rendered icon or null if not provided.
   */
  const getIcon = (): JSX.Element | null => {
    if (!icon) return null;
    return (
      <MuiListItemIcon>
        <Icon
          color={color as IconColor}
          name={icon}
          size='sm'
          variant='filled'
        />
      </MuiListItemIcon>
    );
  };

  const menuItemContent = (
    <>
      {getIcon()}
      <MuiListItemText primary={<TextBody2 color={color}>{label}</TextBody2>} />
    </>
  );

  /**
   * Gets the JSX for the menu item based on whether it has a custom component.
   * @returns {JSX.Element} - The rendered menu item.
   */
  const getComponentItem = (): JSX.Element => {
    if (menuItems && menuItems.length > 0) {
      return (
        <>
          <StyledMenuItem
            disabled={disabled}
            isGroup
            key={menuItemContent.key}
            onClick={toggleSubMenu}
          >
            {menuItemContent}
            {isSubMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </StyledMenuItem>
          <Collapse in={isSubMenuOpen} timeout='auto' unmountOnExit>
            {menuItems.map((subMenuItem) => (
              <MenuItem
                color='secondary'
                handleCloseMenu={handleCloseMenu}
                key={subMenuItem.id}
                {...subMenuItem}
              />
            ))}
          </Collapse>
        </>
      );
    }
    if (path && !disabled)
      return (
        <MuiLink
          color='inherit'
          component={Link}
          href={path}
          to={path}
          underline='none'
        >
          <StyledMenuItem
            disabled={disabled}
            key={menuItemContent.key}
            onClick={handleMenuItemClick}
          >
            {menuItemContent}
          </StyledMenuItem>
        </MuiLink>
      );
    return (
      <StyledMenuItem
        disabled={disabled}
        key={menuItemContent.key}
        onClick={onClick}
      >
        {menuItemContent}
      </StyledMenuItem>
    );
  };

  // If not displayed, return null
  if (isDisplayed !== undefined && !isDisplayed) return null;

  return <>{getComponentItem()}</>;
}

// Export the MenuItem component and its props
export { MenuItem, MenuItemProps };

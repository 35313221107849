import { Box as MuiBox } from '@mui/material';
import ErrorBoundary from 'components/ErrorBoundary';
import { styled } from 'mui-theme/styled';
import React from 'react';

import NavBar from './web-app/Header/NavBar';

const LayoutContainer = styled(MuiBox)`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 72px 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header header'
    'content content';
  height: 100vh;
`;

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <LayoutContainer>
      <ErrorBoundary isEmpty name='Nav'>
        <NavBar gridAreaName='header' />
      </ErrorBoundary>
      <ErrorBoundary name='Page' size='l'>
        {children}
      </ErrorBoundary>
    </LayoutContainer>
  );
}

export default Layout;

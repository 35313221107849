import React from 'react';

function ChevronLeftOutlined() {
  return (
    <path
      d='M 28.65,1 33.35,5.6999999 18.083333,21 33.35,36.3 28.65,41 8.65,21 Z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default ChevronLeftOutlined;

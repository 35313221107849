import { useTheme } from '@mui/material';
import React from 'react';

function Perimeter2Filled() {
  const {
    palette: { secondary, tertiary },
  } = useTheme();
  return (
    <>
      <path
        d='M 21.7774 38.2446 C 12.7091 38.2446 5.35783 30.8933 5.35783 21.825 C 5.35783 17.4702 7.08775 13.2938 10.167 10.2145 C 13.2463 7.13526 17.4227 5.40535 21.7774 5.40535 C 26.1322 5.40535 30.3086 7.13526 33.3879 10.2145 C 36.4671 13.2938 38.1971 17.4702 38.1971 21.825 C 38.1971 30.8933 30.8457 38.2446 21.7774 38.2446 Z'
        fill='currentColor'
      />
      <path
        d='m 15.632116,32.637975 c -0.440565,0 -0.810967,-0.150179 -1.111325,-0.450537 -0.280294,-0.300358 -0.420501,-0.660788 -0.420501,-1.081289 0,-0.440565 0.140207,-0.800995 0.420501,-1.081289 l 8.53017,-9.971889 c 0.600716,-0.700795 1.061225,-1.361583 1.381647,-1.982363 0.340366,-0.62078 0.510609,-1.211404 0.510609,-1.772113 0,-0.88101 -0.150179,-1.641998 -0.450537,-2.282721 -0.300358,-0.640724 -0.730831,-1.131353 -1.29154,-1.471755 -0.560708,-0.36043 -1.231468,-0.540645 -2.012399,-0.540645 -0.760867,0 -1.451691,0.200231 -2.072471,0.600705 -0.600716,0.380457 -1.081289,0.901086 -1.441719,1.561874 -0.340366,0.640724 -0.510609,1.351611 -0.510609,2.132542 0,0.400437 -0.150179,0.750895 -0.450537,1.051253 -0.280294,0.300358 -0.640724,0.450538 -1.081289,0.450538 -0.420501,0 -0.780931,-0.15018 -1.081289,-0.450538 -0.300358,-0.300358 -0.450537,-0.650816 -0.450537,-1.051253 0,-1.361583 0.31033,-2.573108 0.93111,-3.634333 0.62078,-1.081289 1.461783,-1.932311 2.523008,-2.553055 1.081289,-0.6207325 2.292693,-0.9310985 3.634333,-0.9310985 1.361583,0 2.543072,0.2903382 3.544225,0.8710385 1.001154,0.580688 1.772113,1.401663 2.312758,2.462936 0.560708,1.061225 0.841002,2.322729 0.841002,3.784512 0,0.640724 -0.140207,1.29154 -0.420501,1.952328 -0.26035,0.660787 -0.600716,1.291539 -1.021218,1.892256 -0.400437,0.600716 -0.810966,1.14136 -1.231468,1.621933 l -6.8782,7.809311 h 8.410027 c 0.440565,0 0.810966,0.160151 1.111324,0.480573 0.300359,0.300358 0.450538,0.650816 0.450538,1.051253 0,0.420501 -0.150179,0.780931 -0.450538,1.081289 -0.300358,0.300358 -0.670759,0.450537 -1.111324,0.450537 z'
        fill={tertiary.light}
      />
      <path
        d='M 12.517727,40.991895 H 2.2254646 C 1.5446489,40.991895 1,40.439236 1,39.766434 l 5e-7,-10.292191 c 0,-1.097388 1.3215667,-1.641997 2.0984969,-0.865031 L 13.390724,38.901403 c 0.776966,0.776966 0.224307,2.098541 -0.864983,2.098541 z'
        fill={secondary.main}
      />
      <path
        d='M 40.99195,12.517792 V 2.2255173 c 0,-0.6808109 -0.552658,-1.225461 -1.225461,-1.2254611 l -10.29231,-4e-7 c -1.097268,-10e-8 -1.641878,1.3215761 -0.865031,2.0985063 L 38.901458,13.390788 C 39.678424,14.167755 41,13.615096 41,12.525793 Z'
        fill={secondary.main}
      />
    </>
  );
}

export default Perimeter2Filled;

import UserRoleEnum from 'shared-api-front/enums/user-role.enum';

type UserRoleType = keyof typeof UserRoleEnum | 'CONNECTED' | 'DISCONNECTED';

const USER_ROLES: Record<UserRoleType, string> = {
  ...UserRoleEnum,
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
};

const ABSTRACT_USER_ROLES: string[] = [
  USER_ROLES.CONNECTED,
  USER_ROLES.DISCONNECTED,
];

const CONCRETE_USER_ROLES: string[] = Object.keys(USER_ROLES).filter(
  (r) => !ABSTRACT_USER_ROLES.includes(r)
);

interface CheckRoleProps {
  role: string;
  roles: string | string[];
}

const checkRole = ({ role, roles }: CheckRoleProps): boolean =>
  Array.isArray(roles) ? roles.includes(role) : roles === role;

interface CheckHasRolesParams {
  isAuthenticated: boolean;
  roles: UserRoleType | UserRoleType[];
  userData?: { role: string | undefined } | null;
}

const checkHasRoles = ({
  isAuthenticated,
  roles,
  userData,
}: CheckHasRolesParams): boolean => {
  if (!isAuthenticated)
    return checkRole({ role: USER_ROLES.DISCONNECTED, roles });

  if (checkRole({ role: USER_ROLES.CONNECTED, roles })) return true;

  if (userData?.role) {
    return checkRole({ role: userData?.role, roles });
  }

  return false;
};

interface CheckRolesParams {
  isAuthenticated: boolean;
  roles?: UserRoleType | UserRoleType[];
  rolesDifferentFrom?: UserRoleType | UserRoleType[];
  userData?: { role: string | undefined } | null;
}

const checkRoles = ({
  isAuthenticated,
  roles,
  rolesDifferentFrom,
  userData,
}: CheckRolesParams): boolean => {
  if (roles && rolesDifferentFrom)
    throw new Error('Impossible to combine "roles" and "rolesDifferentFrom"');

  if (!roles && !rolesDifferentFrom) return true;

  const rolesToCheck: UserRoleType | UserRoleType[] | undefined =
    roles || rolesDifferentFrom;

  if (userData && rolesToCheck) {
    const hasRoles = checkHasRoles({
      isAuthenticated,
      roles: rolesToCheck,
      userData,
    });
    return rolesDifferentFrom ? !hasRoles : hasRoles;
  }

  return true;
};

export { checkRoles, USER_ROLES, CONCRETE_USER_ROLES, UserRoleType };

import { useTheme } from '@mui/material';
import React from 'react';

function MarkerBuildingFilled() {
  const {
    palette: { tertiary },
  } = useTheme();

  return (
    <>
      <path
        d='M 32.121704,5.4506963 C 28.920074,2.483563 25.211111,1 20.998222,1 16.785407,1 13.07637,2.483563 9.8747704,5.4506963 6.6731408,8.4178518 5.0740741,12.400963 5.0740741,17.400074 c 0,3.332741 1.3191407,6.957259 3.9574296,10.87363 C 11.669785,32.19 15.658667,36.433259 21.001778,41 26.344815,36.433259 30.333704,32.19 32.972,28.273704 c 2.638296,-3.916371 3.957407,-7.540889 3.957407,-10.87363 0,-4.999111 -1.599037,-8.9822222 -4.804148,-11.9493777 z'
        fill={tertiary.light}
      />
      <path
        d='M 12.7137 25.5212 L 12.7137 10.2285 L 21.1697 10.2285 L 21.1697 13.6269 L 29.6258 13.6269 L 29.6258 25.5212 Z M 14.4049 23.822 L 16.0961 23.822 L 16.0961 22.1228 L 14.4049 22.1228 Z M 14.4049 20.4236 L 16.0961 20.4236 L 16.0961 18.7245 L 14.4049 18.7245 Z M 14.4049 17.0253 L 16.0961 17.0253 L 16.0961 15.3261 L 14.4049 15.3261 Z M 14.4049 13.6269 L 16.0961 13.6269 L 16.0961 11.9277 L 14.4049 11.9277 Z M 17.7873 23.822 L 19.4785 23.822 L 19.4785 22.1228 L 17.7873 22.1228 Z M 17.7873 20.4236 L 19.4785 20.4236 L 19.4785 18.7245 L 17.7873 18.7245 Z M 17.7873 17.0253 L 19.4785 17.0253 L 19.4785 15.3261 L 17.7873 15.3261 Z M 17.7873 13.6269 L 19.4785 13.6269 L 19.4785 11.9277 L 17.7873 11.9277 Z M 21.1697 23.822 L 27.9345 23.822 L 27.9345 15.3261 L 21.1697 15.3261 L 21.1697 17.0253 L 22.861 17.0253 L 22.861 18.7245 L 21.1697 18.7245 L 21.1697 20.4236 L 22.861 20.4236 L 22.861 22.1228 L 21.1697 22.1228 Z M 24.5522 18.7245 L 24.5522 17.0253 L 26.2433 17.0253 L 26.2433 18.7245 Z M 24.5522 22.1228 L 24.5522 20.4236 L 26.2433 20.4236 L 26.2433 22.1228 Z'
        fill='currentColor'
      />
    </>
  );
}

export default MarkerBuildingFilled;

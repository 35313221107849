import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {
    color: 'secondary',
    shape: 'rounded',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '.MuiPaginationItem-previousNext': {
        color: `${theme.palette.secondary.main}`,
      },
      '.MuiPaginationItem-text': {
        '&.Mui-selected': {
          color: `${theme.palette.text.light}`,
        },
        color: `${theme.palette.secondary.main}`,
      },
      borderRadius: '10px',
      textTransform: 'none',
    }),
  },
} as Components<Theme>['MuiPagination'];

import React from 'react';

function ChartArrowFilled() {
  return (
    <path
      d='M 10.777778,29.888889 17.555556,23.111111 22,27.555556 29.888889,19.722222 v 3.5 h 4.444444 V 12.111111 H 23.222222 v 4.444445 h 3.5 L 22,21.277778 17.555556,16.833333 7.6666667,26.777778 Z M 5.4444444,41 C 4.2222222,41 3.1759333,40.564889 2.3055556,39.694444 1.4351778,38.824 1,37.777778 1,36.555556 V 5.4444444 C 1,4.2222222 1.4351778,3.1759333 2.3055556,2.3055556 3.1759333,1.4351778 4.2222222,1 5.4444444,1 H 36.555556 C 37.777778,1 38.824,1.4351778 39.694444,2.3055556 40.564889,3.1759333 41,4.2222222 41,5.4444444 V 36.555556 C 41,37.777778 40.564889,38.824 39.694444,39.694444 38.824,40.564889 37.777778,41 36.555556,41 Z m 0,-4.444444 H 36.555556 V 5.4444444 H 5.4444444 Z'
      fill='currentColor'
    />
  );
}

export default ChartArrowFilled;

import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {
    disableGutters: true,
    maxWidth: undefined,
  },
  styleOverrides: {
    root: ({ maxWidth, theme }) =>
      maxWidth
        ? {
            [theme.breakpoints.down('sm')]: {
              paddingLeft: theme.spacing(9.5),
              paddingRight: theme.spacing(9.5),
            },
            [theme.breakpoints.between('sm', 'md')]: {
              paddingLeft: theme.spacing(20),
              paddingRight: theme.spacing(20),
            },
            [theme.breakpoints.between('md', 'lg')]: {
              paddingLeft: theme.spacing(23.5),
              paddingRight: theme.spacing(23.5),
            },
            [theme.breakpoints.between('lg', 'xl')]: {
              paddingLeft: theme.spacing(23.5),
              paddingRight: theme.spacing(23.5),
            },
            [theme.breakpoints.up('xl')]: {
              maxWidth: 1920 - 2 * parseFloat(theme.spacing(50)),
              paddingLeft: theme.spacing(50),
              paddingRight: theme.spacing(50),
            },
          }
        : {
            maxWidth: 'none',
            paddingLeft: 0,
            paddingRight: 0,
          },
  },
} as Components<Theme>['MuiContainer'];

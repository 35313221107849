import { Expose, Type } from 'class-transformer';
import Actor from 'models/Actor/Actor.model';
import UserActorRoleEnum from 'shared-api-front/enums/user-actor-role.enum';

import User from './User.model';

class UserActor {
  @Expose()
  userId: string;

  @Expose()
  actorId: number;

  @Expose()
  public role: UserActorRoleEnum;

  @Expose()
  @Type(() => User)
  public user: User;

  @Expose()
  @Type(() => Actor)
  public actor: Actor;

  constructor(model: Partial<UserActor> = {}) {
    if (model.user && typeof model.user === 'string') {
      this.user = JSON.parse(model.user);
    }

    if (model.actor && typeof model.actor === 'string') {
      this.actor = JSON.parse(model.actor);
    }

    Object.assign(this, model);
  }
}

export default UserActor;

import React from 'react';

function ZoomSiteFilled() {
  return (
    <>
      <path
        d='m 21.297298,29.055194 q -1.886188,0 -3.572325,-0.571572 -1.686137,-0.60015 -2.65781,-1.62898 -0.371522,-0.428679 -0.314364,-0.943094 0.05716,-0.514415 0.514414,-0.885936 0.485837,-0.371522 1.000251,-0.314365 0.514415,0.05716 0.857358,0.457258 0.571572,0.628729 1.657559,1.085986 1.114566,0.457258 2.429181,0.457258 1.857609,0 2.743546,-0.600151 0.914515,-0.628729 0.943094,-1.543244 0,-0.943094 -0.885937,-1.571823 -0.885937,-0.657308 -3.115067,-1.02883 -2.886439,-0.542993 -4.201055,-1.686137 -1.314615,-1.143144 -1.314615,-2.772124 0,-1.457509 0.800201,-2.400603 0.828779,-0.971672 2.143395,-1.42893 1.343194,-0.485836 2.829281,-0.485836 1.886188,0 3.315118,0.628729 1.42893,0.600151 2.286288,1.686138 0.371521,0.428679 0.314364,0.885936 -0.02858,0.457258 -0.457257,0.771623 -0.428679,0.257207 -1.000251,0.171471 -0.542994,-0.08574 -0.914516,-0.485836 -0.714465,-0.743044 -1.600401,-1.02883 -0.857358,-0.314364 -2.000502,-0.314364 -1.371773,0 -2.286288,0.514415 -0.885937,0.514414 -0.885937,1.400351 0,0.571572 0.285786,1.000251 0.314365,0.428679 1.143144,0.800201 0.82878,0.342943 2.372024,0.628729 2.143395,0.4001 3.372275,1.057408 1.257458,0.657308 1.800452,1.543245 0.542993,0.885936 0.542993,2.000502 0,1.314615 -0.743044,2.372023 -0.714465,1.02883 -2.086237,1.628981 -1.371773,0.60015 -3.315118,0.60015 z'
        fill='currentColor'
      />
      <path
        d='m 21.255859,6.3769531 c -8.261434,2e-7 -14.9960932,6.7346589 -14.9960934,14.9960939 0,8.261435 6.7346584,14.996093 14.9960934,14.996094 8.261435,0 14.996094,-6.734659 14.996094,-14.996094 0,-8.261435 -6.734659,-14.9960939 -14.996094,-14.9960939 z m 0,3.5 c 6.369897,0 11.496094,5.1261969 11.496094,11.4960939 0,6.369896 -5.126197,11.496094 -11.496094,11.496094 C 14.885963,32.86914 9.7597656,27.742943 9.7597656,21.373047 9.7597658,15.003151 14.885963,9.8769533 21.255859,9.8769531 Z'
        fill='currentColor'
      />
      <path
        d='M 7.57617 3.13672 L 3.45703 3.80078 L 2.66016 4.59766 L 1.99609 8.7168 L 3.58984 7.12305 L 7.04492 10.5781 L 9.43555 8.18555 L 5.98242 4.73242 L 7.57617 3.13672 Z'
        fill='currentColor'
      />
      <path
        d='M 35.9512 31.4219 L 33.5605 33.8145 L 37.0137 37.2676 L 35.4199 38.8633 L 39.5391 38.1992 L 40.3359 37.4023 L 41 33.2832 L 39.4063 34.877 L 35.9512 31.4219 Z'
        fill='currentColor'
      />
      <path
        d='M 35.4199 3.37305 L 37.0137 4.9668 L 33.5605 8.41992 L 35.9512 10.8125 L 39.4063 7.35938 L 41 8.95312 L 40.3359 4.83398 L 39.5391 4.03711 L 35.4199 3.37305 Z'
        fill='currentColor'
      />
      <path
        d='M 6.04883 31.4219 L 2.59375 34.877 L 1 33.2832 L 1.66406 37.4023 L 2.46094 38.1992 L 6.58008 38.8633 L 4.98633 37.2676 L 8.43945 33.8145 L 6.04883 31.4219 Z'
        fill='currentColor'
      />
    </>
  );
}

export default ZoomSiteFilled;

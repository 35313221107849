import { getConfig } from 'utils/external-services/config/getConfig';

const config = getConfig();

enum ApiEndPointNameEnum {
  login = 'login',
  resetPassword = 'resetPassword',
  forgotPassword = 'forgotPassword',
  logout = 'logout',
  me = 'me',
  register = 'register',
  actorDetails = 'actorDetails',
  companyPdfReport = 'companyPdfReport',
  actors = 'actors',
  companyDetails = 'companyDetails',
  prescriberDetails = 'prescriberDetails',
  sites = 'sites',
  companyCreate = 'companyCreate',
  companyDelete = 'companyDelete',
  companyUpdate = 'companyUpdate',
  prescriberCreate = 'prescriberCreate',
  prescriberDelete = 'prescriberDelete',
  prescriberUpdate = 'prescriberUpdate',
  siteCreate = 'siteCreate',
  siteUpdate = 'siteUpdate',
  siteDelete = 'siteDelete',
  siteDetails = 'siteDetails',
  users = 'users',
  sitePdfReport = 'sitePdfReport',
  mapServiceLayers = 'mapServiceLayers',
  geocodingForward = 'geocodingForward',
  geocodingReverse = 'geocodingReverse',
}

enum ApiSourceEnum {
  cbsApi = 'cbsApi',
  mapboxGeocodingApi = 'mapboxGeocodingApi',
}

type ApiEndpoint = Partial<{
  [endpointName in ApiEndPointNameEnum]: string;
}>;

type ApiSource = {
  [sourceName in ApiSourceEnum]: {
    endpoints: ApiEndpoint;
    url: string;
  };
};

const ApiSources: ApiSource = {
  cbsApi: {
    endpoints: {
      actorDetails: 'actors/:id/details',
      actors: 'actors/',
      companyCreate: 'actors/company/create',
      companyDelete: 'actors/company/:id',
      companyDetails: 'actors/company/:id/details',
      companyPdfReport: 'actors/company/:id/report',
      companyUpdate: 'actors/company/:id/update',
      forgotPassword: 'auth/forgot-password',
      login: 'auth/login',
      logout: 'auth/logout',
      mapServiceLayers: 'map-service-layers',
      me: 'users/me',
      prescriberCreate: 'actors/prescriber/create',
      prescriberDelete: 'actors/prescriber/:id',
      prescriberDetails: 'actors/prescriber/:id/details',
      prescriberUpdate: 'actors/prescriber/:id/update',
      register: 'auth/register',
      resetPassword: 'auth/reset-password',
      siteCreate: 'sites/create',
      siteDelete: 'sites/:id',
      siteDetails: 'sites/:id/details',
      sitePdfReport: 'sites/:id/report',
      siteUpdate: 'sites/:id/update',
      sites: 'sites',
      users: 'users',
    },
    url: config.apiUrlHttp,
  },
  mapboxGeocodingApi: {
    endpoints: {
      geocodingForward: 'forward',
      geocodingReverse: 'reverse',
    },
    url: `https://api.mapbox.com/search/geocode/v6`,
  },
};

export { ApiEndPointNameEnum, ApiSourceEnum };
export default ApiSources;

import React, { createContext, useReducer } from 'react';
import useSafeContext from 'utils/useSafeContext';

import { ToastReducerActionTypeEnum } from './ToastContext.enum';

type Action = {
  type: ToastReducerActionTypeEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};

interface ToastState {
  isOpen?: boolean;
  message?: string;
}

interface ToastContextData {
  toastDispatch: React.Dispatch<Action>;
  toastState: ToastState;
}

/**
 * Function to get the initial state of the webapp.
 * @returns {WebAppState} The initial webapp state.
 */
const getInitialState = (): ToastState => ({
  isOpen: false,
  message: undefined,
});

/**
 * Context for managing webapp-related information.
 */
const ToastContext: React.Context<ToastContextData> =
  createContext<ToastContextData>({
    toastDispatch: () => {},
    toastState: getInitialState(),
  });

/*
 * Custom hook to access the Toast context.
 * @returns {WebAppContextData} The WebApp context data.
 */
const useToast = () => useSafeContext(ToastContext, 'Toast');

/**
 * Reducer function to manage WebApp state.
 * @param {WebAppState} state - The current WebApp state.
 * @param {Action} action - The action to perform on the state.
 * @returns {WebAppState} The new WebApp state.
 * @throws {Error} If an unsupported action type is provided.
 */
const reducer = (state: ToastState, action: Action): ToastState => {
  switch (action.type) {
    case ToastReducerActionTypeEnum.ADD_TOAST: {
      return {
        ...state,
        ...action.value,
        isOpen: true,
      };
    }
    case ToastReducerActionTypeEnum.CLOSE_TOAST: {
      return {
        ...state,
        isOpen: false,
      };
    }

    default:
      return state;
      throw new Error(`WebApp action type not handled: ${action.type}`);
  }
};

function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toastState, toastDispatch] = useReducer<
    React.Reducer<ToastState, Action>
  >(reducer, getInitialState());

  return (
    <ToastContext.Provider
      value={{
        toastDispatch,
        toastState,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}

export { ToastProvider, useToast };

import { Components, Theme } from '@mui/material';

import { palette } from '../palette';

export default {
  defaultProps: {
    MenuListProps: {
      style: {
        backgroundColor: palette.primary.main,
        borderRadius: 4,
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        minWidth: 250,
        mt: 1.5,
        overflow: 'visible',
        padding: 10,
      },
    },
    PaperProps: {
      elevation: 10,
      square: true,
      style: {
        backgroundColor: palette.primary.main,
        borderRadius: 4,
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        overflow: 'visible',
        padding: 0,
      },
    },
  },
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
} as Components<Theme>['MuiMenu'];

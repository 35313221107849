import axios, { AxiosError, AxiosResponse } from 'axios';

import { EndpointName, SourceName } from './api-endpoints.type';
import { apiUrlBuilder } from './utils';

type HttpMethod = 'get' | 'post' | 'put' | 'delete';

interface ApiResponse<T> {
  data: T | null;
  [x: string]: unknown;
}

interface ApiError {
  message: string;
  // Add other error properties as needed
}

interface ApiCallerProps {
  source: SourceName;
  endpoint: EndpointName;
  token?: string | null;
  headers?: Record<string, unknown>;
  body?: Record<string, unknown>;
  method: HttpMethod;
  params?: Record<string, unknown>;
  queryParams?: Record<string, unknown>;
}

class ApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ApiError';
  }
}

async function ApiCaller<T>({
  body,
  endpoint,
  headers,
  method = 'get',
  params,
  queryParams,
  source,
  token,
}: ApiCallerProps): Promise<ApiResponse<T>> {
  const apiCallerResponse: ApiResponse<T> = {
    data: null,
    error: null,
    loading: true,
  };

  try {
    const apiHeaders = {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...headers,
      'Content-type': 'application/json',
    };

    const apiUrl = apiUrlBuilder({
      apiSourceName: source,
      endpoint,
      params,
      queryParams,
    });

    let response: AxiosResponse<T>;
    switch (method) {
      case 'get':
        response = await axios.get<T>(apiUrl, {
          headers: apiHeaders,
        });
        break;
      case 'post':
        response = await axios.post<T>(apiUrl, body, {
          headers: apiHeaders,
        });
        break;
      case 'put':
        response = await axios.put<T>(apiUrl, body, {
          headers: apiHeaders,
        });
        break;
      case 'delete':
        response = await axios.delete<T>(apiUrl, {
          headers: apiHeaders,
        });
        break;
      default:
        throw new Error('Invalid HTTP method');
    }

    apiCallerResponse.data = response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ApiError>;
      if (axiosError.response) {
        apiCallerResponse.error = axiosError.response.data;

        throw axiosError.response.data;
      } else {
        throw new ApiError('An error occurred');
      }
    } else {
      throw new ApiError('An error occurred');
    }
  } finally {
    apiCallerResponse.loading = false;
  }

  return apiCallerResponse;
}

export default ApiCaller;

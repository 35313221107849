import { Theme, createTheme } from '@mui/material';

import components from './components';
import { palette } from './palette';

// cspell-checker: disable
export const mainFontFamily =
  'Comfortaa, -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif';
export const secondaryFontFamily =
  'Rubik, -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif';
// cspell-checker: enable

const muiTheme: Theme = createTheme({
  components: {
    ...components,
  },
  fontFamily: {
    main: mainFontFamily,
    secondary: secondaryFontFamily,
  },
  palette,
  spacing: 4,
});

export { muiTheme };

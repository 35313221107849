import { MultiPolygon } from '@turf/turf';
import { Expose, Type } from 'class-transformer';

import ArtificialAreaType from './ArtificialAreaType.model';

class ArtificialArea {
  @Expose()
  public id: number;

  @Expose()
  public geom: MultiPolygon;

  @Expose()
  @Type(() => ArtificialAreaType)
  public type: ArtificialAreaType;

  constructor(model: Partial<ArtificialArea> = {}) {
    if (model.type && typeof model.type === 'string') {
      this.type = JSON.parse(model.type);
    }
    Object.assign(this, model);
  }
}

export default ArtificialArea;

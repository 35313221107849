import React from 'react';

function BuildingOutlined() {
  return (
    <path
      d='M 1,39.084894 V 2.915106 h 19.99993 v 8.037731 H 41 V 39.084894 Z M 4.9999965,35.066029 H 8.999993 V 31.047163 H 4.9999965 Z m 0,-8.037731 H 8.999993 V 23.009433 H 4.9999965 Z m 0,-8.037731 H 8.999993 V 14.971702 H 4.9999965 Z m 0,-8.03773 H 8.999993 V 6.9339713 H 4.9999965 Z m 7.9999925,24.113192 h 3.999997 v -4.018866 h -3.999997 z m 0,-8.037731 h 3.999997 v -4.018865 h -3.999997 z m 0,-8.037731 h 3.999997 v -4.018865 h -3.999997 z m 0,-8.03773 h 3.999997 V 6.9339713 H 12.999989 Z M 20.99993,35.066029 H 36.999881 V 14.971702 H 20.99993 v 4.018865 h 4.000119 v 4.018866 H 20.99993 v 4.018865 h 4.000119 v 4.018865 H 20.99993 Z m 8.000063,-12.056596 v -4.018866 h 3.999944 v 4.018866 z m 0,8.03773 v -4.018865 h 3.999944 v 4.018865 z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='2'
    />
  );
}

export default BuildingOutlined;

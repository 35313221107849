import {
  Alert as MuiAlert,
  AlertColor as MuiAlertColor,
  Snackbar as MuiSnackBar,
} from '@mui/material';
import { useToast } from 'business/ToastContext';
import { ToastReducerActionTypeEnum } from 'business/ToastContext.enum';
import { IconButton } from 'design-system/button/icon-button/IconButton';
import React, { useEffect } from 'react';

interface ToastProps {
  message: string;
  duration?: number;
  severity?: MuiAlertColor;
  isOpen?: boolean;
}

function Toast({
  duration,
  isOpen,
  message,
  severity = 'success',
}: ToastProps) {
  const [open, setOpen] = React.useState(isOpen);
  const { toastDispatch } = useToast();
  useEffect(() => {
    setOpen(isOpen || false);
  }, [isOpen]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    toastDispatch({
      type: ToastReducerActionTypeEnum.CLOSE_TOAST,
    });
    setOpen(false);
  };

  if (!message) return null;

  return (
    <MuiSnackBar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={duration || 3000}
      onClose={handleClose}
      open={open}
    >
      <MuiAlert
        action={
          <IconButton
            color='primary'
            icon='cross'
            onClick={handleClose}
            size='xs'
            sx={{ backgroundColor: 'inherit' }}
          />
        }
        severity={severity || 'info'}
        sx={{ width: '100%' }}
        variant='filled'
      >
        {message}
      </MuiAlert>
    </MuiSnackBar>
  );
}

export default Toast;

import { Components, Theme } from '@mui/material';

export default {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      '.MuiTableCell-root': {
        color: theme.palette.text.tertiary,
        fontFamily: theme.fontFamily.main,
        fontWeight: 300,
      },
      '.MuiTableHead-root': {
        '& .MuiTableCell-head': {
          backgroundColor: theme.palette.secondary.main,
          borderWidth: 2,
          fontWeight: 700,
        },
      },
      color: theme.palette.text.tertiary,
    }),
  },
} as Components<Theme>['MuiTable'];

import { Components, Theme } from '@mui/material';

import { palette } from '../palette';

export default {
  defaultProps: {
    PaperProps: {
      elevation: 10,
      square: false,
      style: {
        mt: 1.5,
        overflow: 'visible',
      },
      sx: (theme) => ({
        backgroundColor: palette.secondary.main,

        padding: '10px',
        [theme.breakpoints.up('md')]: {
          padding: '60px',
        },
      }),
    },
  },
} as Components<Theme>['MuiDialog'];

import React from 'react';

function StateStep3Outlined() {
  return (
    <>
      <path
        d='M 32.249278,5.4506944 C 29.010444,2.4835667 25.258278,1 20.996444,1 16.734611,1 12.9825,2.4835667 9.7436277,5.4506944 6.5047721,8.4178333 4.887111,12.400944 4.887111,17.400056 c 0,3.332777 1.3344833,6.957277 4.0034444,10.873611 C 11.5595,32.19 15.594833,36.433278 21,41 c 5.405167,-4.566722 9.440444,-8.81 12.109444,-12.726333 2.668945,-3.916334 4.003445,-7.540834 4.003445,-10.873611 0,-4.999112 -1.617667,-8.9822227 -4.860056,-11.9493616 z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M 29.164062 6.625 L 11.037109 17.591797 L 12.199219 21.615234 L 17.341797 20.931641 L 18.869141 27.367188 L 21.347656 27.015625 L 20.412109 20.521484 L 32.705078 18.886719 L 29.164062 6.625 z '
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='m 8.9502777,18.498222 1.3956663,-0.593055 1.157111,3.862222 -1.111111,0.328333 z'
        fill='transparent'
        stroke='currentColor'
        strokeWidth='2'
      />
    </>
  );
}

export default StateStep3Outlined;

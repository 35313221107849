import { useTheme } from '@mui/material';
import React from 'react';

function MarkerDotStackLowFilled() {
  const {
    palette: { tertiary },
  } = useTheme();

  return (
    <>
      <path
        d='M 32.172437,5.4506943 C 28.955704,2.4835648 25.229214,1 20.996484,1 16.763755,1 13.037265,2.4835648 9.8205308,5.4506943 6.6037967,8.4178239 4.9971875,12.400949 4.9971875,17.40007 c 0,3.332748 1.3253647,6.957286 3.9760942,10.873616 C 11.624011,32.190016 15.631746,36.433292 21,41 c 5.368255,-4.566708 9.375989,-8.809984 12.026718,-12.726314 2.650729,-3.91633 3.976094,-7.540868 3.976094,-10.873616 0,-4.999121 -1.606609,-8.9822461 -4.826859,-11.9493757 z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0'
      />
      <path
        d='m 9.1318685,17.440148 c 0,0.739813 -0.6264687,1.356336 -1.3782282,1.356336 H 5.1538041 C 5.0493932,18.108033 4.9971875,17.429882 4.9971875,16.741466 c 0,-0.215785 0,-0.441835 0.020882,-0.65762 h 2.7355706 c 0.7517595,0 1.3782282,0.606223 1.3782284,1.356302 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='m 17.52656,16.083846 h -3.288944 c -0.761188,0 -1.378238,0.607242 -1.378238,1.356302 0,0.749094 0.61705,1.356336 1.378238,1.356336 h 3.288943 c 0.761189,0 1.37824,-0.607242 1.37824,-1.356336 0,-0.74906 -0.617051,-1.356302 -1.378239,-1.356302 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='m 27.299385,16.083846 h -3.288943 c -0.76119,0 -1.378239,0.607242 -1.378239,1.356302 0,0.749094 0.617049,1.356336 1.378239,1.356336 h 3.288943 c 0.761188,0 1.378239,-0.607242 1.378239,-1.356336 0,-0.74906 -0.617051,-1.356302 -1.378239,-1.356302 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='m 36.988715,16.741466 c 0,0.688416 -0.05221,1.366567 -0.156618,2.055018 h -3.038355 c -0.762208,0 -1.378204,-0.616523 -1.378204,-1.356336 0,-0.739779 0.615996,-1.356302 1.378204,-1.356302 h 3.17409 c 0.02088,0.215785 0.02088,0.441835 0.02088,0.65762 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='M 12.953349,21.632449 H 9.6643698 c -0.7611531,0 -1.3782141,0.607242 -1.3782141,1.356301 0,0.74906 0.617061,1.356302 1.3782141,1.356302 h 3.2889792 c 0.761153,0 1.378203,-0.607242 1.378203,-1.356302 0,-0.749059 -0.61705,-1.356301 -1.378203,-1.356301 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='m 22.726174,21.632449 h -3.288945 c -0.761188,0 -1.378239,0.607242 -1.378239,1.356301 0,0.74906 0.617051,1.356302 1.378239,1.356302 h 3.288945 c 0.761153,0 1.378202,-0.607242 1.378202,-1.356302 0,-0.749059 -0.617049,-1.356301 -1.378202,-1.356301 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='m 32.499069,21.632449 h -3.288944 c -0.761153,0 -1.378203,0.607242 -1.378203,1.356301 0,0.74906 0.61705,1.356302 1.378203,1.356302 h 3.288944 c 0.761188,0 1.378238,-0.607242 1.378238,-1.356302 0,-0.749059 -0.61705,-1.356301 -1.378238,-1.356301 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='m 22.726174,32.739814 h -3.288945 c -0.761188,0 -1.378239,0.607242 -1.378239,1.356336 0,0.74906 0.617051,1.356302 1.378239,1.356302 h 3.288945 c 0.761153,0 1.378202,-0.607242 1.378202,-1.356302 0,-0.749094 -0.617049,-1.356336 -1.378202,-1.356336 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='m 17.839761,27.181016 h -3.288979 c -0.761153,0 -1.378203,0.607242 -1.378203,1.356337 0,0.749059 0.61705,1.356301 1.378203,1.356301 h 3.288978 c 0.761153,0 1.378204,-0.607242 1.378204,-1.356301 0,-0.749095 -0.617051,-1.356337 -1.378203,-1.356337 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
      <path
        d='m 27.612656,27.181016 h -3.288943 c -0.761153,0 -1.378204,0.607242 -1.378204,1.356337 0,0.749059 0.617051,1.356301 1.378204,1.356301 h 3.288943 c 0.761189,0 1.378239,-0.607242 1.378239,-1.356301 0,-0.749095 -0.61705,-1.356337 -1.378239,-1.356337 z'
        fill={tertiary.light}
        strokeWidth='0'
      />
    </>
  );
}

export default MarkerDotStackLowFilled;

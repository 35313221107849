import { Expose, Type } from 'class-transformer';
import Actor from 'models/Actor/Actor.model';
import SiteStatusEnum from 'shared-api-front/enums/site-status.enum';

import SiteIndicatorValue from './SiteIndicatorValue';

class Site {
  @Expose()
  public id: number;

  @Expose()
  public name: string;

  @Expose()
  public address: string;

  @Expose()
  public extraAddress?: string;

  @Expose()
  public postalCode: string;

  @Expose()
  public city: string;

  @Expose()
  public area?: number;

  @Expose()
  public latitude: number;

  @Expose()
  public longitude: number;

  @Expose()
  public inProgressActions?: string;

  @Expose()
  public status: SiteStatusEnum;

  @Expose()
  @Type(() => SiteIndicatorValue)
  public indicatorValue?: SiteIndicatorValue;

  @Expose()
  @Type(() => SiteIndicatorValue)
  public indicatorValues?: Array<SiteIndicatorValue>;

  @Expose()
  @Type(() => Actor)
  public actor: Actor;

  constructor(model: Partial<Site> = {}) {
    if (model.indicatorValue && typeof model.indicatorValue === 'string') {
      this.indicatorValue = JSON.parse(model.indicatorValue);
    }

    if (model.indicatorValues && typeof model.indicatorValues === 'string') {
      this.indicatorValues = JSON.parse(model.indicatorValues);
    }

    if (model.actor && typeof model.actor === 'string') {
      this.actor = JSON.parse(model.actor);
    }
    Object.assign(this, model);
  }
}

export default Site;

import { Components, Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      '&:hover, .MuiCard-root:hover &': {
        '.MuiTouchRipple-root': {
          backgroundColor: theme.palette.secondary.shade100,
        },
        backgroundColor: 'transparent',
      },
      '.MuiSvgIcon-root': {
        zIndex: 1,
      },
      '.MuiTouchRipple-root .MuiTouchRipple-ripple': {
        color: `${theme.palette.secondary.shade300}`,
        opacity: '1 !important',
      },
      input: {
        zIndex: 2,
      },
      padding: theme.spacing(2),
    }),
  },
  variants: [
    {
      props: { size: 'sm' },
      style: {
        '& .MuiSvgIcon-root': {
          fontSize: '16px',
        },
        '&.MuiCheckbox-root': {
          padding: '10px',
        },
      },
    },
    {
      props: { size: 'md' },
      style: {
        '& .MuiSvgIcon-root': {
          fontSize: '20px',
        },
        '&.MuiCheckbox-root': {
          padding: '10px',
        },
      },
    },
    {
      props: { size: 'lg' },
      style: {
        '& .MuiSvgIcon-root': {
          fontSize: '34px',
        },
        '&.MuiCheckbox-root': {
          padding: '11px',
        },
      },
    },
  ],
} as Components<Theme>['MuiCheckbox'];

import React from 'react';

function RectangleOutlined() {
  return (
    <path
      d='M 1.4628843,7.8043675 H 40.598397 V 34.195633 H 1.4025223 Z'
      fill='transparent'
      stroke='currentColor'
      strokeWidth='3'
    />
  );
}

export default RectangleOutlined;
